import { Component, OnInit } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from 'src/app/services/col2/auth.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css'],
    standalone: false
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthServiceProvider) { }

  ngOnInit() {
    this.authService.get().subscribe(api => api.startRegistration('/'));
  }

}
