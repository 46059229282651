import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { School } from 'src/app/model/rest/casa-apiv2.model';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';

@Component({
    selector: 'app-admin-school-details-page',
    templateUrl: './admin-school-details-page.component.html',
    styleUrls: ['./admin-school-details-page.component.scss'],
    standalone: false
})
export class AdminSchoolDetailsPageComponent implements OnInit {
  schoolId: number;
  school: School;
  currentFragment: string;
  private currentFragments: string[];

  @ViewChild('renewalModal', { static: true }) renewalModal: ModalComponent;
  @ViewChild('deleteModal', { static: true }) deleteModal: ModalComponent;

  constructor(
    private route: ActivatedRoute,
    private casa: CasaRestApiV2,
    private router: Router,
    private authProvider: AuthServiceProvider,
    private functionalAccessService: FunctionalAccessService
  ) {
    router.events
      .pipe(
        filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
                            e instanceof NavigationEnd || 
                            e instanceof NavigationCancel || 
                            e instanceof NavigationError))
      .subscribe((e: RouterEvent) => {
        e['urlAfterRedirects']
          ? (this.currentFragments = e['urlAfterRedirects'].split('/'))
          : '';
        this.currentFragment =
          this.currentFragments[this.currentFragments.length - 1];
      });
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        tap((params) => (this.schoolId = +params.get('schoolId'))),
        switchMap((_) => this.casa.getSchoolDetails(this.schoolId)),
        tap((school) => (this.school = school))
      )
      .subscribe();
  }

  logInAsManager() {
    this.authProvider
      .get()
      .pipe(
        tap((auth) => auth.logInAsManager(this.schoolId, null)),
        //reinitialize functionalAccess when admin has manager school id
        tap(_ => this.functionalAccessService.initialize())
      ).subscribe()
  }
}
