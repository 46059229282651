<div class="bg-workspace min-vh-100">
  <top-bar [title]="'News'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <a href="/" routerLink="/school/{{ schoolId }}/dashboard">Home</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">News</li>
      </ol>
    </nav>
    <div class="d-none d-md-flex mb-md-4">
      <h4>News and Highlights</h4>
      <p class="text-muted xsmall ms-auto my-auto">
        You have
        <span class="text-primary rounded-pill bg-white p-2 ms-2"
          >{{ newsList?.content.length }} News</span
        >
      </p>
    </div>
    <div class="card">
      <div
        class="card-header flex-column border-bottom border-secondary border-bottom-md-0"
      >
        <h4 class="d-md-none text-center">News and Highlights</h4>
        <p class="d-md-none text-center text-muted xsmall mb-4">
          You have
          <span class="text-primary">{{ newsList?.content.length }} News</span>
        </p>
        <form class="row flex-column flex-sm-row">
          <div class="col-12 col-sm-6 col-lg-5">
            <select
              name="groups-sorting"
              [(ngModel)]="currentState.sortingRule"
              class="form-select p-3 mb-3"
              (ngModelChange)="sort()"
              [ngClass]="{ 'select-not-empty': currentState.sortingRule }"
            >
              <option [ngValue]="sorting" *ngFor="let sorting of sortingRules">
                {{ sorting.name }}
              </option>
            </select>
          </div>
        </form>
      </div>
      <div class="card-body pt-4 pt-md-3">
        <ng-container *ngIf="!loaded">
          <div class="d-flex">
            <span
              class="spinner-border text-primary mx-auto"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        </ng-container>
        <ng-container *ngIf="loaded && !newsList?.content.length">
          <div class="d-flex">
            <span class="text-muted mx-auto">No results found</span>
          </div>
        </ng-container>
        <ng-container *ngIf="loaded && newsList?.content.length">
          <div class="row">
            <app-news-item
              class="col-12 pb-3 pb-md-4"
              [entry]="newsList?.content[0]"
              [isWide]="true"
              [schoolId]="schoolId"
            ></app-news-item>
          </div>
          <ng-container *ngIf="newsList?.content.length > 1">
            <hr class="my-5 border" />
            <div
              class="row row-cols-1 row-cols-md-2 row-cols-lg-3 gy-4 gy-md-5 pt-3 pt-md-4"
            >
              <app-news-item
                class="col"
                *ngFor="let newsItem of newsList?.content.slice(1)"
                [entry]="newsItem"
                [isSmall]="true"
                [schoolId]="schoolId"
              ></app-news-item>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
