import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {combineLatest, Subject, Subscription} from 'rxjs';
import { Pageable, Page, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { LessonBundeFilter, ApiLessonBundle } from 'src/app/model/rest/rest-model';
import {ManagerRestService} from "../../../services/col2/manager-rest.service";
import {filter, finalize, switchMap, tap} from "rxjs/operators";

export class StudentCreditsComponentEvent extends ComponentEvent {
}

@Component({
    selector: 'app-student-credits',
    templateUrl: './student-credits.component.html',
    styleUrls: ['./student-credits.component.css'],
    standalone: false
})
export class StudentCreditsComponent extends StateAwareComponent<StudentCreditsComponentEvent> implements OnInit, OnDestroy {

  bundles: ApiLessonBundle[];
  bundleFilter: LessonBundeFilter;
  pageQuery: Pageable;
  lastPageResult: Page<ApiLessonBundle>;
  _lang: string;
  hasNext = false;
  _studentId: number;
  creditsTotal: number;

  studentIdSubject = new Subject<number>();
  schoolIdSubject = new Subject<number>();
  langCodeSubject = new Subject<string>();
  private _schoolId: number;

  ngOnDestroy(): void {
  }

  @Input()
  set schoolId(value: number) {
    this.schoolIdSubject.next(value);
  }

  @Input()
  set studentId(studentId: number) {
    this.studentIdSubject.next(studentId);
  }

  @Input()
  public set langCode(lang: string){
    this.langCodeSubject.next(lang);
  }

  constructor(
    private managerRest: ManagerRestService
  ) {
    super();
    combineLatest(
      this.schoolIdSubject, this.studentIdSubject, this.langCodeSubject
    ).pipe(
      tap<[number, number, string]>(([schoolId, studentId, langCode]) => {
          this._schoolId = schoolId;
          this._studentId = studentId;
          this._lang = langCode;
        }
      ),
      filter<[number, number, string]>(([schoolId, studentId, langCode]) => schoolId != null && studentId != null && langCode != null),
      switchMap(([schoolId, studentId, langCode]) => this.initQuery(schoolId, studentId, langCode))

    ).subscribe();
  }

  get studentId() {
    return this._studentId;
  }

  ngOnInit() {
  }

  private loadAvailableBundles(
    schoolId: number, studentId: number,
    filter: LessonBundeFilter, pageable: Pageable) {
    return this.managerRest.findStudentLessonBundles(
      schoolId, studentId, filter, pageable);
  }

  private initQuery(schoolId: number, studentId:number, lang: string) {
    this.eventLoading();
    this.clearQueryData();
    this.bundleFilter = new LessonBundeFilter(lang, true);
    this.pageQuery = Pageable.of(0, 1000, null);
    return this.callAppendQuery(schoolId, studentId);
  }

  callAppendQuery(schoolId: number, studentId: number) {
    this.eventLoading();
    return this.loadAvailableBundles(schoolId, studentId, this.bundleFilter, this.pageQuery).pipe(
      tap<Page<ApiLessonBundle>>(result => {
          this.lastPageResult = result;
          this.bundles = this.bundles.concat(result.content);
          this.hasNext = (!result.last);
          this.creditsTotal = 0;
          this.bundles.forEach(bundle => this.creditsTotal += bundle.available);
          this.eventNumberOfElements(result.totalElements);
        }),
      finalize( () => this.eventLoaded())
    )
  }

  loadNext() {
    this.moveNext();
    this.callAppendQuery(this._schoolId, this._studentId).subscribe();
  }

  moveNext() {
    if (!this.hasNext) { return; }
    this.pageQuery = this.pageQuery.next();
  }

  clearQueryData() {
    this.bundles = [];
    this.bundleFilter = null;
    this.pageQuery = null;
    this.lastPageResult = null;
    this.hasNext = false;
  }



}
