import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SearchingContextService} from "../../services/searching-context.service";
import {VideoServerRestService} from "../../services/rest/video-server-rest.service";
import {switchMap,  tap} from "rxjs/operators";
import {LocalStateService} from "../../services/local-state.service";
import { RoomDef, TeachersAttendanceReport} from "../../model/server";
import {PageableDataProvider} from "../../model/internal";
import {Observable} from "rxjs";
import {Page, Pageable} from "../../model/rest/base";
import {School} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";

class AttendanceRoomsQueryState {
  constructor(public teacherMail: string, public pageable: Pageable) {
  }
}

class AttendanceQueryState {
  constructor(public month: number, public duration: number) {
  }
}

@Component({
    selector: 'app-teacher-attendance-report-page',
    templateUrl: './teacher-attendance-report-page.component.html',
    styleUrls: ['./teacher-attendance-report-page.component.css'],
    standalone: false
})
export class TeacherAttendanceReportPageComponent implements OnInit {
  schoolId: number;
  private localStateAttendanceContextPath: string;
  query: AttendanceQueryState;
  reportData: TeachersAttendanceReport[];
  private localStateRoomsSearchContextPath: string;
  roomsQuery: AttendanceRoomsQueryState;
  teachers: String[];
  currentRoomsPage: Page<RoomDef>;
  private school: School;

  constructor(activatedRoute: ActivatedRoute,
              private searchContext: SearchingContextService,
              private localState: LocalStateService,
              private rest: VideoServerRestService,
              private casa: CasaRestApiV2,
              private router: Router) {
    activatedRoute.parent.paramMap.pipe(
      tap (params => {
        this.schoolId = Number(params.get('schoolId'));
        this.localStateAttendanceContextPath = `schools/${this.schoolId}/reports/teacher-attendance`;
        this.localStateRoomsSearchContextPath = `schools/${this.schoolId}/reports/teacher-attendance/rooms`;
      }),
      switchMap( _ => this.loadSchool()),
      switchMap( _ => this.initializeSearchingContexts()),
      switchMap( _ => this.reloadAndStoreReportData()),
      switchMap( _ => this.loadRoomsPage(this.roomsQuery))
    )
      .subscribe( );
  }

  ngOnInit() {
  }

  private loadSchool():Observable<School> {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.school = school)
    );
  }

  private reloadAndStoreReportData() {
    return this.localState.set(this.localStateAttendanceContextPath, this.query).pipe(
      switchMap( _ => this.rest.getTeacherAttendanceReport(this.schoolId, this.query.month, this.query.duration)),
      tap( data => this.storeReportData(data))
    )
  }

  private initializeSearchingContexts() {
    return this.localState.get<AttendanceQueryState>(this.localStateAttendanceContextPath, () =>
      new AttendanceQueryState(0, 12)
    ).pipe(
      tap( q => this.query = q),
      switchMap( _ => this.localState.get<AttendanceRoomsQueryState>(this.localStateRoomsSearchContextPath, () =>
        new AttendanceRoomsQueryState(null, new Pageable(0, 20, ["info.created,DESC"]))
      )),
      tap ( (q: AttendanceRoomsQueryState) => {
        this.roomsQuery = q;
      })
    )
  }

  private storeReportData(reportData: TeachersAttendanceReport[]) {
    this.reportData = reportData;
    this.teachers = reportData.map( row => row.teacherEmail);
  }

  private loadRoomsPage(state: AttendanceRoomsQueryState) {
    return this.localState.set<AttendanceRoomsQueryState>(
      this.localStateRoomsSearchContextPath, state
    ).pipe(
      tap( state => this.roomsQuery = state),
      switchMap<AttendanceRoomsQueryState, Observable<Page<RoomDef>>>( state => this.rest.listSchoolRooms(
        this.schoolId,
        state.pageable,
        this.query.month,
        this.query.duration,
        state.teacherMail
      )),
      tap<Page<RoomDef>>( rooms => this.currentRoomsPage = rooms)
    )
  }

  onTimeChange() {
    this.roomsQuery.pageable.page = 0;
    this.reloadAndStoreReportData().pipe(
      switchMap( _ => this.loadRoomsPage(this.roomsQuery))
    ).subscribe(
    )
  }

  onTeacherChange() {
    this.roomsQuery.pageable.page = 0;
    this.loadRoomsPage(this.roomsQuery).subscribe();
  }

  hasRoomsPage() {
    return !!this.currentRoomsPage;
  }

  getRoomsCount() {
    if (!this.hasRoomsPage()) return null;
    return this.currentRoomsPage.totalElements;
  }

  hasRoomsNext() {
    return this.hasRoomsPage() && !this.currentRoomsPage.last;
  }

  hasRoomsPrev() {
    return this.hasRoomsPage() && !this.currentRoomsPage.first;
  }

  nextRoomsPage() {
    this.roomsQuery.pageable = this.roomsQuery.pageable.next();
    this.loadRoomsPage(this.roomsQuery).subscribe();
  }

  prevRoomsPage() {
    this.roomsQuery.pageable = this.roomsQuery.pageable.prev();
    this.loadRoomsPage(this.roomsQuery).subscribe();
  }

  firstRoomsPage() {
    this.roomsQuery.pageable = this.roomsQuery.pageable.first();
    this.loadRoomsPage(this.roomsQuery).subscribe();
  }

  roomsPageNumber() {
    if (!this.hasRoomsPage()) return null;
    return this.currentRoomsPage.number + 1;
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  openRoomDetails(room: RoomDef) {
    this.router.navigate(["school",this.schoolId,"rooms",room.uuid,"details"]);
  }
}
