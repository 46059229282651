import { Directive, EventEmitter, Output, HostListener, Renderer2, ElementRef } from '@angular/core';

@Directive({
    selector: '[appFileDrop]',
    standalone: false
})
export class FileDropDirective {

  @Output()
  fileDrop = new EventEmitter<any>();
  constructor(private renderer: Renderer2, private hostElement: ElementRef) { }

  @HostListener('dragover', ['$event'])
  onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.addClass(this.hostElement.nativeElement, 'file-over');
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.hostElement.nativeElement, 'file-over');
  }

  @HostListener('drop', ['$event']) public ondrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.hostElement.nativeElement, 'file-over');
    const files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDrop.emit(files);
    }
  }
}
