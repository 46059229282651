import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { Pageable, Page } from 'src/app/model/rest/base';
import { LessonBundeFilter, ApiLessonBundle } from 'src/app/model/rest/rest-model';
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {StudentBundleExtendComponent} from "../student-bundle-extend/student-bundle-extend.component";


@Component({
    selector: 'app-student-purchase-history',
    templateUrl: './student-purchase-history.component.html',
    styleUrls: ['./student-purchase-history.component.css'],
    standalone: false
})
export class StudentPurchaseHistoryComponent implements OnInit {
  _studentId: number;
  private pageable: Pageable;
  private filter: LessonBundeFilter;
  results: ApiLessonBundle[];
  lastPage: Page<ApiLessonBundle>;
  hasNext = false;
  _schoolId: number;
  @ViewChild('bundleExtendComponent')
  bundleExtendComponent: StudentBundleExtendComponent

  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
  }

  constructor(
    private managerRest: ManagerRestService
  ) {}

  refresh() {
    this.results = [];
    this.loadThePage();
  }

  initDataLoading(): any {
    if (this._studentId == null) { return; }
    this.clear();
    this.pageable = Pageable.of(0, 10 , ['lb.date,desc']);
    this.filter = new LessonBundeFilter(null, null);
    this.loadThePage();
  }

  loadThePage(): any {
    if (!this._schoolId) return;
    this.managerRest.findStudentLessonBundles(this._schoolId, this._studentId, this.filter, this.pageable)
      .subscribe( page => this.handleResultPage(page));
  }

  handleResultPage(page: Page<ApiLessonBundle>): void {
    this.lastPage = page;
    this.results = this.results.concat(page.content);
    this.hasNext = !page.last;
  }

  next() {
    if (!this.hasNext || !this.pageable || !this.filter) { return; }
    this.pageable = this.pageable.next();
    this.loadThePage();
  }

  clear(): any {
    this.pageable = null;
    this.filter = null;
    this.results = [];
    this.hasNext = false;
    this.lastPage = null;
  }

  ngOnInit() {
    this.initDataLoading();
  }

  changeExpiryDate(bundle: ApiLessonBundle) {
    this.bundleExtendComponent.openFor(bundle)
  }
}
