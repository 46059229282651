import {Component, Input, OnInit} from '@angular/core';
import {ContactEntry, ContactType, School, SchoolStatus} from "../../../model/rest/casa-apiv2.model";
import {TimeUnits} from "../../../services/utils/calendar-utils";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-dashboard-school-info-block',
    templateUrl: './dashboard-school-info-block.component.html',
    styleUrls: ['./dashboard-school-info-block.component.css'],
    standalone: false
})
export class DashboardSchoolInfoBlockComponent implements OnInit {

  constructor() { }

  @Input() school: School

  ngOnInit() {
  }

  getName() {
    return this.school.details.name;
  }

  getStatusClass() {
    switch (this.school.status) {
      case SchoolStatus.ACCREDITED:
      case SchoolStatus.ACCREDITED_NO_CSPA:
      case SchoolStatus.PROSPECT:
      case SchoolStatus.PRE_ACCREDITED_NO_CSPA:
      case SchoolStatus.PRE_ACCREDITED:
        return "text-success";
      case SchoolStatus.EXPIRED_NO_CSPA:
      case SchoolStatus.EX:
      case SchoolStatus.EXPIRED_CSPA:
      case SchoolStatus.BLACKLISTED:
        return "text-danger";
    }
    return "";
  }

  getStatusName() {
    switch (this.school.status) {
      case SchoolStatus.ACCREDITED: return "Accredited";
      case SchoolStatus.ACCREDITED_NO_CSPA: return "Accredited no Cspa";
      case SchoolStatus.BLACKLISTED: return "Blacklisted";
      case SchoolStatus.NEW: return "New";
      case SchoolStatus.EXPIRED_CSPA: return "Expired";
      case SchoolStatus.COLD_LEAD: return "";
      case SchoolStatus.EX: return "Ex";
      case SchoolStatus.EXPIRED_NO_CSPA: return "Expired No Cspa";
      case SchoolStatus.NON_ACCREDITED: return "Non accredited";
      case SchoolStatus.PRE_ACCREDITED: return "Pre-accredited";
      case SchoolStatus.PRE_ACCREDITED_NO_CSPA: return "Pre-accredited no Cspa";
      case SchoolStatus.PROSPECT: return "Prospect";
    }
  }

  getExpiryDate() {
    return this.school.expDate;
  }

  getAddressLine(line: number) {
    const address = this.school.details.address;
    if (!address) return null;
    switch (line){
      case 0: return address.line1;
      case 1: return address.line2;
      case 2: return address.line3;
      case 3: return  address.line4;
    }
    return null;
  }

  getAddressArea(){
    const address = this.school.details.address;
    if (!address) return null;
    const areaComponents = [];
    if (address.postal) areaComponents.push(address.postal);
    if (address.city) areaComponents.push(address.city);
    const lastLineComponents = [];
    if (areaComponents.length > 0) {
      lastLineComponents.push(areaComponents.join(" "));
    }
    if (address.country) {
      lastLineComponents.push(address.country);
    }
    if (lastLineComponents.length === 0) {
      return null;
    }
    return lastLineComponents;
  }

  getContacts(): ContactEntry[] {
    const contacts = this.school.details.contacts;
    if (!contacts || contacts.length === 0) return [];
    return contacts;
  }

  getContact(contact: ContactEntry) {
    return contact.value;
  }

  getContactLabel(contact: ContactEntry) {
    switch (contact.entryType) {
      case ContactType.CUSTOM: return "other:";
      case ContactType.EMAIL: return "mail:";
      case ContactType.PHONE: return "phone:";
      case ContactType.SKYPE: return "skype:";
      case ContactType.WEBSITE: return "web page:";
    }
    return "";
  }

  hasSchool() {
    return this.school != null;
  }

  expires() {
    if (!this.school.expDate) return false;
    return new Date(this.school.expDate).getTime() < new Date().getTime() + TimeUnits.Days(60).toMilis();
  }

  getCasaAccreditationLink() {
    if (environment.env === 'prod') {
      return `https://casa.callanonline.com/app/main#!/schools/${this.school?.id}`;
    } else {
      return `https://casa-dev.callanonline.com/app/main#!/schools/${this.school?.id}`;
    }
  }
}
