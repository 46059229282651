import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  Competence,
  Course,
  CourseTeacherProfileDetails,
  TeacherProfileDetails
} from "../../model/rest/casa-apiv2.model";

@Component({
    selector: 'app-manager-casa-teacher-profile',
    templateUrl: './manager-casa-teacher-profile.component.html',
    styleUrls: ['./manager-casa-teacher-profile.component.scss'],
    standalone: false
})
export class ManagerCasaTeacherProfileComponent implements OnInit {

  courses = [Course.en, Course.enBs, Course.enKid, Course.es];
  courseNames = ["English", "Business English", "English for kids", "Spanish"];
  competences = [Competence.A,
    Competence.B, Competence.C, Competence.D, Competence.PROVA, Competence.TEST]

  private _profile: TeacherProfileDetails;
  activeIdx = 0;
  @Input()
  busy = true;

  @Input()
  set profile(value: TeacherProfileDetails) {
    this._profile = value;
    this.prepareProfile();
  }

  @Output()
  saveProfile = new EventEmitter<TeacherProfileDetails>();

  get profile() {
    return this._profile;
  }

  private prepareProfile() {
    if (!this._profile) return;
    if (!this.profile.courseDetails) this.profile.courseDetails = [];
    this._profile.courseDetails.forEach( courseDetails => {
      if (!courseDetails.competences) courseDetails.competences = [];
    })
  }

  constructor() { }

  ngOnInit(): void {
  }

  activate(i: number) {
    this.activeIdx = i;
  }

  hasCourse(course: Course) {
    return !!this.getCourse(course);
  }

  getCourse(course: Course) {
    if (!this._profile || !this._profile.courseDetails) return null;
    return this._profile.courseDetails.find( cd => cd.course === course)
  }

  activateLang(course: Course) {
    if (this.hasCourse(course)) return;
    const newCourse = new CourseTeacherProfileDetails();
    newCourse.course = course;
    newCourse.competences = [];
    this._profile.courseDetails.push(newCourse);
  }

  hasCompetence(course: Course, competence: Competence) {
    const courseContext = this.getCourse(course);
    if (!courseContext) return false;
    return !!courseContext.competences.find(c => c == competence);

  }

  changeCompetence(course: Course, competence: Competence) {
    const courseContext = this.getCourse(course);
    if (!courseContext) return;
    const competenceFound = courseContext.competences.indexOf(competence);
    if (competenceFound >= 0) {
      courseContext.competences.splice(competenceFound, 1)
    } else {
      courseContext.competences.push(competence);
    }
  }

  removeCourse(course: Course) {
    const courseContext = this.getCourse(course);
    if (!courseContext) return;
    this._profile.courseDetails.splice(this._profile.courseDetails.indexOf(courseContext),1);
  }

  onSave() {
    this.saveProfile.emit(this._profile);
  }
}
