import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {
  ConflictResults,
  ScheduleCalendarDisplay,
  ScheduleSearchService,
  SearchQuery,
  SearchResults
} from "../../../services/schedule-search.service";
import {MySearchQuery} from "../../schedules-week-summary/schedules-week-summary.component";
import {ActivatedRoute} from "@angular/router";
import {DateUtils} from "../../../services/helpers/date-utils";
import {ScheduleRowSimplified} from "../../../model/schedule";
import {switchMap, take, tap, timeoutWith} from "rxjs/operators";
import {ScheduleListQuery, SchedulesListProvider} from "../../schedules-list/schedules-list.provider";
import {of} from "rxjs";
import {SearchingContextService} from "../../../services/searching-context.service";
import {ScheduleRestService} from "../../../services/rest/schedule-rest.service";
import {ApiTeacherProfile} from "../../../model/rest/rest-model";
import {ManagerRestService} from "../../../services/col2/manager-rest.service";

@Component({
    selector: 'app-teacher-schedules-week-summary-calendar',
    templateUrl: './teacher-schedules-week-summary-calendar.component.html',
    styleUrls: ['./teacher-schedules-week-summary-calendar.component.scss'],
    standalone: false
})
export class TeacherSchedulesWeekSummaryCalendarComponent implements OnInit, AfterViewChecked {
  schoolId: number;
  getWeekStartDate: number;
  getWeekEndDate: number;
  searchResult: SearchResults;
  conflictedSchedules: Set<number> = new Set();
  searchQuery = new MySearchQuery(SearchQuery.BY_TEACHER, "");
  toShow: ScheduleCalendarDisplay;
  isScheduleLoading = false;
  conflicts: ConflictResults;
  mDataSet: ScheduleRowSimplified[];
  teacher: ApiTeacherProfile
  teacherId: number
  schedulesDataProvider: SchedulesListProvider
  constructor(
    route: ActivatedRoute,
    private searchService: ScheduleSearchService,
    private searchingContext: SearchingContextService,
    private scheduleRest: ScheduleRestService,
    private managerRest: ManagerRestService
  ) {
    route.parent.paramMap.pipe(
      tap(params => {
        this.schoolId = Number(params.get('schoolId'));
        this.teacherId = Number(params.get('teacherId'));
      }),
      switchMap(_ => this.managerRest.getTeacher(this.schoolId, this.teacherId)),
      tap(teacher => this.teacher = teacher),
      switchMap(_ => this.loadDataForContext())
    ).subscribe();
  }


  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.getCalendarStartPosition()
  }

  private loadDataForContext() {
    const contextPath = `/schools/${this.schoolId}/schedules`;
    const initialQuery = new ScheduleListQuery(0, null);
    return this.searchingContext.getOrCreateContext(contextPath).pipe(
      take(1),
      timeoutWith(100, of(initialQuery)),
      tap((initialQuery: ScheduleListQuery) => {
        this.schedulesDataProvider = new SchedulesListProvider(
          contextPath,
          this.searchingContext,
          this.scheduleRest,
          this.schoolId,
          initialQuery
        )
        this.loadData()
      })
    )
  }

  getCalendarStartPosition() {
    let schedules = document.body.getElementsByClassName("bg-calendar")
    if(schedules.length) {
      let schedule = (schedules[0] as HTMLElement)
      let tableHeader = document.getElementById("table-header")

      document.getElementById("calendar")
        .scrollTo({
          left: 0,
          top: (schedule.offsetParent as HTMLElement).offsetTop - tableHeader.clientHeight
        })
    }
  }

  private prepareDataForDisplay() {
    if (!this.searchQuery || !this.mDataSet) return;
    this.searchResult = this.searchService.search(this.mDataSet, this.searchQuery);
    this.toShow = this.searchService.prepareDisplayCalendar(this.searchResult.results);
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.loadData();
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.loadData();
  }

  private loadData() {
    this.getWeekStartDate = DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
    this.getWeekEndDate = DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
    this.schedulesDataProvider?.doLoad().subscribe(page => {
      this.mDataSet = this.filterTeacherSchedules(page);
      this.mDataSet = this.searchService.sortEventsByDate(this.mDataSet);
      this.conflicts = this.searchService.searchForConflicts(this.mDataSet);
      this.findConflictedEvents();
      this.prepareDataForDisplay()
    })
  }

  private filterTeacherSchedules(input: ScheduleRowSimplified[]): ScheduleRowSimplified[] {
    return input.filter(row => row.schedule.details.participants.find(
      participant => participant.role === "Teacher"
        && participant.email === this.teacher?.teacher?.person?.emailAddress))
  }

  private findConflictedEvents() {
    this.conflictedSchedules.clear();
    this.conflicts.byClass.concat(this.conflicts.byTeacher)
      .forEach( it => {
          this.conflictedSchedules.add(it.left.schedule.id);
          this.conflictedSchedules.add(it.right.schedule.id);
        }
      )
  }
}
