import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {switchMap, take, tap, timeoutWith} from "rxjs/operators";
import {VideoServerRestService} from "../../services/rest/video-server-rest.service";
import {SearchingContextService} from "../../services/searching-context.service";
import {RoomDef, RoomProgressReport} from "../../model/server";
import {RoomProgressProvider} from "../../services/helpers/progress-history-list.provider";
import {Pageable} from "../../model/rest/base";
import {of} from "rxjs";
import {Constants} from "../../services/constants";

@Component({
    selector: 'app-room-details-page',
    templateUrl: './room-details-page.component.html',
    styleUrls: ['./room-details-page.component.css'],
    standalone: false
})
export class RoomDetailsPageComponent implements OnInit {
  roomUuid: string;
  roomData: RoomDef;
  progressData: RoomProgressReport;
  historyLength = 0;
  historyDataProvider: RoomProgressProvider = null;
  schoolId: string

  constructor(
    activatedRoute: ActivatedRoute,
    private serverRest: VideoServerRestService,
    private searchingContext: SearchingContextService
    ) {
    activatedRoute.paramMap.pipe(
      tap(params => this.extractParams(params)),
      switchMap( _ => this.loadRoomData()),
      switchMap( _ => this.loadRoomProgress())
    )
      .subscribe( _ => this.restoreRoomProgressSearch() );
  }

  restoreRoomProgressSearch() {
    const contextPath = `/rooms/${this.roomUuid}/progress-history`;
    this.searchingContext.getOrCreateContext<Pageable>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new Pageable(0, Constants.SmallPageSize, ['createDate,desc'])))
    ).subscribe(initialPageable =>
      this.historyDataProvider = new RoomProgressProvider(
        this.roomUuid,
        contextPath,
        this.searchingContext,
        this.serverRest,
        initialPageable
      )
    );
  }

  private extractParams(params: ParamMap) {
    this.roomUuid = params.get('roomUuid');
    this.schoolId = params.get('schoolId');
  }

  ngOnInit() {
  }

  private loadRoomData() {
    return this.serverRest.getRoom(this.roomUuid).pipe(
      tap( room => this.storeRoomData(room))
    )
  }

  private storeRoomData(room: RoomDef) {
    this.roomData = room;
  }

  private loadRoomProgress() {
    return this.serverRest.getRoomProgress(this.roomUuid).pipe(
      tap( progress => this.storeRoomProgressData(progress))
    )
  }

  private storeRoomProgressData(progress: RoomProgressReport) {
    this.progressData = progress;
  }
}
