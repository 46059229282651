import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
  AuthServiceProvider,
  UserRole,
} from 'src/app/services/col2/auth.service';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
    standalone: false
})
export class AdminMenuComponent implements OnInit {
  activeSegments: string[];
  activePath: string;
  private role: string;

  constructor(
    private authService: AuthServiceProvider,
    private router: Router
  ) {
    router.events
      .pipe(filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
                                e instanceof NavigationEnd || 
                                e instanceof NavigationCancel || 
                                e instanceof NavigationError))
      .subscribe((e: RouterEvent) => {
        this.activePath = e['urlAfterRedirects'];
        this.activePath
          ? (this.activeSegments = this.activePath.split('/'))
          : '';
      });
  }

  ngOnInit() {
    this.authService
      .get()
      .pipe(
        switchMap((api) => api.getUserRole()),
        tap((role) => (this.role = role))
      )
      .subscribe();
  }

  logout() {
    this.authService.get().subscribe((api) => api.logout());
  }

  getName() {
    // return ManagerUtils.getName();
  }

  getRole() {
    return this.role === UserRole.Admin ? 'Role Admin' : '';
  }
}
