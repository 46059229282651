import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfirmDialogService} from "../../services/confirm-dialog.service";
import {ModalComponent} from "../modal/modal.component";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent implements OnInit {

  @ViewChild("confirmDialog", {static: true})
  modal: ModalComponent;
  private serviceOpenState = false;

  constructor(
    public confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit(): void {
    this.confirmDialogService.show.subscribe(show => {
      if (show){
        this.serviceOpenState = true;
        this.modal.show(false);
      } else {
        this.serviceOpenState = false;
        this.modal.hide();
      }
    });
    this.modal.state.subscribe(state => {
      if (state != this.serviceOpenState) {
        if (!state) {
          this.confirmDialogService.cancel();
        }
      }
    })
  }

}
