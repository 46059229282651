import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoServerRestService } from 'src/app/services/rest/video-server-rest.service';
import {RoomTemplate, School, SchoolConfig, RoomSettings, TemplateParticipant} from 'src/app/model/server';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { SearchingContextService } from 'src/app/services/searching-context.service';
import { take, timeoutWith} from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { GroupsPageable } from 'src/app/components/room-templates-list/room-templates-list.provider';
import {ScheduleListQuery, SchedulesListProvider} from "../../components/schedules-list/schedules-list.provider";
import {ScheduleRestService} from "../../services/rest/schedule-rest.service";
import {ScheduleRowSimplified} from "../../model/schedule";
import {
  ListEvent as ScheduleListEvent,
  SchedulesListComponent
} from "src/app/components/schedules-list/schedules-list.component"
import {DateUtils} from "../../services/helpers/date-utils";
import {ModalComponent} from "../../components/modal/modal.component";
import {ToastNotificationsService, ToastNotification} from "../../services/toast-notifications.service";
import {CreateScheduleComponent} from "../../components/create-schedule/create-schedule.component";

@Component({
    selector: 'app-manager-dashboard-page',
    templateUrl: './manager-dashboard-page.component.html',
    styleUrls: ['./manager-dashboard-page.component.css'],
    standalone: false
})
export class ManagerDashboardPageComponent implements OnInit, OnDestroy {

  schoolId: number;
  @ViewChild('startConfirmModal', {static: true})
  startConfirmModal: ModalComponent;
  @ViewChild('schedulesListComponent', {static: true})
  schedulesList: SchedulesListComponent;
  @ViewChild('createScheduleComponent', {static: true})
  createScheduleComponent: CreateScheduleComponent;
  school: School;

  templateToStart: RoomTemplate<TemplateParticipant>;
  scheduleToStart: ScheduleRowSimplified;
  schoolConfig: SchoolConfig;
  uploadProgress: number = null;
  groupsSearching = new Subject<GroupsPageable>();
  schedulesDataProvider: SchedulesListProvider;
  private currentScheduleRefreshingTimeout: any;
  roomPlannedStartDate: string;
  scheduleDataSet: ScheduleRowSimplified[];
  isScheduleLoading = false;

  constructor(activatedRoute: ActivatedRoute,
              private router: Router,
              private rest: VideoServerRestService,
              private notifService: ToastNotificationsService,
              private searchingContext: SearchingContextService,
              private scheduleRest: ScheduleRestService) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.setupSchool(Number(params.get('schoolId')));
      this.restoreSchedulesSearching();
    });
  }


  private restoreSchedulesSearching() {
    const contextPath = `/schools/${this.schoolId}/schedules`;
    const initialQuery = new ScheduleListQuery( 0, null);
    this.searchingContext.getOrCreateContext(contextPath).pipe(
      take(1),
      timeoutWith(100, of(initialQuery)),
    ).subscribe(
      (initialQuery: ScheduleListQuery) =>
        this.schedulesDataProvider = new SchedulesListProvider(
          contextPath,
          this.searchingContext,
          this.scheduleRest,
          this.schoolId,
          initialQuery
        )
    )
  }


  setupSchool(schoolId: number) {
    this.schoolId = schoolId;
    console.log(schoolId);
    this.rest.getSchoolData(schoolId).subscribe( school => this.school = school);
    this.rest.getSchoolConfig(schoolId) .subscribe( schoolConfig => this.schoolConfig = schoolConfig);
  }

  getUploadProgressStyle() {
    if (!this.uploadProgress) {
      return { width : '0'};
    }
    return {width: `${this.uploadProgress}%`};
  }

  onSchedulesEvent(event: ScheduleListEvent) {
    // if (event.event === ScheduleListEvent.EVENT_DELETE) {
    //   this.scheduleRest.deleteSchedule(this.schoolId, event.item.schedule.id, this.deleteFollowing).subscribe( _ => {
    //     this.schedulesList.refreshData();
    //   });
    // } else
    if (event.event === ScheduleListEvent.EVENT_OPEN) {
      this.router.navigate(["school", this.schoolId, "schedules", event.item.schedule.id])
    } else if (event.event === ScheduleListEvent.EVENT_START) {
      this.scheduleToStart = event.item;
      this.startConfirmModal.show(true);
    }
  }

  startPlannedTemplate() {
    this.startConfirmModal.hide();
    if (this.templateToStart) {
      const roomSettings = new RoomSettings();
      const parsedDate = DateUtils.getParsedTime(this.roomPlannedStartDate);
      this.roomPlannedStartDate = null;
      if (parsedDate) roomSettings.plannedStartDate = parsedDate.getTime();
      this.rest.createRoomWithTemplate(this.schoolId, this.templateToStart.id, roomSettings).subscribe(room =>
        this.router.navigate(['rooms', room.uuid, 'details'])
      );
    } else if (this.scheduleToStart) {
      this.scheduleRest.updateScheduleState(this.schoolId, this.scheduleToStart.schedule.id, "PENDING").subscribe( _ => {
        this.startConfirmModal.hide();
        this.schedulesList.refreshData();
      })
    }
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    if (!this.isSchoolReady()) {
      return null;
    }

    return this.school.name;
  }

  getLogoUrl() {
    if (!this.schoolConfig || !this.schoolConfig.logo || !this.schoolConfig.logo.accessUrl) {
      return null;
    }
    return this.schoolConfig.logo.accessUrl;
  }

  ngOnInit() {
    this.startSchedulesRefreshing();
  }

  uploadFile(event) {
    if (event.length === 0) {
      return;
    }

    this.rest.uploadLogoFile(this.schoolId, event[0]).subscribe( requestEvent  => {
      if (requestEvent.type === HttpEventType.UploadProgress ) {
        this.uploadProgress = Math.round(100 * requestEvent.loaded / requestEvent.total);
      } else if (requestEvent instanceof HttpResponse) {
        this.schoolConfig = requestEvent.body;
        this.uploadProgress = null;
      }
    }, _ => {
      this.notifService.display(new ToastNotification('Error while uploading the file', 'Check the file size'));
    });
  }

  getSchedulesDataProvider() {
    return this.schedulesDataProvider;
  }

  ngOnDestroy(): void {
    this.stopSchedulingRefreshTimeout();
  }

  private startSchedulesRefreshing() {
    this.currentScheduleRefreshingTimeout = setTimeout(() => {
      this.schedulesList.refreshData();
      this.startSchedulesRefreshing();
    }, 60*1000);
  }

  private stopSchedulingRefreshTimeout() {
    clearTimeout(this.currentScheduleRefreshingTimeout);
  }

  onScheduleDataSet(dataSet: ScheduleRowSimplified[]) {
    this.scheduleDataSet = dataSet;
  }

  getSchedulesCount() {
    if (!this.scheduleDataSet) return 0;
    return this.scheduleDataSet.length;
  }

  onScheduleLoading(isScheduleLoading: boolean) {
    this.isScheduleLoading = isScheduleLoading;
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.schedulesList.refreshData();
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider && this.schedulesDataProvider?.queryArgs && this.schedulesDataProvider?.queryArgs.weekOffset != null;
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider?.queryArgs.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider?.queryArgs.weekOffset));
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.schedulesList.refreshData();
  }

  createNewSchedule() {
    this.createScheduleComponent.show();
  }
}
