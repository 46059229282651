import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {map, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {ApiOrderItem, ApiProduct} from "../../model/rest/old-casa-api.model";
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {
  ProductsListDataAdapter,
} from "../../components/account-products/account-products.component";



@Component({
    selector: 'app-students-products-page',
    templateUrl: './students-products-page.component.html',
    styleUrls: ['./students-products-page.component.css'],
    standalone: false
})
export class StudentsProductsPageComponent implements OnInit {
  schoolId: number;
  private studentId: number;
  adapter: ProductsListDataAdapter

  constructor(
    route: ActivatedRoute,
    private casaRest: CasaRestApiV2
    ) {
    route.parent.paramMap.pipe(
      map( params => [Number(params.get("schoolId")), Number(params.get("studentId"))]),
      tap( ([schoolId, studentId]) => this.storeIds(schoolId, studentId)),
    ).subscribe(
      ([schoolId, studentId]) => {
        this.adapter = {
          listProducts(): Observable<ApiOrderItem[]> {
            return casaRest.studentProducts(schoolId, studentId);
          },
          syncProducts(products: ApiProduct[]): Observable<any> {
            return casaRest.syncStudentProducts(schoolId, studentId, products);
          }
        }
      }
    )
  }

  ngOnInit() {
  }

  private storeIds(schoolId: number, studentId: number) {
    this.schoolId = schoolId;
    this.studentId = studentId;
  }

}
