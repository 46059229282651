import { Component, OnInit } from '@angular/core';
import { map, switchMap, tap} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {Page, Pageable} from "../../../model/rest/base";
import {GroupSimple} from "../../../model/group";
import {LocalState, SortingRule} from "../../../services/utils/manager-utils";
import {LocalStateService} from "../../../services/local-state.service";

class GroupsSorting extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static ByName = new GroupsSorting("By Name", ['details.name,asc']);
  static ByNameDesc = new GroupsSorting("By Name (descending)", ['details.name,desc']);
  static ByDate = new GroupsSorting("By Date", ['createDate,asc']);
  static ByDateDesc = new GroupsSorting("By Date (descending)", ['createDate,desc']);
  static values = [
    GroupsSorting.ByName, GroupsSorting.ByNameDesc, GroupsSorting.ByDate,GroupsSorting.ByDateDesc
  ]
}

@Component({
    selector: 'app-group-list-page',
    templateUrl: './group-list-page.component.html',
    styleUrls: ['./group-list-page.component.scss'],
    standalone: false
})
export class GroupListPageComponent implements OnInit {

  schoolId: number;
  pageable = Pageable.of(0, 10, [])
  groups: Page<GroupSimple>

  currentState: LocalState<GroupsSorting>
  sortingRules = GroupsSorting.values;

  constructor(activatedRoute: ActivatedRoute,
              private groupRest: GroupRestServiceImpl,
              private localStateService: LocalStateService
  ) {
    activatedRoute.parent.paramMap.pipe(
      map( params =>     this.schoolId = Number(params.get('schoolId'))),
      tap( _ => this.listSchools(this.pageable)),
      switchMap( _ => this.restoreGroupLocalState()),
      switchMap( state => this.loadPage(state))
    ).subscribe();
  }

  ngOnInit(): void {
  }

  private restoreGroupLocalState() {
    const contextPath = `/schools/${this.schoolId}/v2_groups`;
    return this.localStateService.get<LocalState<GroupsSorting>>(contextPath,
      () => new LocalState<GroupsSorting>("", GroupsSorting.ByDateDesc, Pageable.of(0, 10, null))
    )
  }

  getGroupsNumber() {
    return this.groups?.numberOfElements
  }

  listSchools(pageable: Pageable) {
    this.groupRest.listSchoolGroups(this.schoolId, pageable).subscribe(
      groups => this.groups = groups
    )
  }
  restartSearch() {
    this.currentState.page = this.currentState.page.first();
    this.doSearch();
  }
  doSearch() {
    this.loadPage(this.currentState).subscribe();
  }

  private loadPage(state: LocalState<GroupsSorting>) {
    const contextPath = `/schools/${this.schoolId}/v2_groups`;
    this.currentState = state;
    state.page.sort = state.sortingRule.sorting;
    return this.localStateService.set(contextPath, state).pipe(
      switchMap(state => this.groupRest.listSchoolGroups(this.schoolId, state.page)),
      map(groupPage => {
        groupPage.content = groupPage.content.filter(g => g.details.name.includes(state.term))
        return groupPage
      }),
      tap(groupsPage => this.groups = groupsPage)
    )
  }

}
