import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {Adapter} from "../../person-autocomplete/person-autocomplete.component";
import {GroupParticipant} from "../../../model/group";
import {
  PersonDetails,
  Student,
  StudentBase,
  StudentPersonDetails,
  Teacher, TeacherBase
} from "../../../model/rest/casa-apiv2.model";
import {ActivatedRoute} from "@angular/router";
import {map} from "rxjs/operators";
import {GroupRestServiceImpl} from "../../../services/rest/group-rest.service";
import {Pageable} from "../../../model/rest/base";
import {formatDate} from "@angular/common";
import {NgForm} from "@angular/forms";

@Component({
    template: '',
    standalone: false
})
export abstract class GroupParticipantCreate<P extends Student | Teacher, PB extends StudentBase | TeacherBase, PD extends StudentPersonDetails | PersonDetails> implements OnInit {
  schoolId: number;
  pageable = Pageable.of(0, 10, [])
  isLoading = false
  participantAdapter: Adapter<P>;
  participant: GroupParticipant<P>
  isParticipantBeingAdded = false
  isParticipantBeingEdited = false
  labels = ""
  isParticipantSelected = false
  @Input() groupId: number
  @Output() reloadGroupEmitter = new EventEmitter<void>()
  @ViewChild('groupParticipantForm') groupParticipantForm: NgForm
  protected constructor(protected activatedRoute: ActivatedRoute,
                        protected groupRest: GroupRestServiceImpl,
                        protected casa: CasaRestApiV2) {
    activatedRoute.paramMap.pipe(
      map(params => {
        this.schoolId = Number(params.get('schoolId'))
      })
    ).subscribe(_ => this.setupAutocompleteAdapters());
  }

  ngOnInit(): void {
  }

  abstract resetParticipantObject()
  abstract setupAutocompleteAdapters()
  abstract addParticipant()
  abstract participantSelected(parti: GroupParticipant<P>, selectedParti: P)
  abstract saveParticipant()

  prepareParticipantEdition(parti: GroupParticipant<P>) {
    this.isParticipantBeingEdited = true
    this.addParticipant()
    this.participantSelected(parti, parti.details.participant)
    this.labels = this.participant.details.labels.join(', ')
    this.participant.details.from = this.convertDate(this.participant.details.from)
    this.participant.details.to = this.convertDate(this.participant.details.to)
  }

  unlinkParticipant() {
    this.isParticipantSelected = false
  }

  cancelAddingParticipant() {
    this.isParticipantBeingAdded = false
    this.isParticipantSelected = false
    this.resetParticipantObject()
  }

  private convertDate(date: number) {
    if(date) return formatDate(date, "yyyy-MM-dd", 'en') as unknown as number;
  }

}

