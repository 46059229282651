import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchedulesListProvider} from "./schedules-list.provider";
import {HybridState} from "../../model/server";
import {ScheduleRowSimplified} from "../../model/schedule";
import {finalize, tap} from "rxjs/operators";
import {animate, state, style, transition, trigger} from "@angular/animations";

export class ListEvent {
  public static EVENT_OPEN = 'open';
  public static EVENT_START = 'start';
  public static EVENT_DELETE = 'delete';
  constructor(public event: string, public item: ScheduleRowSimplified) {}
}

@Component({
    selector: 'app-schedules-list',
    templateUrl: './schedules-list.component.html',
    styleUrls: ['./schedules-list.component.css'],
    animations: [
        trigger('loading', [
            state('loaded', style({
                opacity: "0"
            })),
            state('loading', style({
                opacity: "1"
            })),
            transition('loading <=> loaded', [
                animate('0.2s')
            ])
        ]),
    ],
    standalone: false
})
export class SchedulesListComponent implements OnInit {
  private mDataProvider: SchedulesListProvider;
  dataRows: ScheduleRowSimplified[] = [];

  @Input()
  showDelete = true;
  @Input()
  showTemplateName = true;
  isLoading = false;
  @Input()
  showUpcomingOnly = false;
  @Input()
  showPlay = true;


  @Output()
  loading = new EventEmitter<boolean>();
  @Output()
  listEvent = new EventEmitter<ListEvent>();
  @Output()
  dataSet = new EventEmitter<ScheduleRowSimplified[]>();
  private hourMs = 1000 * 60 * 60;

  @Input()
  set dataProvider( p: SchedulesListProvider) {
    this.mDataProvider = p;
    if (p) {
      this.refreshData();
    }
  }

  constructor() { }

  ngOnInit() {
  }

  refreshData() {
    if (!this.mDataProvider) return;
    this.isLoading = true;
    this.loading.emit(true);
    this.mDataProvider.doLoad().pipe(
      tap( page => this.storePage(page)),
      finalize( () => {
        this.isLoading = false;
        this.loading.emit(false);
      })
    ).subscribe();
  }

  storePage(page: ScheduleRowSimplified[]) {
    if (this.showUpcomingOnly) {
      this.dataRows = this.filterForUpcoming(page)
    } else {
      this.dataRows = page;
    }
    this.dataSet.emit(page);
  }

  private filterForUpcoming(input: ScheduleRowSimplified[]): ScheduleRowSimplified[] {
    const res: ScheduleRowSimplified[] = [];
    const completed = input.filter(s => s.schedule.state === 'COMPLETE');
    if (completed.length > 0) {
      res.push(completed[completed.length - 1]);
    }
    const running = input.filter( s => s.schedule.state === 'PENDING' || s.schedule.state === 'IN_PROGRESS');
    if (running.length > 0) res.push(...running);
    const planned = input.filter(s => s.schedule.state === 'PLANED');
    if (planned.length > 0) {
      const numberToTake = Math.min(5, planned.length);
      res.push(...planned.slice(0, numberToTake))
    }
    return res;
  }

  getTemplateName(row: ScheduleRowSimplified) {
    return row.template.details.name;
  }

  getTeacher(row: ScheduleRowSimplified) {
    const teacher = row.schedule.details.participants.find( p => p.role === 'Teacher')
    let email = teacher.email;
    if (email == null) email = 'empty';
    return `${teacher.name} (${email})`
  }

  countActiveStudents(row: ScheduleRowSimplified) {
    if (SchedulesListComponent.isOffline(row)) return 0;
    return row.schedule.details.participants.filter( p => p.role === 'Student' && !p.offline).length;
  }

  countOfflineStudents(row: ScheduleRowSimplified) {

    if (SchedulesListComponent.isOffline(row)) {
      return row.schedule.details.participants.filter(p => p.role === 'Student').length;
    }
    return row.schedule.details.participants.filter( p => p.role === 'Student' && p.offline).length;
  }

  public static isOffline(row: ScheduleRowSimplified) {
    if (row.schedule.details.hybridState === HybridState.Offline) return true;
    return row.schedule.details.hybridState == null
      && row.template.details.hybridState === HybridState.Offline;

  }

  getScheduleStatus(row: ScheduleRowSimplified) {
    switch(row.schedule.state) {
      case 'PENDING': return 'links sent';
      case 'IN_PROGRESS': return 'in progress';
      case 'COMPLETE': return 'finished';
      case 'PLANED': return 'planned';
    }
    return row.schedule.state;
  }

  getScheduleTime(row: ScheduleRowSimplified) {
    return row.schedule.details.startDate;
  }
  getScheduleEndTime(row: ScheduleRowSimplified) {
    return row.schedule.details.startDate + row.schedule.details.durationMin * 60 * 1000;
  }

  onOpen(row: ScheduleRowSimplified) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_OPEN, row));
  }

  onStart(row: ScheduleRowSimplified) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_START, row));
  }

  onDelete(row: ScheduleRowSimplified) {
    this.listEvent.emit(new ListEvent(ListEvent.EVENT_DELETE, row));
  }

  getStatusColorClass(row: ScheduleRowSimplified): string {
    switch(row.schedule.state) {
      case 'PENDING': return 'bg-primary text-light';
      case 'IN_PROGRESS': return 'bg-primary text-light';
      case 'COMPLETE': return 'bg-light text-muted';
      case 'PLANED': return 'bg-warning';
    }
    return 'bg-warning';
  }

  isEmpty() {
    return this.dataRows?.length === 0;
  }

  mayDelete(row: ScheduleRowSimplified){
    return this.showDelete && row.schedule.state === 'PLANED';
  }

  mayStart(row: ScheduleRowSimplified){
    return this.showPlay &&
      row.schedule.state === 'PLANED'
      && row.schedule.details.startDate < (new Date().getTime() + this.hourMs);
  }

  getTeacherName(row: ScheduleRowSimplified) {
    const teacher = row.schedule.details.participants.find( p => p.role === 'Teacher');
    return (teacher) ? teacher.name : "";
  }

  getTeacherEmail(row: ScheduleRowSimplified) {
    const teacher =  row.schedule.details.participants.find( p => p.role === 'Teacher');
    return (teacher) ? teacher.email : "";
  }

  getScheduleBgClass(schedule: ScheduleRowSimplified) {
    if(schedule.schedule.state === 'PLANED')
      return 'bg-warning'
    else if(schedule.schedule.state === 'PENDING')
      return 'bg-info'
    else if(schedule.schedule.state === 'IN_PROGRESS')
      return 'bg-info'
    else if(schedule.schedule.state === 'COMPLETE')
      return 'bg-success'
    return null
  }
}
