import { Component, OnInit } from '@angular/core';
import {AuthServiceImpl, AuthServiceProvider} from '../../services/col2/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    standalone: false
})
/*
do the login page - prepare all required
data to invoke oauth redirect with authorization on the "/"
page.
Is used when application wants to force the authorization process
or may be used as landing page target url.
*/
export class LoginComponent implements OnInit {

  constructor(
    authService: AuthServiceProvider,
    activatedRoute: ActivatedRoute
    ) {
      activatedRoute.queryParams.subscribe(params => {
        if (params['state']) {
          authService.get().subscribe(api => api.startLogin(params['state']));
        } else {
          authService.get().subscribe( api => api.startLogin('/'));
        }
      });
    }

  ngOnInit() {
  }




}
