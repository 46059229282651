import { Directive } from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: '[appPasswordVerifyValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordVerifyValidatorDirective, multi: true }],
    standalone: false
})
export class PasswordVerifyValidatorDirective implements Validator{

  constructor() { }

  registerOnValidatorChange(fn: () => void): void {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const password = control.get('password');
    const passwordVerify = control.get('password-verify');
    return password && passwordVerify && password.value === passwordVerify.value ? null : { differentPassword: true };
  }

}
