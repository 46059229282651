import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {
  HybridState,
  IdentifiedRoomTemplate, IdentifiedTemplateParticipant,
  RoomTemplate,
  TemplateParticipant,
  TemplateParticipantDetailsBase
} from 'src/app/model/server';
import {Regions} from "../../services/helpers/regions";
import {ModalComponent} from "../modal/modal.component";
import {DomSanitizer} from "@angular/platform-browser";
import {Adapter} from "../person-autocomplete/person-autocomplete.component";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {Student, Teacher} from "../../model/rest/casa-apiv2.model";
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";
import {
  StudentAutocompleteAdapter,
  TeacherAutocompleteAdapter
} from "../person-autocomplete/person-autocomplete-adapters";
import {Utils} from "../../services/utils/utils";

@Component({
    selector: 'app-room-template-edit',
    templateUrl: './room-template-edit.component.html',
    styleUrls: ['./room-template-edit.component.css'],
    standalone: false
})
export class RoomTemplateEditComponent implements OnInit {
  get schoolId(): number {
    return this._schoolId;
  }

  @Input()
  set schoolId(value: number) {
    this._schoolId = value;
    if (value) this.setupAutocompleteAdapters();
  }

  private setupAutocompleteAdapters() {
    this.studentAdapter = new StudentAutocompleteAdapter(this.schoolId, this.casa);
    this.teacherAdapter = new TeacherAutocompleteAdapter(this.schoolId, this.casa);
  }

  public static EVENT_SAVE = 'save';
  public static EVENT_CANCEL = 'cancel';

  @ViewChild("settingsModal", {static: true})
  settingsModal: ModalComponent;

  mTemplate: IdentifiedRoomTemplate;
  teacher: IdentifiedTemplateParticipant;
  students: IdentifiedTemplateParticipant[];
  regions = Regions.regions;
  editParticipantSettings: IdentifiedTemplateParticipant;
  onlineGroup = true;

  studentAdapter : Adapter<Student>;
  teacherAdapter: Adapter<Teacher>;

  @Input()
  set template(template: IdentifiedRoomTemplate) {
    this.mTemplate = template;
    this.decomposeData();
  }
  get template() {return this.mTemplate; }
  private _schoolId: number;

  @Output()
  formEvent = new EventEmitter<string>();


  canAdd() {
    if (!this.template) { return false; }
    return this.template.participants.length <= 20;

  }

  addStudent() {
    if (!this.canAdd()) {
      return;
    }
    const student = new IdentifiedTemplateParticipant();
    student.details = new TemplateParticipantDetailsBase();
    student.details.role = 'Student';
    this.template.participants.push(student);
    this.students.push(student);
  }

  removeStudent(participant: IdentifiedTemplateParticipant) {
    this.students.splice(this.students.indexOf(participant), 1);
    this.template.participants.splice(this.template.participants.indexOf(participant), 1);
  }

  onSave() {
    this.template.details.hybridState = (this.onlineGroup) ? null : HybridState.Offline;
    this.formEvent.emit(RoomTemplateEditComponent.EVENT_SAVE);
  }

  onCancel() {
    this.formEvent.emit(RoomTemplateEditComponent.EVENT_CANCEL);
  }

  decomposeData() {
    if (!this.template) {return; }
    this.teacher = this.template.participants.find( p => p.details.role === 'Teacher');
    this.students = this.template.participants.filter ( p => p.details.role === 'Student');
    this.onlineGroup = this.template.details.hybridState !== HybridState.Offline;
  }

  constructor(
    private sanitizer: DomSanitizer,
    private casa: CasaRestApiV2) {}

  ngOnInit() {
  }

  editSettings(student: IdentifiedTemplateParticipant) {
    this.editParticipantSettings = student;
    this.settingsModal.show();
  }

  hasFlags(student: TemplateParticipant) {
    return this.isOffline(student);
  }

  isOffline(student: TemplateParticipant) {
    if (!student) return false;
    return student.details.offline;
  }

  casaTeacherItemSelected(teacher: Teacher) {
    this.teacher.details.name = PersonNameExtractor.getCasaPersonName(teacher.person);
    this.teacher.details.email = teacher.person.registrationEmail;
    this.teacher.eid = `casa:${teacher.id}`;
  }

  casaStudentItemSelected(student: IdentifiedTemplateParticipant, casaStudent: Student) {
    student.details.name = PersonNameExtractor.getCasaPersonName(casaStudent.person);
    student.details.email = casaStudent.person.registrationEmail;
    student.eid = `casa:${casaStudent.id}`;
  }

  isCasaConnected(parti: IdentifiedTemplateParticipant) {
    return parti && parti.eid;
  }

  unlink(parti: IdentifiedTemplateParticipant) {
    parti.id = null;
    parti.eid = null;
    parti.details.name = null;
    parti.details.email = null;
  }

  studentFieldIndex = new Map<IdentifiedTemplateParticipant, number>();
  currentIndexValue = 1;
  getStudentIndex(parti: IdentifiedTemplateParticipant) {
    let res = this.studentFieldIndex.get(parti);
    if (res) return res;
    res = this.currentIndexValue++;
    this.studentFieldIndex.set(parti, res);
    return res;
  }
}
