import {Component, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {VerifiedPasswordChangeRequest} from "../../model/rest/casa-apiv2.model";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";


@Component({
    selector: 'app-person-password-change',
    templateUrl: './person-password-change.component.html',
    styleUrls: ['./person-password-change.component.scss'],
    standalone: false
})
export class PersonPasswordChangeComponent implements OnInit {

  @ViewChild('modal', {static: true})
  modal: ModalComponent
  model: VerifiedPasswordChangeRequest = null;
  error: string = null;
  complete = false;
  inProgress = false;

  @Input()
  changePasswordCallback: (VerifiedPasswordChangeRequest) => Observable<any>;
  passwordRepeat = "";

  constructor() { }

  ngOnInit(): void {
  }

  show() {
    this.model = new VerifiedPasswordChangeRequest();
    this.passwordRepeat = "";
    this.complete = false;
    this.error = null;
    this.modal.show();
  }

  submitActivation() {
    this.error = null;
    this.complete = false;
    this.inProgress = true;
    this.changePasswordCallback(this.model).pipe(
      finalize( () => this.inProgress = false)
    ).subscribe(
      {
        next: _ => {
          this.complete = true;
          this.error = null;
          this.model = null;
        },
        error: err => {
          this.error = err.userMessage ? err.userMessage : err.message;
          this.complete = true;
        }
      }
    )
  }
}
