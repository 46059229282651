import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import {AuthServiceImpl, AuthServiceProvider} from '../../services/col2/auth.service';

@Component({
    selector: 'app-oauth',
    templateUrl: './oauth.component.html',
    styleUrls: ['./oauth.component.css'],
    standalone: false
})
/*
OAuth component - watches for code and state params. After finished token obtain component
switches the page to the url from state param or just to the main "/" if state is missing
*/
export class OauthComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthServiceProvider,
    private router: Router
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
          this.processOauthCode(params);
      });
  }

  private processOauthCode(params) {
    // prevent language auto switch on the authorization time
    const oauthCode = this.mapOauthParams(params);
    this.authService.get().subscribe(api => {
      api.authorizeCode(oauthCode.code).subscribe(() => {
        if (oauthCode.state) {
          const finalState = api.resolveState(oauthCode.state);
          this.router.navigateByUrl(finalState).then(() => {
            // unlock language switching and initialize
          });
        } else {
          this.router.navigate(['/']);
        }
      });
    })
  }

  private mapOauthParams(params): any {
    const res = {code: params.code, state: null as string};
    if (params.state) {
      res.state = atob(params.state);
    }
    return res;
  }

  ngOnInit() {
  }

}
