import { Component, OnInit, Input } from '@angular/core';
import { RoomDef, ParticipantDef, RoomProgressReport } from 'src/app/model/server';
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-room-details',
    templateUrl: './room-details.component.html',
    styleUrls: ['./room-details.component.css'],
    standalone: false
})
export class RoomDetailsComponent implements OnInit {

  room: RoomDef;
  teacherParti: ParticipantDef;
  studentsPartis: ParticipantDef[];
  private linksCopied = new Set<String>();

  @Input()
  set roomData(room: RoomDef) {
    this.storeRoomData(room);
  }

  @Input()
  progress: RoomProgressReport;

  constructor() { }


  getRoomName() {
    if (!this.room) {
      return '';
    }
    return this.room.name;
  }

  copyToClipboard(link: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.linksCopied.clear();
    this.linksCopied.add(link);
    setTimeout(() => this.linksCopied.delete(link), 3000);
  }

  createLink(parti: ParticipantDef) {
    if (!parti) {
      return '';
    }
    const origin = environment.videoWebBase;
    if (parti.role === 'Teacher') {
      return `${origin}/participants/${parti.uuid}/teacher-room`;
    } else {
      return `${origin}/participants/${parti.uuid}/student-room`;
    }
  }

  isShowLinkCopied(link: string) {
    return this.linksCopied.has(link);
  }

  storeRoomData(room: RoomDef): void {
    this.room = room;
    this.teacherParti = null;
    this.studentsPartis = null;
    if (!room) return;
    this.teacherParti = room.participants.find ( p => p.role === 'Teacher');
    this.studentsPartis = room.participants.filter ( p => p.role === 'Student');
  }

  getTeacherName() {
    if (!this.teacherParti) {
      return '';
    }
    return this.teacherParti.name;
  }

  getCreateDate() {
    return this.room.createDate;
  }

  getTimerStartDate() {
    return this.room.timerStartDate;
  }

  getTotalTime() {
    return Math.round(this.room.totalTime / 60000);
  }

  getLessonStartDate() {
    return this.room.lessonStartDate;
  }

  getPCreated() {
    return this.room.provisioning.created;
  }

  getPUpdated() {
    return this.room.provisioning.updated;
  }
  getPDuration() {
    return Math.round(this.room.provisioning.duration / 60);
  }
  getPEndTime() {
    return this.room.provisioning.endTime;
  }
  getPCStatus() {
    return this.room.provisioning.status;
  }
  ngOnInit() {
  }

  getStartPlannedDate() {
    if (!this.room) return null;
    return this.room.plannedDate;
  }

  hasAnyProgress() {
    return this.progress && (this.progress.details.comment
      || this.progress.details.paragraph
      || this.progress.details.dictation
      || this.progress.details.reading
      || this.progress.details.lastHw);
  }

  wasAttended(parti: ParticipantDef) {
    if (!this.progress || !this.progress.attenders) return false;
    const attender = this.progress.attenders.find( it => it.participant.uuid === parti.uuid );
    return attender && attender.attended;
  }

}
