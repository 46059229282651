import { Component, OnInit, Input } from '@angular/core';
import { AllowanceType } from 'src/app/model/rest/rest-model';
import {ManagerRestService} from "../../services/col2/manager-rest.service";

@Component({
    selector: 'app-teacher-allowances',
    templateUrl: './teacher-allowances.component.html',
    styleUrls: ['./teacher-allowances.component.css'],
    standalone: false
})
export class TeacherAllowancesComponent implements OnInit {
  _teacherId: number;
  gdpr = false;
  _schoolId: number;

  constructor(private managerRest: ManagerRestService) { }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.refreshAllowances();
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.refreshAllowances();
  }

  refreshAllowances() {
    if (!this._teacherId || !this._schoolId) {return; }

    this.listAllowances().subscribe(
      allowances => this.setupAllowances(allowances)
    );
  }

  listAllowances() {
    return this.managerRest.listTeacherAllowances(this._schoolId, this._teacherId);
  }

  setupAllowances(allowances: String[]): void {
    this.gdpr = allowances.findIndex( a => a === AllowanceType.TEACHER_GDPR) >= 0;
  }

  ngOnInit() {
  }
}
