import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import {LessonType} from "../../components/teacher-lessons/teacher-lessons.component";
import {Page, Pageable} from "../../model/rest/base";

@Component({
    selector: 'app-manager-teacher-lessons-page',
    templateUrl: './manager-teacher-lessons-page.component.html',
    styleUrls: ['./manager-teacher-lessons-page.component.css'],
    standalone: false
})
export class ManagerTeacherLessonsPageComponent implements OnInit {

  schoolId: string;
  teacherId: string;
  lessonTypes = LessonType;
  futureData: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  futurePageable: Pageable;
  incompleteData: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  incompletePageable: Pageable;
  pastData: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;
  pastPageable: Pageable;

  constructor(activatedRoute: ActivatedRoute,
    private router: Router) {
    activatedRoute.parent.paramMap.subscribe( params => {
      this.schoolId = params.get('schoolId');
      this.teacherId = params.get('teacherId');
    });
  }

  ngOnInit() {
  }

}
