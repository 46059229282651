import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { SearchingContextService } from 'src/app/services/searching-context.service';
import { VideoServerRestService } from 'src/app/services/rest/video-server-rest.service';
import {Subject, of, merge, Observable} from 'rxjs';
import { take, timeoutWith, tap, switchMap, } from 'rxjs/operators';
import { GenericSimpleDataProvider } from 'src/app/model/internal';
import {GroupTiming, RoomDef, RoomTemplate, TemplateParticipant} from 'src/app/model/server';
import { SearchingTerms, ReportDataProvider } from 'src/app/components/activity-report/activity-report.provider';
import { FullRoomsDataProvider,  TemplateIdSearchTermPageable } from 'src/app/services/helpers/rooms-list.provider';
import { Constants } from 'src/app/services/constants';
import {LocalStateService} from "../../services/local-state.service";
import {Page, Pageable} from "../../model/rest/base";
import {School} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";

class RoomsReportState {
  constructor(
    public templateId: number,
    public pageable: Pageable, public terms: SearchingTerms) {
  }
}

class ReportDataProviderWrapper extends ReportDataProvider {
  constructor(private component: SchoolActivitiesPageComponent, path: string, schoolId: number, terms: SearchingTerms) {
    super(path, schoolId, component.rest, terms, component.searchContext);
  }
  doLoad() {
    return super.doLoad().pipe(
      tap( timings => this.component.availableGroups = timings.map( row => row.group))
    );
  }
}

@Component({
    selector: 'app-school-activities-page',
    templateUrl: './school-activities-page.component.html',
    styleUrls: ['./school-activities-page.component.css'],
    standalone: false
})
export class SchoolActivitiesPageComponent implements OnInit {

  schoolId: number;
  mMonth = 0;
  mDuration = 12;
  mTemplateId = -1;
  reportData: GenericSimpleDataProvider<GroupTiming[]>;
  availableGroups: RoomTemplate<TemplateParticipant>[];

  termsSubject = new Subject<SearchingTerms>();
  groupSubject = new Subject<number>();
  private currentRoomsState: RoomsReportState;
  roomsPage: Page<RoomDef>;
  private school: School;

  set month(m: number) {
    this.mMonth = m;
    this.updateDates();
  }

  get month() {
    return this.mMonth;
  }

  set duration(d: number) {
    this.mDuration = d;
    this.updateDates();
  }

  get duration() {
    return this.mDuration;
  }

  set templateId(id: number) {
    this.mTemplateId = id;
    this.groupSubject.next(id);
  }

  get templateId() {
    return this.mTemplateId;
  }

  constructor(activatedRoute: ActivatedRoute,
              public searchContext: SearchingContextService,
              public rest: VideoServerRestService,
              private localStateService: LocalStateService,
              private casa: CasaRestApiV2,
              private router: Router) {
    activatedRoute.parent.paramMap.pipe(
      tap (params => this.schoolId = Number(params.get('schoolId'))),
      switchMap( schoolId => this.loadSchool()),
      switchMap( () => this.restoreDates()),
      switchMap( () => this.restoreRooms()),
      switchMap( state => this.loadRooms(state))
    )
    .subscribe( );

    this.listenForTermsChangesForReports();
    this.listenForInputChangesForRooms();
  }

  listenForTermsChangesForReports() {
    this.termsSubject.pipe(
      tap( terms => {
        const contextPath = `/schools/${this.schoolId}/activity/terms`;
        this.reportData = new ReportDataProviderWrapper(this, contextPath,
          this.schoolId, terms);
        this.currentRoomsState.terms = terms;
        this.loadRooms(this.currentRoomsState)
      })
    ).subscribe();
  }

  listenForInputChangesForRooms() {
    merge(this.termsSubject, this.groupSubject).pipe(
      tap( () => {
        this.currentRoomsState.templateId = this.mTemplateId
      }),
    ).subscribe(
      _ => this.roomFirst()
    );
  }

  roomsContext() {
    return `/schools/${this.schoolId}/activity/rooms`;
  }

  restoreRooms() {
    const path = this.roomsContext();
    return this.localStateService.get<RoomsReportState>(path,
      () => new RoomsReportState(
        this.templateId,
        Pageable.of(0, 10, ['createDate,desc']),
        new SearchingTerms(this.mMonth, this.mDuration)
        )
      ).pipe(
        tap( state => this.mTemplateId = state.templateId),
        tap<RoomsReportState>( state => this.currentRoomsState = state)
    )
  }

  restoreDates() {
    const contextPath = `/schools/${this.schoolId}/activity/terms`;
    return this.searchContext.getOrCreateContext<SearchingTerms>(contextPath).pipe(
      take(1),
      timeoutWith(10, of(new SearchingTerms(0, 1))),
      tap (terms => {
        this.mMonth = terms.month;
        this.mDuration = terms.duration;
        this.reportData = new ReportDataProviderWrapper(this, contextPath,
          this.schoolId, terms);
      })
    );
  }

  updateDates() {
    this.mTemplateId = -1;
    this.termsSubject.next(new SearchingTerms(this.month, this.duration));
  }

  ngOnInit() {
  }

  private loadRooms(state: RoomsReportState) {
    return this.localStateService.set<RoomsReportState>(this.roomsContext(), state).pipe(
      tap(state => this.currentRoomsState = state),
      switchMap( state => this.queryRooms(state)),
      tap<Page<RoomDef>>( rooms => this.roomsPage = rooms)
    )
  }

  private queryRooms(state: RoomsReportState) {
   if (state.templateId > 0) {
      return this.rest.listSchooTemplatelRooms(this.schoolId, state.templateId,
        state.pageable,
        state.terms.month,
        state.terms.duration);
    } else {
      return this.rest.listSchoolRooms(this.schoolId,
        state.pageable,
        state.terms.month,
        state.terms.duration);
    }
  }

  hasRoomData() {
    return !!this.roomsPage;
  }

  hasRoomPrev() {
    return this.hasRoomData() && !this.roomsPage.first;
  }

  hasRoomNext() {
    return this.hasRoomData() && !this.roomsPage.last;
  }

  roomPage() {
    if (!this.roomsPage) return null;
    return this.roomsPage.number + 1;
  }

  roomPrev() {
    this.currentRoomsState.pageable = this.currentRoomsState.pageable.prev();
    this.loadRooms(this.currentRoomsState).subscribe();
  }

  roomFirst() {
    this.currentRoomsState.pageable = this.currentRoomsState.pageable.first();
    this.loadRooms(this.currentRoomsState).subscribe();
  }

  roomNext() {
    this.currentRoomsState.pageable = this.currentRoomsState.pageable.next();
    this.loadRooms(this.currentRoomsState).subscribe();
  }


  getRoomsCount() {
    if (!this.roomsPage) return null;
    return this.roomsPage.totalElements;
  }

  private loadSchool():Observable<School> {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.school = school)
    );
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  openRoomDetails(room: RoomDef) {
    this.router.navigate(["school",this.schoolId,"rooms",room.uuid,"details"]);
  }
}
