import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pageable, Page, RoomProgressReport } from 'src/app/model/server';
import { Observable } from 'rxjs';
import { GenericDataProvider } from 'src/app/model/internal';

@Component({
    selector: 'app-progress-history-list',
    templateUrl: './progress-history-list.component.html',
    styleUrls: ['./progress-history-list.component.css'],
    standalone: false
})
export class ProgressHistoryListComponent implements OnInit {
  progressHistoryLastPage: Page<RoomProgressReport>;
  progressHistory: RoomProgressReport[] = null;

  @Input()
  set dataProvider(provider: GenericDataProvider<RoomProgressReport, any>) {
    this.mDataProvider = provider;
    if (provider) {
      this.resetHistoryPageable();
      this.mDataProvider.load()
      .subscribe(newPage => this.saveProgressHistoryPage(newPage));
    }
  }

  @Input()
  short = false;


  @Output()
  itemsNumber = new EventEmitter<number>();

  mDataProvider: GenericDataProvider<RoomProgressReport, any>;

  constructor() { }

  nextHistoryPage() {
    this.mDataProvider.nextPage();
    this.mDataProvider.load()
    .subscribe(newPage => this.saveProgressHistoryPage(newPage));
  }

  isLastPage() {
    if (!this.progressHistoryLastPage) {
      return true;
    }
    return this.progressHistoryLastPage.last;
  }

  getTeacherName(progress: RoomProgressReport) {
    return progress.teacher?.name || "missing teacher";
  }

  getCreated(progress: RoomProgressReport) {
    return progress.created;
  }

  getAttendance(progress: RoomProgressReport) {
    const numberOfStudents = progress.attenders.length;
    const attendentStudents = progress.attenders.filter( p => p.attended).length;
    return `A: ${attendentStudents} / ${numberOfStudents}`;
  }

  getProgress(progress: RoomProgressReport) {
    return `Para: <strong>${this.getPara(progress)}</strong>\
    / <strong>${this.getHw(progress)}</strong>\
    / R: <strong>${this.getReading(progress)}</strong>\
    / D: <strong>${this.getDict(progress)}</strong>\
    / ${progress.details.comment ? progress.details.comment : ''}
    `;
  }

  saveProgressHistoryPage(progressHistoryPage: Page<RoomProgressReport>): void {
    this.progressHistoryLastPage = progressHistoryPage;
    if (!this.progressHistory) {
      this.progressHistory = [];
    }
    this.progressHistory = this.progressHistory.concat(progressHistoryPage.content);
    if (this.progressHistory.length === 0) {
      this.progressHistory = null;
      this.itemsNumber.emit(0);
    } else {
      this.itemsNumber.emit(this.progressHistoryLastPage.totalElements);
    }
  }

  resetHistoryPageable(): void {
    this.progressHistoryLastPage = null;
    this.progressHistory = null;
  }

  ngOnInit() {
  }

  getHistoryItemHeader(historyItem: RoomProgressReport) {
    let res = this.showMissing(historyItem.details.stage, "missing stage") + " - ";
    res += this.getTeacherName(historyItem);
    return res;
  }

  getLessonType(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.lessonType, "missing type");
  }

  fix(val: string) {
    if (!val || val.trim().length == 0) return null;
    return val.trim();
  }

  showMissing(val: string, missingValue?: string) {
    if (!missingValue) missingValue = '-';
    const res = this.fix(val);
    if (res == null) return missingValue;
    return res;
  }

  getReadingLesson(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.readingLesson);
  }

  getPara(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.paragraph);
  }

  getStart(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.startPage);
  }

  getHw(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.lastHw);
  }

  getReading(historyItem: RoomProgressReport) {
    let res = this.fix(historyItem.details.reading);
    if (res != null) return `up to p. ${res}`;
    return null;
  }

  getDict(historyItem: RoomProgressReport) {
    return this.showMissing(historyItem.details.dictation);
  }

}
