import {Component, Input, OnInit, ViewChild} from '@angular/core';

import {catchError, finalize, switchMap, tap} from "rxjs/operators";
import {
  ConfirmationRequestStatus,
  ConfirmationRequestType, ManualConfirmationRequest,
  School,
  SchoolConfirmationRequest, VerifiedPasswordChangeRequest
} from "../../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {LocalStateService} from "../../../services/local-state.service";
import {Page, Pageable} from "../../../model/rest/base";
import {TimeUnits} from "../../../services/utils/calendar-utils";
import {ModalComponent} from "../../modal/modal.component";

@Component({
    selector: 'app-invitations-block',
    templateUrl: './invitations-block.component.html',
    styleUrls: ['./invitations-block.component.css'],
    standalone: false
})
export class InvitationsBlockComponent implements OnInit {
  private mSchool: School;
  private currentPageable: Pageable;
  private currentPage: Page<SchoolConfirmationRequest>;
  private readonly localStateUrl = "dashboard/invitations";
  private expiryDateFrom = new Date().getTime() - TimeUnits.Days(14).toMilis();

  @ViewChild('activationModal', {static: true})
  activationModal: ModalComponent;
  confirmationToActivate: SchoolConfirmationRequest;
  activationRequestForm: ManualConfirmationRequest;
  userPasswordRetype: string;
  private activationError: any = null;

  @Input()
  set school(value: School) {
    this.mSchool = value;
    this.reload();
  }

  constructor(
    private casa: CasaRestApiV2,
    private localState: LocalStateService
  ) { }

  ngOnInit() {}


  private reload() {
    if (!this.mSchool) return;
    this.currentPage = null;

    this.localState.get<Pageable>(
      this.localStateUrl,
      () => Pageable.of(0, 5, ["expiry,ASC"]))
      .pipe(
        switchMap( page => this.loadPage(page)),
        tap( dataPage => this.store(dataPage))
      )
      .subscribe();
  }

  private loadPage(page: Pageable) {
    this.currentPage = null;
    this.currentPageable = page;
    return this.casa.getSchoolConfirmationRequests(
      this.mSchool.id,
      ConfirmationRequestType.ALL,
      ConfirmationRequestStatus.COMING,
      null,
      this.expiryDateFrom,
      page);
  }

  private store(dataPage: Page<SchoolConfirmationRequest>) {
    this.currentPage = dataPage;
  }

  isReady() {
    return this.currentPage;
  }

  hasPages() {
    return this.hasPrev() || this.hasNext();
  }

  hasPrev() {
    return this.currentPage && !this.currentPage.first;
  }

  prev() {
    this.localState.set(this.localStateUrl, this.currentPageable.prev())
      .pipe(
        switchMap( page => this.loadPage(page)),
        tap( pageData => this.store(pageData))
      )
      .subscribe();
  }

  hasNext() {
    return this.currentPage && !this.currentPage.last;
  }

  next() {
    this.localState.set(this.localStateUrl, this.currentPageable.next())
      .pipe(
        switchMap( page => this.loadPage(page)),
        tap( pageData => this.store(pageData))
      )
      .subscribe();
  }

  getEntries(): SchoolConfirmationRequest[] {
    return this.currentPage.content;
  }

  getMail(entry: SchoolConfirmationRequest) {
    if (!entry) return '';
    return entry.requestedEmail;
  }

  getRole(entry: SchoolConfirmationRequest) {
    if (!entry) return '';
    switch (entry.role) {
      case ConfirmationRequestType.PersonDetailsUpdate: return "update";
      case ConfirmationRequestType.ManagerInvite: return "manager";
      case ConfirmationRequestType.TeacherInvite: return "teacher";
      case ConfirmationRequestType.StudentInvite: return "student";
      case ConfirmationRequestType.StudentInstantInvite: return "student";
    }
    return "unknown";
  }

  getExpiry(entry: SchoolConfirmationRequest) {
    if (!entry) return null;
    return entry.expiryDate;
  }

  isEmpty() {
    return this.currentPage.totalElements === 0;
  }

  resend(entry: SchoolConfirmationRequest) {
    this.casa.renewConfirmationRequest(this.mSchool.id, entry).pipe(
      switchMap( _ => this.loadPage(this.currentPageable)),
      tap( data => this.store(data))
    ).subscribe();
  }

  revoke(entry: SchoolConfirmationRequest) {
    this.casa.revokeConfirmationRequest(this.mSchool.id, entry).pipe(
      switchMap( _ => this.loadPage(this.currentPageable)),
      tap( data => this.store(data))
    ).subscribe();
  }

  private rolesMayBeActivated = [
    ConfirmationRequestType.ManagerInvite, ConfirmationRequestType.TeacherInvite, ConfirmationRequestType.StudentInvite, ConfirmationRequestType.StudentInstantInvite];

  mayBeActivated(entry: SchoolConfirmationRequest) {
    return (entry.status == ConfirmationRequestStatus.Requested
      && entry.expiryDate > new Date().getTime()
      && this.rolesMayBeActivated.indexOf(entry.role) >= 0
    )
  }

  activate(entry: SchoolConfirmationRequest) {
    this.clearActivationData();
    this.confirmationToActivate = entry;
    this.activationModal.show();
  }

  private clearActivationData() {
    this.activationRequestForm = new ManualConfirmationRequest();
    this.activationRequestForm.passwords = new VerifiedPasswordChangeRequest();
    this.userPasswordRetype = null;
    this.activationError = null;
  }

  submitActivation() {
    this.casa.confirmRequestManually(
      this.mSchool.id,
      this.activationRequestForm.passwords.managerPassword,
      this.activationRequestForm.passwords.password,
      this.confirmationToActivate
      ).pipe(
        switchMap( _ => this.loadPage(this.currentPageable)),
        tap( data => this.store(data))
    ).subscribe({
      complete: () => {
        this.clearActivationData();
        this.activationModal.hide();
      },
      error: err => this.activationError = err
    });
  }

  hasError() {
    return !!this.activationError
  }

  getErrorMessage() {
    return this.activationError.userMessage || this.activationError.developerMessage || this.activationError;
  }
}
