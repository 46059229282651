import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {combineLatest, merge, Observable, of} from "rxjs";
import {combineAll, concatAll, map, mergeAll, switchMap, tap} from "rxjs/operators";
import {CspaRestService} from "../../services/rest/cspa-rest.service";
import {Chapter, DefinitionBase, ExerciseSession, ExerciseSet, ItemAvailability} from "../../model/rest/cspa.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {Student} from "../../model/rest/casa-apiv2.model";
import {fromArray} from "rxjs/internal/observable/fromArray";
import {Page, Pageable} from "../../model/rest/base";

@Component({
    selector: 'app-manager-student-cspa-monitor',
    templateUrl: './manager-student-cspa-monitor.component.html',
    styleUrls: ['./manager-student-cspa-monitor.component.scss'],
    standalone: false
})
export class ManagerStudentCspaMonitorComponent implements OnInit {
  schoolId: number;
  exerciseSets: Map<string, ExerciseSet> = null;
  chaptersCache = new Map<string, Chapter[]>();
  private student: Student;
  setAvailabilities: ItemAvailability[] = [];
  innerAvailabilities: Map<string, ItemAvailability> = new Map<string, ItemAvailability>();
  private setSessions: Map<string, Page<ExerciseSession>> = new Map<string, Page<ExerciseSession>>();

  constructor(
    private activeRoute: ActivatedRoute,
    private cspa: CspaRestService,
    private casa: CasaRestApiV2
    ) { }

  ngOnInit() {

    combineLatest(
      [this.activeRoute.parent.parent.paramMap,
      this.activeRoute.parent.paramMap]
    ).pipe(
      map(([parentParams, params]) => [Number(parentParams.get('schoolId')), Number(params.get('studentId'))]),
      tap( ([schoolId,_]) => {
        this.schoolId = schoolId;
      }),
      switchMap(([schoolId, studentId]) =>
        this.casa.getStudent(schoolId, studentId)
      ),
      tap<Student>( student => this.student = student ),
      switchMap( _ => this.loadExerciseSets()),
      switchMap( _ => this.getAvailabilities('', 1)),
      map<ItemAvailability[], ItemAvailability[]>( allSetAvailabilities =>
        allSetAvailabilities.filter( it => it.assigned || it.available && this.exerciseSets.has(it.path) )
      ),
      tap<ItemAvailability[]>(setAvailabilities =>
        this.setAvailabilities = setAvailabilities
      ),
      switchMap<ItemAvailability[], Observable<Observable<[Chapter[], ItemAvailability[], Page<ExerciseSession>]>>>( availabilities =>
        fromArray(availabilities.map( availability =>
          combineLatest([
            this.loadExerciseSetStructure(availability.path),
            this.loadInnerAvailabilities(availability.path),
            this.loadSetSessionsPage(availability.path)
          ])
        ))
      ),
      mergeAll()
    ).subscribe(  );
  }

  loadInnerAvailabilities(path) {
    return this.getAvailabilities(path, 3).pipe(
      tap( setInnerAvailabilities => setInnerAvailabilities.forEach( av => this.innerAvailabilities.set(av.path, av)))
    );
  }

  getAvailabilities(path, depth) {
    return this.cspa.listPersonAvailabilities(this.schoolId, this.student.person.id, path, depth)
  }

  loadExerciseSetStructure(path: string): Observable<Chapter[]> {
    if (this.chaptersCache.has(path)) {
      return of(this.chaptersCache.get(path));
    }
    return this.cspa.listChapters(this.schoolId, path).pipe(
      tap( setChapters => this.chaptersCache.set(path, setChapters))
    )
  }

  loadExerciseSets(): Observable<any> {
    if (this.exerciseSets && this.exerciseSets.size > 0) return of(this.exerciseSets)
    return this.cspa.listExerciseSets(this.schoolId).pipe(
      map( sets =>
        sets.reduce((res,current)=> res.set(current.path, current)
          ,new Map<string, ExerciseSet>())),
      tap( setsMap => this.exerciseSets = setsMap)
    )
  }

  getSetName(availability: ItemAvailability) {
    return this.exerciseSets.get(availability.path).name;
  }

  getItemScore(availability: ItemAvailability) {
    if (!availability || !availability.score) return 0;
    return Math.round(availability.score * 100);
  }

  getItemProgress(availability: ItemAvailability) {
    if (!availability || !availability.progress) return 0;
    return Math.round(availability.progress * 100);
  }

  getItemLastSubmit(availability: ItemAvailability) {
    return availability.lastSubmit;
  }

  listAvailableStages(path: string): Chapter[] {
    const setChapters = this.chaptersCache.get(path);
    if (!setChapters) return [];
    const res = setChapters.filter(
      chapter => {
        const av = this.innerAvailabilities.get(chapter.path);
        if (!av) return false;
        return av.available || av.assigned;
      }
    );
    return res;
  }

  getItemName(item: DefinitionBase) {
    return item.name;
  }

  getItemShortName(item: DefinitionBase) {
    return item.shortName;
  }

  getItemByPath(path: string) {
    return this.innerAvailabilities.get(path);
  }

  getStatusClass(path: string) {
    const availability = this.innerAvailabilities.get(path);

    if (!availability) {
      return 'locked';
    }
    if (!availability.assigned || !availability.available) {
      return 'locked';
    }
    if (availability.score === 0) {
      return 'ready';
    } else if (availability.score < 0.5) {
      return 'bad';
    } else if (availability.score < 0.95) {
      return 'enough';
    } else {
      return 'perfect';
    }
  }

  buildPath(...items: DefinitionBase[]) {
    return items.map( defItem => defItem.path).join("_");
}

  private loadSetSessionsPage(path: string): Observable<Page<ExerciseSession>> {
    return this.cspa.findSessions(this.schoolId, this.student.person.id, `${path}_`, Pageable.of(0,5,["finishDate,DESC"])).pipe(
      tap<Page<ExerciseSession>>( setSessions => this.setSessions.set(path, setSessions) )
    );
  }

  countSetSessions(availability: ItemAvailability) {
    if (!availability) return 0;
    const setSessionsPage = this.setSessions.get(availability.path);
    if (!setSessionsPage) return 0;
    return setSessionsPage.totalElements;
  }

  getSetSessions(setAvailability: ItemAvailability): ExerciseSession[] {
    const setSessions = this.setSessions.get(setAvailability.path);
    if (!setSessions) return [];
    return setSessions.content;
  }

  getChapterHeader(setAvailability: ItemAvailability) {
    return this.getSetSessions(setAvailability)[0].chapterName.split(' ')[0]

  }

  getExerciseNameHeader(setAvailability: ItemAvailability) {
    return this.getSetSessions(setAvailability)[0].exerciseName.split(' ')[0]
  }

  getChapter(session: ExerciseSession) {
    return session.chapterName.split(' ')[1]

  }
  getSection(session: ExerciseSession) {
    return session.sectionName.split(',')[0]

  }
  getExerciseName(session: ExerciseSession) {
    return session.exerciseName.split(' ')[1]
  }

  getSessionDate(session: ExerciseSession) {
    return session.finishDate;

  }

  getSessionScore(session: ExerciseSession) {
    return Math.round(session.score * 100);
  }

  isExam(path: string) {
    return path.includes("exam")
  }
}
