import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {VerifiedEmailChangeRequest, VerifiedPasswordChangeRequest} from "../../model/rest/casa-apiv2.model";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-person-email-change',
    templateUrl: './person-email-change.component.html',
    styleUrls: ['./person-email-change.component.scss'],
    standalone: false
})
export class PersonEmailChangeComponent implements OnInit {

  @ViewChild('modal', {static: true})
  modal: ModalComponent
  model: VerifiedEmailChangeRequest = null;
  error: string = null;
  complete = false;
  inProgress = false;

  @Input()
  changeEmailCallback: (VerifiedEmailChangeRequest) => Observable<any>;

  constructor() { }

  ngOnInit(): void {
  }

  show() {
    this.model = new VerifiedEmailChangeRequest();
    this.complete = false;
    this.error = null;
    this.modal.show();
  }

  submitActivation() {
    this.error = null;
    this.complete = false;
    this.inProgress = true;
    this.changeEmailCallback(this.model).pipe(
      finalize( () => this.inProgress = false)
    ).subscribe(
      {
        next: _ => {
          this.complete = true;
          this.error = null;
          this.model = null;
        },
        error: err => {
          this.error = err.userMessage ? err.userMessage : err.message;
          this.complete = true;
        }
      }
    )
  }

}
