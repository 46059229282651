<div class="card h-100" *ngIf="isReady()">
  <div class="card-header">
    <app-icon name="briefcase" class="icon-primary icon-bg bg-gray"></app-icon>
    <div>
        <h6 class="card-title">Teachers</h6>
        <p class="card-subtitle small text-muted">You have {{getTeachersNumber()}} teacher(s) registered.</p>
      </div>
  </div>
  <div class="card-body">
    <div *ngIf="hasAnyStats()">
      <p class="xsmall">This quarter you have:</p>
      <ul class="list-unstyled separated-list">
        <li class="small" *ngIf="getNew()">{{getNew()}} new teacher(s)</li>
        <li class="small" *ngIf="getActivated()">{{getActivated()}} activated teacher(s)</li>
        <li class="small" *ngIf="getBlocked()">{{getBlocked()}} blocked teacher(s)</li>
        <li class="small" *ngIf="getUpdated()">{{getUpdated()}} updated teacher(s)</li>
        <li class="small" *ngIf="getDeleted()">{{getDeleted()}} deleted teacher(s)</li>
      </ul>
    </div>
  </div>
  <div class="card-footer text-end">
    <a class="btn btn-secondary btn-sm rounded-pill px-3 px-md-4 py-2 me-2" (click)="redirectTo('teachers')">Teachers’ details</a>
    <a class="btn btn-primary btn-sm rounded-pill px-3 px-md-4 py-2" (click)="redirectTo('teacher/new')">Add teacher</a>
  </div>
</div>
