import { Component, OnInit } from '@angular/core';
import {
  PersonDetails,
  Student,
  StudentPersonDetails,
  Teacher,
  TeacherProfileDetails
} from "../../model/rest/casa-apiv2.model";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {finalize, map, switchMap, tap} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
    selector: 'app-manager-teacher-casa-profile-page',
    templateUrl: './manager-teacher-casa-profile-page.component.html',
    styleUrls: ['./manager-teacher-casa-profile-page.component.scss'],
    standalone: false
})
export class ManagerTeacherCasaProfilePageComponent implements OnInit {

  private schoolId: number;
  private teacherId: number;
  profileUnderEdit: PersonDetails;
  busy = false;
  private teacher: Teacher;
  teacherProfileUnderEdit: TeacherProfileDetails;
  teacherBusy = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private casa: CasaRestApiV2
  ) {
    activatedRoute.parent.paramMap.pipe(
      map<ParamMap, [number, number]>(params => [Number(params.get("schoolId")), Number(params.get("teacherId"))]),
      tap<[number, number]>( ([schoolId, teacherId]) => this.storeIds(schoolId, teacherId)),
      switchMap<[number, number], Observable<Teacher>>( _ => this.loadTeacher(true, true)),
    ).subscribe(
    )
  }

  ngOnInit(): void {
  }

  private storeIds(schoolId: number, teacherId: number) {
    this.schoolId = schoolId;
    this.teacherId = teacherId;
  }

  private keepTeacher(teacher: Teacher,replaceDetails: boolean, replaceCourses: boolean) {
    if (this.teacher == null || (replaceDetails && replaceCourses)) {
      this.teacher = teacher;
    }
    if (replaceDetails) {
      this.teacher.person.details = teacher.person.details;
    }
    if (replaceCourses) {
      this.teacher.profile = teacher.profile;
    }
    this.profileUnderEdit = this.teacher.person.details;
    this.teacherProfileUnderEdit = this.teacher.profile;
    if (!this.teacherProfileUnderEdit) {
      this.teacherProfileUnderEdit = new TeacherProfileDetails();
    }
  }

  saveProfile(details: PersonDetails) {
    this.busy = true;

    this.casa.requestTeacherPersonDetailsUpdate(this.schoolId, this.teacherId, details, true).pipe(
      switchMap( _ => this.loadTeacher(true, false)),
      finalize( () => this.busy = false)
    ).subscribe(

    );
  }

  loadTeacher(replaceDetails: boolean, replaceCourses: boolean) {
    return this.casa.getTeacher(this.schoolId, this.teacherId).pipe(
      tap<Teacher>(teacher => this.keepTeacher(teacher, replaceDetails, replaceCourses))
    )
  }

  saveTeacherProfile(toSave: TeacherProfileDetails) {
    this.teacherBusy = true;
    this.casa.updateTeacherCourseDetails(this.schoolId, this.teacherId, toSave).pipe(
      switchMap( _ => this.loadTeacher(false, true)),
      finalize( () => this.teacherBusy = false)
    ).subscribe()
  }
}
