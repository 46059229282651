import {Component, OnInit} from '@angular/core';
import {AuthService, AuthServiceProvider, UserRole} from '../../services/col2/auth.service';
import {Router} from '@angular/router';
import {TokenBasedSServerProfile} from '../../model/auth/ServerProfile';
import {CallanOnlineRecognizeService} from "../../services/callan-online-recognize.service";

@Component({
    selector: 'app-root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.css'],
    standalone: false
})
/*
This is the root page responsible for routing the users
depending on their roles. If the user is not logged in it
shows the welcome content. For non captured user roles the page may show
optional navigation.
*/
export class RootComponent implements OnInit {

  constructor(
    private authService: AuthServiceProvider,
    private router: Router,
    private callanOnlineRecognize: CallanOnlineRecognizeService
    ) { }
  public serverProfile: TokenBasedSServerProfile;
  initialized = false;
  ngOnInit() {
    this.authService.get().subscribe(api => {
      this.initialized = true;
      this.routeToUserPage(api);
    });
  }
  public logout(authApi: AuthService) {
    authApi.logout();
  }
  routeToUserPage(authApi: AuthService) {
    if (authApi.isTokenValid()) {

      authApi.getUserRole().subscribe(
        userRole => {
          if (userRole === UserRole.Unknown || userRole === UserRole.Unauthorized){
            this.logout(authApi);
          } else if (userRole === UserRole.Manager) {
            if (this.callanOnlineRecognize.isCallanOnlineFranchiseById(authApi.getSchoolId())) {
              this.router.navigate(['school', authApi.getSchoolId(), 'students']);
            } else {
              this.router.navigate( ['school', authApi.getSchoolId(), 'dashboard']);
            }
          } else if (userRole === UserRole.Student) {
            this.router.navigate(['sa', 'school', authApi.getSchoolId(), 'student', authApi.getStudentId()]);
          } else if (userRole === UserRole.Teacher) {
            this.router.navigate(['ta', 'school', authApi.getSchoolId(), 'teacher', authApi.getTeacherId()]);
          } else if (userRole === UserRole.Admin) {
            this.router.navigate(['admin']);
          } else {
            this.router.navigate(['forbidden']);
          }
        }
      )
    }
  }

}
