import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ComponentEvent, Page, Pageable, StateAwareComponent} from 'src/app/model/rest/base';
import {combineLatest, Subject} from 'rxjs';
import {ApiLearningUnitTeacher, ApiLessonInstance, ApiPerson, ApiPersonalProfile} from 'src/app/model/rest/rest-model';
import {filter, switchMap, tap} from 'rxjs/operators';
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {LessonTypeColors} from "../../services/utils/utils";
import {PersonNameExtractor, ProfilePhotoUrlExtractor} from "../../services/utils/profile-photo-url-extractor";
import {LocalStateService} from "../../services/local-state.service";

export enum LessonType {
  Upcoming, Incomplete, Past
}

export class TeacherLessonsComponentEvent extends ComponentEvent {

}

@Component({
    selector: 'app-teacher-lessons',
    templateUrl: './teacher-lessons.component.html',
    styleUrls: ['./teacher-lessons.component.css'],
    standalone: false
})
export class TeacherLessonsComponent extends StateAwareComponent<TeacherLessonsComponentEvent> implements OnInit {
  _teacherId: number;
  _lessonType: LessonType;
  _pageable: Pageable;
  hasNext = false;
  _data: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>;

  _schoolId: number;
  private contextPath: string;

  @Output()
  dataLoaded = new EventEmitter<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>();
  @Output()
  currentPageable = new EventEmitter<Pageable>()

  constructor(
    private managerRest: ManagerRestService,
    private localState: LocalStateService
  ) {
    super();

    combineLatest(
      this.schoolIdSubject,
      this.teacherIdSubject,
      this.lessonTypeSubject
    ).pipe(
      filter<[number, number, LessonType]>(([schoolId, teacherId, lessonType]) => schoolId != null && teacherId != null && lessonType != null),
      tap(_ => this.clearData()),
      tap( _ => this.prepareContextPath()),
      switchMap( _ => this.loadCurrentState()),
      switchMap( pageable => this.loadPage(pageable))
    ).subscribe();
  }

  private loadCurrentState() {
    return this.localState.get<Pageable>(this.contextPath, () => {
      const order = this._lessonType === LessonType.Past
        ? ['lesson.teacher.metricDetails.plannedStartDate,DESC']
        : ['lesson.teacher.metricDetails.plannedStartDate,ASC'];
      return Pageable.of(0, 10, order);
    }).pipe(
      tap( pageable => this.pageable = pageable)
    );

  }

  private prepareContextPath() {
    this.contextPath = `/school/${this._schoolId}/teachers/${this._teacherId}/lessons/${this._lessonType}`;
  }

  //input subjects
  private teacherIdSubject = new Subject<number>();
  private lessonTypeSubject = new Subject<LessonType>();
  private schoolIdSubject = new Subject<number>();

  set pageable(value: Pageable) {
    this._pageable = value;
    this.currentPageable.emit(value);
  }

  get pageable() {
    return this._pageable;
  }

  set data(value: Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>){
    this._data = value;
    this.dataLoaded.emit(value);
  }

  get data() {
    return this._data;
  }

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.teacherIdSubject.next(teacherId);
  }

  get teacherId(): number {
    return this._teacherId;
  }

  @Input()
  set lessonType(lessonType: LessonType) {
    this._lessonType = lessonType;
    this.lessonTypeSubject.next(lessonType);
  }

  @Input()
  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.schoolIdSubject.next(schoolId);
  }

  @Output()
  lessonDetailsEvent = new EventEmitter<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>();

  isCancelable(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return lesson.lessonStatus === 'Booked'
    && new Date(lesson.lessonMetric.plannedStartDate).getTime() - 1000 * 60 * 15 > new Date().getTime();
  }

  public hasResults() {
    return this.data != null;
  }

  public mayViewLesson(_: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return true;
  }

  public lessonBorderClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getTeacherLessonColorBorderClass(lesson);
  }

  get lessonType(): LessonType {
    return this._lessonType;
  }


  getPersonProfilePhoto(person: ApiPerson<ApiPersonalProfile>) {
    return ProfilePhotoUrlExtractor.getPersonProfilePhoto(person);
  }

  getLessonDate(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): Date {
    if (this._lessonType === LessonType.Upcoming ) {
      return lesson.lessonMetric.plannedStartDate;
    } else  {
      return lesson.lessonMetric.plannedStartDate;
    }
  }

  public getStudentNames(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>): string {
    return lesson.students
      .map(s => s.person )
      .map(p => PersonNameExtractor.getPersonName(p))
      .join(', ');
  }

  clearData(): any {
    this.pageable = null;
  }

  ngOnInit() {
    this.eventLoading();
  }

  private loadPage(pageable: Pageable) {
    this.pageable = pageable;
    return this.localState.set<Pageable>(this.contextPath, pageable).pipe(
      switchMap( pageable => this.callForLessons(pageable)),
      tap<Page<ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>>>( resultPage => this.data = resultPage)
    )
  }

  private callForLessons(pageable: Pageable) {
    if (this._lessonType === LessonType.Upcoming ) {
      return this.managerRest.listTeacherUpcomingLessons(this._schoolId, this._teacherId, this.pageable);
    } else if (this._lessonType === LessonType.Past) {
      return this.managerRest.listTeacherPastLessons(this._schoolId, this._teacherId, this.pageable);
    } else if (this._lessonType === LessonType.Incomplete) {
      return this.managerRest.listTeacherIncompleteLessons(this._schoolId, this._teacherId, this.pageable);
    }
  }

  switchPage(pageable: Pageable) {
    this.loadPage(pageable).subscribe();
  }
  public lessonBadgeClass(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    if (this._lessonType === undefined) {
      return '';
    }
    return LessonTypeColors.getStudentLessonBorderColorClass(lesson);
  }

  public getInitials(lesson: ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>) {
    return PersonNameExtractor.getInitials(lesson.students[0].person)
  }
}
