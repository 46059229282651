import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router, RouterEvent, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { ThemeService } from 'src/app/services/theme.service';
import * as Sentry from "@sentry/angular";

@Component({
    selector: 'admin-main-nav',
    templateUrl: './admin-main-nav.component.html',
    styleUrls: ['./admin-main-nav.component.scss'],
    standalone: false
})
export class AdminMainNavComponent implements OnInit, AfterViewInit {
  private activeSegments: string[];
  private activePath: string;
  @Input() isOffcanvas: boolean = false;

  disableVideo = true;
  theme: string;

  constructor(
    private authService: AuthServiceProvider,
    public themeService: ThemeService,
    private router: Router
  ) {
    themeService.getTheme().subscribe((theme) => (this.theme = theme));
    router.events
      .pipe(filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
                                  e instanceof NavigationEnd || 
                                  e instanceof NavigationCancel || 
                                  e instanceof NavigationError))
      .subscribe((e: RouterEvent) => {
        this.activePath = e['urlAfterRedirects'];
        this.activePath
          ? (this.activeSegments = this.activePath.split('/'))
          : '';
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const feedback = Sentry.feedbackIntegration();
    const buttons = document.querySelectorAll("#reportABugButton")
    
    if (buttons.length && !Array.from(buttons).every(button => button.hasAttribute("data-feedback-attached"))) {
      buttons.forEach(button => {
        feedback.attachTo(button)
        button.setAttribute("data-feedback-attached", "true")
      })
    }
  }

  logout() {
    this.authService.get().subscribe((api) => api.logout());
  }

  getDismiss() {
    return this.isOffcanvas && 'offcanvas';
  }

  isActive(segments: string) {
    return this.activeSegments[1] === 'admin' &&
      segments
        .split(';')
        .some((segment) => this.activeSegments[2]?.includes(segment))
      ? true
      : false;
  }
}
