import { Component, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, map, switchMap, take, tap} from "rxjs/operators";
import {LocalState, ManagerUtils, SortingRule} from "../../services/utils/manager-utils";
import {Page, Pageable} from "../../model/rest/base";
import {LearningUnitTeacherStatus, School, Teacher, VerifiedEmailChangeRequest, VerifiedPasswordChangeRequest} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {LocalStateService} from "../../services/local-state.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {ConfirmDialogService} from "../../services/confirm-dialog.service";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";
import { PersonEmailChangeComponent } from 'src/app/components/person-email-change/person-email-change.component';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';
import { PersonPasswordChangeComponent } from 'src/app/components/person-password-change/person-password-change.component';

class TeachersSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[], public status: string[]) {
    super(sorting);
  }
  static Natural = new TeachersSortingRule("All",null, null);
  static NameAsc = new TeachersSortingRule("All (Ascending)", ["person.surname,ASC"], null);
  static NameDesc = new TeachersSortingRule("All (Descending)", ["person.surname,DESC"], null);
  static values = [TeachersSortingRule.Natural, TeachersSortingRule.NameAsc, TeachersSortingRule.NameDesc];
}


@Component({
    selector: 'app-manager-teachers-list-page',
    templateUrl: './manager-teachers-list-page.component.html',
    styleUrls: ['./manager-teachers-list-page.component.css'],
    standalone: false
})
export class ManagerTeachersListPageComponent implements OnInit {
  schoolId: number;
  currentState: LocalState<TeachersSortingRule>;
  private teachersPage: Page<Teacher>;
  private school: School;
  sortingRules = TeachersSortingRule.values;
  private teachersStateUrl = "dashboard/teachers";
  private searchTermUpdates = new Subject<string>();
  private readonly searchTermUpdatesSubscription: Subscription;

  @ViewChild('emailModal', {static: true})
  emailModal: PersonEmailChangeComponent;
  @ViewChild('passwordModal', {static: true})
  passwordModal: PersonPasswordChangeComponent;

  constructor(
    route: ActivatedRoute,
    private casa: CasaRestApiV2,
    private localState: LocalStateService,
    private router: Router,
    private confirmationService: ConfirmDialogService,
    private toastNotificationService: ToastNotificationsService,
    public functionalAccessService: FunctionalAccessService
  ) {

    forkJoin([
      this.loadSchoolId(route).pipe(
        switchMap( _ => this.loadSchool())
      ),
      this.findLocalState(),
      this.getTermQueryParam(route)
    ]).pipe(
      switchMap( ([_, state, term]) => {
        if (term) {
          state.term = term
        }
        return this.loadTeachersPage(state);
      })
    ).subscribe();

    this.searchTermUpdatesSubscription = this.searchTermUpdates.pipe(
      debounceTime(400)
    ).subscribe( _ => this.doSearch());
  }

  ngOnDestroy() {
    if (this.searchTermUpdatesSubscription) {
      this.searchTermUpdatesSubscription.unsubscribe();
    }
  }

  ngOnInit() {
  }

  private getTermQueryParam(route: ActivatedRoute) {
    return route.queryParamMap.pipe(
      take(1),
      map( paramMap => paramMap.get('term'))
    );
  }

  private findLocalState() {
    return this.localState.get<LocalState<TeachersSortingRule>>(
      this.teachersStateUrl,
      () => new LocalState("", TeachersSortingRule.NameAsc ,Pageable.of(0, 50, null)));
  }

  private loadSchoolId(route: ActivatedRoute) {
    return route.parent.paramMap.pipe(
      take(1),
      map( params => Number(params.get('schoolId'))),
      tap<number>( schoolId => this.schoolId = schoolId));
  }

  set searchTerm(value: string) {
    if (this.currentState) this.currentState.term = value;
    this.searchTermUpdates.next(value);
  }

  get searchTerm(): string {
    if (this.currentState) return this.currentState.term;
    return '';
  }

  private loadSchool() {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.storeSchool(school))
    );
  }

  private storeSchool(school: School) {
    this.school = school;
  }

  private loadTeachersPage(state: LocalState<TeachersSortingRule>) {
    this.currentState = state;
    return this.localState.set(this.teachersStateUrl, state).pipe(
      switchMap( state => this.casa.listTeachers(this.schoolId, state.term, state.page)),
      tap( studentsPage => this.storeTeachersPage(studentsPage))
    )
  }

  private storeTeachersPage(studentsPage: Page<Teacher>) {
    this.teachersPage = studentsPage;
  }

  isSchoolReady() {
    return this.school != null;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  countTotal() {
    if (!this.teachersPage) return 0;
    return this.teachersPage.totalElements;
  }

  doSearch() {
    this.loadTeachersPage(this.currentState.first()).subscribe();
  }

  searchUpdated() {
    this.currentState.applySorting();
  }

  hasPages() {
    return this.hasPrev() || this.hasNext();
  }

  hasPrev() {
    return this.teachersPage && !this.teachersPage.first;
  }

  getPageNumber() {
    return this.teachersPage.number + 1;
  }

  hasNext() {
    return this.teachersPage && !this.teachersPage.last;
  }

  hasTeachers() {
    return this.teachersPage != null;
  }

  getTeachersPage() {
    return this.teachersPage.content;
  }

  prev() {
    this.loadTeachersPage(this.currentState.prev()).subscribe();
  }

  next() {
    this.loadTeachersPage(this.currentState.next()).subscribe();
  }

  open(teacher: Teacher) {
    if(this.schoolId === 1)
      this.router.navigate(['school', this.schoolId, 'teacher', teacher.id, 'lessons']);
    else
      this.router.navigate(['school', this.schoolId, 'teacher', teacher.id, 'lessons-list']);
  }

  getName(teacher: Teacher) {
    return ManagerUtils.getName(teacher.person);
  }

  getMail(teacher: Teacher) {
    return teacher.person.registrationEmail;
  }

  getStatus(teacher: Teacher) {
    return teacher.status;
  }

  getInitials(teacher: Teacher) {
    return ManagerUtils.getInitials(teacher.person)
  }

  delete(teacher: Teacher) {
    this.confirmationService.doOnConfirm("Are you sure you want delete the teacher?",
      () => {
        this.casa.deleteTeacher(this.schoolId, teacher.id).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher deletion",`Teacher ${this.getName(teacher)} has been deleted.`))),
          switchMap( _ => this.loadTeachersPage(this.currentState))
        ).subscribe();
      },
      "Teacher deletion",
      "bg-danger text-white"
    );
  }

  block(teacher: Teacher) {
    this.confirmationService.doOnConfirm("Are you sure you want to block the teacher?",
      () => {
        this.casa.updateTeacherStatus(this.schoolId, teacher.id, LearningUnitTeacherStatus.BLOCKED).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher blocking",`Teacher ${this.getName(teacher)} has been blocked.`))),
          switchMap( _ => this.loadTeachersPage(this.currentState))
        ).subscribe();
      },"Teacher blocking", "bg-warning");
  }

  activate(teacher: Teacher) {
    this.confirmationService.doOnConfirm("Are you sure you want to activate the teacher?",
      () => {
        this.casa.updateTeacherStatus(this.schoolId, teacher.id, LearningUnitTeacherStatus.ACTIVE).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher activation",`Teacher ${this.getName(teacher)} has been activated.`))),
          switchMap( _ => this.loadTeachersPage(this.currentState))
        ).subscribe();
      },"Teacher activation", "bg-warning");

  }

  isActive(teacher: Teacher) {
    return teacher && teacher.status === LearningUnitTeacherStatus.ACTIVE;
  }

  canActivate(teacher: Teacher) {
    return teacher && teacher.status === LearningUnitTeacherStatus.BLOCKED;
  }

  changePasswordCallback = (request : VerifiedPasswordChangeRequest) =>
    this.casa.changeTeacherPassword(this.schoolId, null, request)

  changeEmailCallback = (request: VerifiedEmailChangeRequest) =>
    this.casa.changeTeacherEmail(this.schoolId, null, request)
  changePassword(teacher: Teacher) {
    this.changePasswordCallback = (request : VerifiedPasswordChangeRequest) =>
    this.casa.changeTeacherPassword(this.schoolId, teacher.id, request)
    this.passwordModal.show();
  }

  changeEmail(teacher: Teacher) {
    this.changeEmailCallback = (request: VerifiedEmailChangeRequest) =>
      this.casa.changeTeacherEmail(this.schoolId, teacher.id, request)
    this.emailModal.show();
  }

  reloadTeacher(teacher: Teacher){
    this.casa.syncTeacher(this.schoolId, teacher.id).subscribe(
      _ => this.toastNotificationService.display(
        new ToastNotification("Refreshing of teacher's eBooks & Exercises", `Teachers' ${this.getName(teacher)} eBooks & Exercises has been refreshed successfully. Teacher should be able to access all content.`, "bg-success text-white"))
    )
  }

  resetBookShares(teacher: Teacher){
    this.confirmationService.doOnConfirm("Are you sure you to reset teacher eBooks download limit? This will remove the eBooks from all teacher's devices, so that they can download the eBooks to new devices.",
    () => {
      this.casa.clearTeacherDeviceShares(this.schoolId, teacher.id).subscribe(
        _ => this.toastNotificationService.display(
          new ToastNotification("Reset of teacher's eBooks download limit", `Teachers' ${this.getName(teacher)} eBooks download limit reset successfully.`)
        )
      )
    }, "Reset of teacher's eBooks download limit","bg-warning");
  }
}
