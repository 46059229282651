<div class="flex-column d-flex position-fixed" [ngClass]="isOnTop ? 'zindex-toast' : ''" style="pointer-events: none;right: 2rem; bottom: 2rem;">
  <div class="toast fade show" *ngFor="let notification of messages" [ngClass]="notification.toastClasses">
    <div class="toast-header" *ngIf="notification.title">
      <strong class="me-auto">{{notification.title}}</strong>
    </div>
    <div class="toast-body">
      {{notification.message}}
    </div>
    <div *ngIf="notification.isReportButtonShown" class="d-flex justify-content-end mb-3 me-3">
      <button class="btn btn-primary rounded-pill small" (click)="reportAnError(notification)">Report an error</button>
    </div>
  </div>
</div>
