import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map, switchMap, tap } from "rxjs/operators";
import { CallanOnlineRecognizeService } from "src/app/services/callan-online-recognize.service";
import { AuthServiceProvider, UserRole } from "src/app/services/col2/auth.service";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {School} from "../../../model/rest/casa-apiv2.model";

@Component({
    selector: 'app-manager-menu',
    templateUrl: './manager-menu.component.html',
    styleUrls: ['./manager-menu.component.css'],
    standalone: false
})
export class ManagerMenuComponent implements OnInit {
  schoolId: string;
  school: School;
  activePath: string
  private role: string

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthServiceProvider,
    private callanOnlineRecognize: CallanOnlineRecognizeService,
    private casaRest: CasaRestApiV2
  ) {}

  ngOnInit() {
    this.activeRoute.children[0].paramMap.pipe(
      tap(params => this.schoolId = params.get('schoolId')),
      switchMap(_ => this.casaRest.getSchoolDetails(Number(this.schoolId))),
      tap(school => this.school = school)
    ).subscribe();
    this.authService.get().pipe(
      switchMap(api => api.getUserRole()),
      tap(role => this.role = role)
    ).subscribe()
  }

  isCallanOnline() {
    if (!this.schoolId) return false;
    return this.callanOnlineRecognize.isCallanOnlineFranchiseById(Number(this.schoolId));
  }

  logout() {
    this.authService.get().subscribe(api=>api.logout());
  }

  getName() {
    // return ManagerUtils.getName();

  }

  getRole() {
    return this.role === UserRole.Manager? 'Role School Admin' : ''
  }


  logOutFromManager() {
    this.authService.get().subscribe((auth) => auth.logOutFromManager());
  }

  isAdminLoggedInAsManager() {
    return this.authService
      .get()
      .pipe(map((auth) => auth.isLoggedInAsManager()));
  }
}
