import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiLearningUnitStudent, ApiTeacherProfile } from 'src/app/model/rest/rest-model';
import {map, switchMap, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {School} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";

@Component({
    selector: 'app-manager-dashboard',
    templateUrl: './manager-dashboard.component.html',
    styleUrls: ['./manager-dashboard.component.css'],
    standalone: false
})
export class ManagerDashboardComponent implements OnInit {
  schoolId: number;
  school: School;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private casaRest: CasaRestApiV2,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
    this.activeRoute.parent.paramMap
      .pipe(
        map(params => Number(params.get('schoolId'))),
        tap<number>(schoolId => this.schoolId = schoolId),
        switchMap( schoolId => this.loadSchool(schoolId)),
        tap<School>( school => this.school = school)
      )
      .subscribe( );
  }

  itemClicked(item: any) {
    if (item instanceof ApiLearningUnitStudent) {
      this.router.navigate(['school', this.schoolId, 'student', item.id, 'lessons']);
      return;
    } else if (item instanceof ApiTeacherProfile) {
      this.router.navigate(['school', this.schoolId, 'teacher', item.teacher.id, 'lessons']);
    }
  }

  private loadSchool(schoolId: number): Observable<School> {
    return this.casaRest.getSchoolDetails(schoolId)
  }

  getSchoolName() {
    if (!this.school) return null;
    return this.school.details.name;
  }


  redirectTo(path: string) {
    let parentUrl = this.router.url
        this.router.navigate(['../', ...path.split("/")], {relativeTo: this.route})
  }
}
