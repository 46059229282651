import {Component, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output} from '@angular/core';
import {
  ConflictResults,
  ScheduleCalendarDisplay,
  ScheduleSearchService,
  ScheduleTableDisplay,
  SearchQuery,
  SearchResults
} from "../../services/schedule-search.service";
import {ScheduleParticipantBase, ScheduleRowSimplified} from "../../model/schedule";
import {LocalStateService} from "../../services/local-state.service";
import {formatDate} from "@angular/common";
import {SchedulesListComponent} from "../schedules-list/schedules-list.component";
import {RoomTemplateBase, RoomTemplateDetailsBase} from "../../model/server";


export class MySearchQuery extends SearchQuery {
  static TYPE_TABLE = 0;
  static TYPE_CALENDAR = 1;
  static TYPE_PLAIN = 2;
  constructor(type, term) {
    super(type, term);
  }
  public viewType = 0;
  public sortBy: number = null;
}

@Component({
    selector: 'app-schedules-week-summary',
    templateUrl: './schedules-week-summary.component.html',
    styleUrls: ['./schedules-week-summary.component.css'],
    standalone: false
})
export class SchedulesWeekSummaryComponent implements OnInit {
  searchQuery = new MySearchQuery(SearchQuery.BY_ALL, "");
  probeSize = 5;

  @Input()
  set displayType(type: string) {
    if(type === "calendar") {
      this.showAsCalendar()
    }
    else if(type === "email") {
      this.showAsPlainText()
    }
    else if(type === "list") {
      this.showAsTable()
    }
  }

  @Input()
  schoolId: number;
  @Input()
  isScheduleLoading: boolean;
  @Input()
  hasScheduleQueryArgs: boolean;
  @Input()
  getWeekStartDate: number;
  @Input()
  getWeekEndDate: number;
  @Output() moveWeekOffsetEmitter = new EventEmitter<number>()
  @Output() moveToNowEmitter = new EventEmitter<null>()

  mDataSet: ScheduleRowSimplified[];
  searchResult: SearchResults;
  conflictedSchedules: Set<number> = new Set();
  toShow: ScheduleTableDisplay | ScheduleCalendarDisplay;
  conflicts: ConflictResults;

  @Input()
  set dataSet(dataSet: ScheduleRowSimplified[]) {
    this.mDataSet = dataSet;
    if (dataSet) this.mDataSet = this.searchService.sortEventsByDate(dataSet);
    this.conflicts = this.searchService.searchForConflicts(this.mDataSet);
    this.findConflictedEvents();
    this.prepareDataForDisplay();
  }

  getGroupsMatched() {
    if (!this.searchResult) return [];
    return this.searchResult.foundGroups;
  }

  first<T>(array: T[], len: number): T[] {
    return array.slice(0, Math.min(len, array.length));
  }

  countMore<T>(array: T[], len: number) {
    return Math.max(0, array.length - len);
  }

  constructor(
    private searchService: ScheduleSearchService,
    private localState: LocalStateService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    this.localState.get<MySearchQuery>("/time-table/search", () => this.searchQuery)
      .subscribe(q => {
        this.searchQuery = q;
        this.prepareDataForDisplay();
      });
  }
  ngAfterViewChecked() {
    this.getCalendarStartPosition()
  }

  doSearch() {
    this.localState.set("/time-table/search", this.searchQuery).subscribe(
      _ => this.prepareDataForDisplay()
    )
  }

  private prepareDataForDisplay() {
    if (!this.searchQuery || !this.mDataSet) return;
    this.searchResult = this.searchService.search(this.mDataSet, this.searchQuery);

    if (!this.searchQuery || this.searchQuery.viewType === MySearchQuery.TYPE_TABLE) {
      this.toShow = this.searchService.prepareDisplay(this.searchResult.results, this.searchQuery.sortBy);
    } else {
      this.toShow = this.searchService.prepareDisplayCalendar(this.searchResult.results);
    }
  }

  getGroupName(group: RoomTemplateBase<RoomTemplateDetailsBase>) {
    return group.details?.name;
  }

  getGroupDaySchedules(group: RoomTemplateBase<RoomTemplateDetailsBase>, dayNumber: number): ScheduleRowSimplified[] {
    if (this.toShow instanceof ScheduleTableDisplay) {
      return this.toShow.rowsByDayByGroupId.get(group.id).get(dayNumber);
    }
    return [];
  }

  getBadgeColorClassByStatus(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'bg-danger';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'bg-success';
      case 'PLANED': return 'bg-warning';
      case 'PENDING': return 'bg-info';
    }
    return '';
  }

  getTooltipContent(row: ScheduleRowSimplified) {
    if (this.conflictedSchedules.has(row.schedule.id)) {
      return 'Conflict detected';
    }

    switch(row.schedule.state) {
      case 'COMPLETE': return 'Complete';
      case 'PLANED': return 'Planned';
      case 'PENDING': return 'Links sent';
    }
    return '';
  }

  getEndDate(row: ScheduleRowSimplified) {
    if (!row || !row.schedule || !row.schedule.details.durationMin) return 0;
    return row.schedule.details.startDate + row.schedule.details.durationMin * 60 * 1000;
  }

  getPlace(row: ScheduleRowSimplified) {
    if (!row || !row.schedule) return null;
    if (row.schedule.details.place) return row.schedule.details.place;
    return row.template.details.place;
  }

  hasAnyMatch() {
    return this.getGroupsMatched().length > 0
      || this.getTeachersMatched().length > 0
      || this.getStudentsMatched().length > 0
      || this.getClassesMatched().length > 0;
  }

  getTeachersMatched() {
    if (!this.searchResult) return [];
    return this.searchResult.foundTeachers;
  }

  getPersonName(participant: ScheduleParticipantBase) {
    return participant.name ? participant.name.trim() : "";
  }
  getPersonEmail(participant: ScheduleParticipantBase) {
    return participant.email ? `${participant.email.trim()}` : "";
  }

  getStudentsMatched() {
    if (!this.searchResult) return [];
    return this.searchResult.foundStudents;
  }

  getClassesMatched() {
    if (!this.searchResult) return [];
    return this.searchResult.foundClasses;
  }

  searchForClass(className: string) {
    this.searchQuery.searchType = SearchQuery.BY_CLASS;
    this.searchQuery.queryTerm = className;
    this.doSearch();
  }

  searchForStudent(student: ScheduleParticipantBase) {
    this.searchQuery.searchType = SearchQuery.BY_STUDENT;
    this.searchQuery.queryTerm = this.searchService.hashParticipant(student);
    this.doSearch();
  }

  searchTeacherGroup(teacher: ScheduleParticipantBase) {
    this.searchQuery.searchType = SearchQuery.BY_TEACHER;
    this.searchQuery.queryTerm = this.searchService.hashParticipant(teacher);
    this.doSearch();
  }

  searchForGroup(group: RoomTemplateBase<RoomTemplateDetailsBase>) {
    this.searchQuery.searchType = SearchQuery.BY_GROUP;
    this.searchQuery.queryTerm = this.searchService.hashGroup(group);
    this.doSearch();

  }

  dataIsEmpty() {
    return !this.searchResult
      || !this.searchQuery
      || !this.toShow
      || this.searchResult.results.length === 0;
  }

  sort(dayDate: number) {
    this.searchQuery.sortBy = dayDate;
    this.doSearch();
  }

  hasConflicts():boolean {
    return this.conflicts && (
      this.conflicts.byTeacher.length > 0
      || this.conflicts.byClass.length > 0 );
  }

  searchTeacherMail(teacherMail: string) {
    this.searchQuery.queryTerm = teacherMail;
    this.searchQuery.searchType = SearchQuery.BY_TEACHER;
    this.doSearch();
  }

  searchClassroomName(room: string) {
    this.searchQuery.queryTerm = room;
    this.searchQuery.searchType = SearchQuery.BY_CLASS;
    this.doSearch();
  }

  private findConflictedEvents() {
    this.conflictedSchedules.clear();
    this.conflicts.byClass.concat(this.conflicts.byTeacher)
      .forEach( it => {
        this.conflictedSchedules.add(it.left.schedule.id);
        this.conflictedSchedules.add(it.right.schedule.id);
        }
      )
  }

  isTableDisplay() {
    return this.searchQuery.viewType === MySearchQuery.TYPE_TABLE;
  }

  showAsTable() {
    if (this.isTableDisplay()) return;
    this.searchQuery.viewType = MySearchQuery.TYPE_TABLE;
    this.doSearch();
  }

  showAsCalendar() {
    if (this.isCalendarDisplay()) return;
    this.searchQuery.viewType = MySearchQuery.TYPE_CALENDAR;
    this.doSearch();
  }

  isPlainTextDisplay() {
    return this.searchQuery.viewType === MySearchQuery.TYPE_PLAIN;
  }

  isCalendarDisplay() {
    return this.searchQuery.viewType === MySearchQuery.TYPE_CALENDAR;
  }

  plainTextDisplayAvailable() {
    return this.searchResult
      && this.searchResult.foundClasses.length === 0
      && this.searchResult.foundStudents.length === 0
      && this.searchResult.foundGroups.length === 0
      && this.searchResult.foundTeachers.length === 1
      && this.searchResult.foundTeachers[0].email
      && this.searchResult.foundTeachers[0].email.trim().length > 4;
  }

  showAsPlainText() {
    if (this.isPlainTextDisplay()) return;
    this.searchQuery.viewType = MySearchQuery.TYPE_PLAIN;
    this.doSearch();
  }

  preparePlainViewEmailLink() {
    const subject = "Lesson Schedule Update";
    const studentEmail = this.searchResult.foundTeachers[0].email;
    let message = "\n\n";
    for (const day of this.toShow.daysSorted) {
      const dayFormatted = formatDate(day, "dd MMM, EEEE", this.locale);
      message += `\n${dayFormatted}\n------------------------\n`;

      const daySchedules = (this.toShow as ScheduleCalendarDisplay).rowsByDay.get(day);
      for (const schedule of daySchedules) {
        const eventStart = formatDate(schedule.schedule.details.startDate, "HH:mm", this.locale);
        const eventFinish = schedule.schedule.details.durationMin ? formatDate(schedule.schedule.details.startDate + schedule.schedule.details.durationMin * 60000, "HH:mm", this.locale) : null;
        let place = schedule.schedule.details.place;
        if (!place || place.trim().length === 0) {
          place = schedule.template.details.place;
        }
        if (place) place = place.trim();
        if (place && place.length > 0) place = `[${place}]`;
        else place = null;

        let eventDescription = eventStart;
        if (eventFinish) eventDescription += ` - ${eventFinish}`;
        if (place) eventDescription += ` ${place}`;
        eventDescription += ` - ${schedule.template.details?.name}`;
        if (this.isOffline(schedule)) eventDescription += ' [regular in class]'
        message += `${eventDescription}\n`;
      }
    }
    const subjectEncoded = encodeURIComponent(subject);
    const messageEncoded = encodeURIComponent(message);
    return `mailto:${studentEmail}?subject=${subjectEncoded}&body=${messageEncoded}`;
  }


  getCalendarDisplayViewDay(day: number): ScheduleRowSimplified[] {
    const rowsByDay = (this.toShow as ScheduleCalendarDisplay).rowsByDay;
    if (!rowsByDay) return [];
    const rows = rowsByDay.get(day);
    if (!rows) return [];
    return rows;
  }

  getTableViewGroupsSorted() {
    return (this.toShow as ScheduleTableDisplay).groupsSorted;
  }

  isOffline(row: ScheduleRowSimplified) {
    return SchedulesListComponent.isOffline(row);
  }

  hasAnyOffline(schedule: ScheduleRowSimplified) {
    return (this.isOffline(schedule) || schedule.schedule.details.participants.find( p => p.offline));
  }

  moveScheduleToNow() {
    this.moveToNowEmitter.emit(null)
  }
  moveScheduleWeekOffset(number: number) {
    this.moveWeekOffsetEmitter.emit(number)
  }

  getCalendarStartPosition() {
    let schedules = document.body.getElementsByClassName("bg-calendar")
    if(schedules.length) {
      let schedule = (schedules[0] as HTMLElement)
      let tableHeader = document.getElementById("table-header")

      document.getElementById("calendar")
        .scrollTo({
          left: 0,
          top: (schedule.offsetParent as HTMLElement).offsetTop - tableHeader.clientHeight
        })
    }
  }

}
