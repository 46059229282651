import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GroupParticipant, GroupStudent, GroupTeacher} from "../../../model/group";
import {LearningUnitTeacherStatus, Student, Teacher} from "../../../model/rest/casa-apiv2.model";

@Component({
    selector: 'app-group-participant-list',
    templateUrl: './group-participant-list.component.html',
    styleUrls: ['./group-participant-list.component.scss'],
    standalone: false
})
export class GroupParticipantListComponent implements OnInit {

  @Input() participants: GroupTeacher<Teacher>[] | GroupStudent<Student>[]
  @Input() isEdited = false
  @Output() editPartiEvent = new EventEmitter<GroupTeacher<Teacher> | GroupStudent<Student>>()
  @Output() deletePartiEvent = new EventEmitter<GroupTeacher<Teacher> | GroupStudent<Student>>()
  constructor() { }

  ngOnInit(): void {
  }

  getName(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    return `${parti.details.participant.person.details.name} ${parti.details.participant.person.details.surname}`
  }
  getStatus(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    return parti.details.participant.status
  }
  getStatusBadgeClass(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    switch(parti.details.participant.status) {
      case LearningUnitTeacherStatus.ACTIVE: return 'bg-primary';
      case LearningUnitTeacherStatus.BLOCKED: return 'bg-secondary';
      case LearningUnitTeacherStatus.REMOVED: return 'bg-danger';
      default: return 'bg-primary'
    }

  }
  getEmail(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    return parti.details.participant.person.registrationEmail
  }
  getDateFrom(parti: GroupParticipant<any>) {
    return parti.details.from ? new Date(parti.details.from).toLocaleDateString() : '-'
  }
  getDateTo(parti: GroupParticipant<any>) {
    return parti.details.to ? new Date(parti.details.to).toLocaleDateString() : '-'
  }
  getLabels(parti: GroupParticipant<any>) {
    return Array.from(parti.details.labels.values()).join(', ')  || '-'
  }

  editParticipant(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    this.editPartiEvent.emit(parti)
  }
  deleteParticipant(parti: GroupTeacher<Teacher> | GroupStudent<Student>) {
    this.deletePartiEvent.emit(parti)
  }
}
