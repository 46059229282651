import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of, ReplaySubject} from 'rxjs';
import {AuthResponse} from '../../model/auth/AuthResponse';
import {TokenBasedSServerProfile} from '../../model/auth/ServerProfile';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {PlatformLocation} from '@angular/common';
import { Router } from '@angular/router';

export enum UserRole {
  // user is unauthorized
  Unauthorized = "Unauthorized",
  Student = "Student",
  Teacher = "Teacher",
  Admin = "Admin",
  Manager = "Manager",
  // Role is unknown for the application or impossible to recognize, threat it as Unauthorized
  Unknown = "Unknown"
}


export interface AuthService {
  clear(): void;
  startLogin(stateUrl: string);
  authorizeCode(code): Observable<AuthResponse>;
  refreshToken(): Observable<AuthResponse>;
  getAccessToken(): string;
  getUserId(): string
  resolveState(state: string): string;
  isTokenValid(): boolean;
  logout(): void;
  startRegistration(stateUrl: string);
  getUserRole(): Observable<UserRole>;
  getSchoolId(): number;
  getStudentId(): number;
  getTeacherId(): number;
  getManagerId(): number
  hasSuperAdminRole(): boolean;
  getAuthorizationId(): number
  logInAsManager(schoolId: number, redirectPath: string): void
  logOutFromManager(): void
  isLoggedInAsManager(): boolean
  getAdminSchoolId(): number
}

@Injectable({
  providedIn: 'root'
})
export class AuthServiceProvider {
  constructor(private http: HttpClient,
              private platformLocation: PlatformLocation,
              private router: Router
  ){}

  private resultService: AuthServiceNew;
  get(): Observable<AuthService> {
    if (this.resultService) return of(this.resultService);

    this.resultService = new AuthServiceNew(this.http, this.platformLocation, this.router);
    this.resultService.initialize();
    return of(this.resultService);
  }
}

export class AuthServiceNew implements AuthService {
  private readonly accessTokenLocalStorageName = 'schools_accessToken';
  private readonly refreshTokenLocalStorageName = 'schools_refreshToken';
  private clientId = environment.casaClientId;
  private serverBase = environment.serverBase;
  private redirectUrl = `${this.serverBase}/oauth`;
  private callanAppUrl = environment.callanAppUrl;
  private oauthEndpoint = environment.authEndpoint;

  private accessTokenValue: string;
  private refreshTokenValue: string;
  private tokenClaims: any = null;


  private readonly adminAsManagerStorageName = 'aid';

  constructor(private http: HttpClient, private platformLocation: PlatformLocation, private router: Router) {
  }

  initialize() {
    this.accessTokenValue = localStorage.getItem(this.accessTokenLocalStorageName);
    this.refreshTokenValue = localStorage.getItem(this.refreshTokenLocalStorageName);
    if (!this.isTokenValid()) {
      this.clear();
      return;
    }

    this.extractTokenData();

  }
  authorizeCode(code): Observable<AuthResponse> {
    this.clear();
    return this.askForAccessToken(code)
      .pipe(
        tap(() => this.extractTokenData())
      );
  }

  clear(): void {
    localStorage.removeItem(this.accessTokenLocalStorageName);
    localStorage.removeItem(this.refreshTokenLocalStorageName);
    this.tokenClaims = null;
  }

  getAccessToken(): string {
    return this.accessTokenValue;
  }

  getUserId(): string {
    return this.tokenClaims?.userId
  }

  getSchoolId(): number {
    if(this.getAdminSchoolId()) {
      let schoolId = this.getAdminSchoolId()
      if (!schoolId) throw Error("no school provided");
      return schoolId
    } else {
      const subject: string[] = this.getSubject();
      if (subject[0] !== 'school') throw Error("no school provided");
      return Number(subject[1])
    }
  }

  getStudentId(): number {
    const subject: string[] = this.getSubject();
    try {
      if (subject[0] !== 'school' || subject[2] !== 'student') throw Error("is not student role");
      return Number(subject[3]);
    } catch (e) {
      if(!this.hasSuperAdminRole())
        throw e
    }
  }

  getTeacherId(): number {
    const subject: string[] = this.getSubject();
    try {
      if (subject[0] !== 'school' || subject[2] !== 'teacher') throw Error("is not teacher role");
      return Number(subject[3]);
    } catch (e) {
      if(!this.hasSuperAdminRole())
        throw e
    }
  }

  getManagerId(): number {
    const subject: string[] = this.getSubject();
    try {
      if (subject[0] !== 'school' || subject[2] !== 'manager') throw Error("is not manager role");
        return Number(subject[3]);
    } catch (e) {
      if(!this.hasSuperAdminRole())
        throw e
    }
  }

  getUserRole(): Observable<UserRole> {
    if (!this.isTokenValid()) return of(UserRole.Unauthorized);
    const subject = this.getSubject();
    if (subject[2] === 'student')  return of(UserRole.Student);
    if (subject[2] === 'teacher')  return of(UserRole.Teacher);
    if (subject[2] === 'manager')  return of(UserRole.Manager);
    if (subject[0] === 'admin')  return of(UserRole.Admin);
    return of(UserRole.Unknown);
  }

  isTokenValid(): boolean {
    const accessToken = AuthServiceNew.decomposeToken(this.accessTokenValue);
    if (!accessToken) {
      return false;
    }
    return accessToken.data.sub;
  }

  logout(): void {
    const currentUrl = `${this.prepareServerBase()}`;
    const backUrl = (this.callanAppUrl != null) ? encodeURIComponent(this.callanAppUrl) : encodeURIComponent(currentUrl)
    const logoutUrl = `${this.oauthEndpoint}/oauth/logout?redirect_uri=${backUrl}`;

    if(this.getAdminSchoolId()) {
      this.logOutFromManager()
    }

    this.clear();
    window.location.href = logoutUrl;
  }

  refreshToken(): Observable<AuthResponse> {
    const codeRequest = {
      refresh_token: this.refreshTokenValue,
      client_id: this.clientId,
      grant_type: "refresh_token"
    };

    const formData = new HttpParams({ fromObject: codeRequest}).toString()
    return this.http.post<AuthResponse>(
      `${this.oauthEndpoint}/oauth/v2/token`,
      formData,
      {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
      .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
  }

  resolveState(state: string): string {
    const paramsExpr = /\[:([^\]]+)\]/g;
    const path = state;
    let myArray: any = null;
    let result = '';
    let startIdx = 0;
    while ((myArray = paramsExpr.exec(path)) !== null) {
      result += path.substring(startIdx, myArray.index);
      if (myArray[1] === "studentId") result += this.getStudentId();
      else if (myArray[1] === "managerId") result += this.getManagerId();
      else if (myArray[1] === "teacherId") result += this.getTeacherId();
      startIdx = paramsExpr.lastIndex;
    }
    if (startIdx < state.length) {
      result += path.substring(startIdx);
    }
    return result;
  }

  startLogin(stateUrl: string) {
    window.location.href = this.oauthEndpoint
      + '/oauth/v2/authorize?' + this.constructOauth(stateUrl);
  }

  startRegistration(stateUrl: string) {
    const oauth = environment.authEndpoint
      + '/oauth/school/' + environment.callanonlineId + '/register?' + this.constructOauth(stateUrl);
    window.location.href = oauth;
  }

  private extractTokenData() {
    this.tokenClaims = JSON.parse(atob(this.accessTokenValue.split("\.")[1]));
  }

  private static decomposeToken(token: string): any {
    const res: any = {};
    if (!token) {
      return null;
    }
    const splitted = token.split('.');
    res.alg = JSON.parse(atob(splitted[0]));
    res.data = JSON.parse(atob(splitted[1]));
    return res;
  }

  private askForAccessToken(code: string): Observable<AuthResponse> {
    const codeRequest = {
      code: code,
      client_id: this.clientId,
      redirect_uri: this.redirectUrl,
      grant_type: "authorization_code"
    };

    const formData = new HttpParams({ fromObject: codeRequest}).toString()
    return this.http.post<AuthResponse>(
      `${this.oauthEndpoint}/oauth/v2/token`,
      formData,
      {headers : new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })})
      .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
  }

  private saveToken(resp: AuthResponse): void {
      this.accessTokenValue = resp.access_token;
      localStorage.setItem(this.accessTokenLocalStorageName, this.accessTokenValue);
      this.refreshTokenValue = resp.refresh_token;
      localStorage.setItem(this.refreshTokenLocalStorageName, this.refreshTokenValue);
  }

  private prepareServerBase() {
    let baseHref  = this.platformLocation.getBaseHrefFromDOM();
    baseHref = baseHref.substr(0, baseHref.length - 1);
    return environment.serverBase + baseHref;
  }

  private getSubject(): string[] {
    if(!this.tokenClaims) return null
    return this.tokenClaims["sub"].split("/");
  }

  private constructOauth(stateUrl: string) {
    const stateStr = encodeURIComponent(btoa(stateUrl));
    const redirectUrl = encodeURIComponent(this.redirectUrl);
    const role = encodeURIComponent("manager|admin");

    return `response_type=code&client_id=${this.clientId}&state=${stateStr}&redirect_uri=${redirectUrl}&role=${role}`;
  }

  hasSuperAdminRole(): boolean {
    if (this.tokenClaims == null || this.tokenClaims.profileInfo == null || this.tokenClaims.profileInfo.roles == null) {
      return false;
    }
    const tokenRoles: Array<String> = this.tokenClaims.profileInfo.roles;
    return tokenRoles.findIndex( role => role === 'ROLE_ADMIN') >= 0 && this.getSubject()[0] === 'admin'
  }

  getAuthorizationId(): number {
    if(!this.hasSuperAdminRole()) return
    return +this.tokenClaims["aid"] || null
  }

  logInAsManager(schoolId: number, redirectPath: string) {
    if (!schoolId) return;
    const adminAuthorizationId = this.getAuthorizationId();
      localStorage.setItem(
        this.adminAsManagerStorageName,
        `${adminAuthorizationId}/${schoolId.toString()}`
      );
      this.router.navigate([
        'school',
        schoolId,
        ...(redirectPath ? redirectPath : 'dashboard').split("/")
      ]);
  }

  isLoggedInAsManager() {
    const aid = +localStorage.getItem(this.adminAsManagerStorageName)?.split('/')[0]
    return aid === this.getAuthorizationId()
  }

  getAdminSchoolId() {
    return +localStorage.getItem(this.adminAsManagerStorageName)?.split('/')[1] || null;
  }

  logOutFromManager(): void {
    localStorage.removeItem(this.adminAsManagerStorageName);
    this.router.navigate(['admin', 'school']);
  }
}

/*
Authorization service. Responsible to process whole authorization and user data retrieve
*/
export class AuthServiceImpl implements AuthService {

  oauthEndpoint = environment.authEndpoint;
  redirectUrl = environment.serverBase + '/oauth';
  clientId = environment.casaClientId;

  private accessTokenValue: string;
  private refreshTokenValue: string;

  public serverProfileSubject = new ReplaySubject<TokenBasedSServerProfile>(1);
  private _serverProfile: TokenBasedSServerProfile;
  private initialized: boolean;
  public get serverProfile(): TokenBasedSServerProfile {
    return this._serverProfile;
  }

  constructor(private http: HttpClient,
  private platformLocation: PlatformLocation

  ) {}
  logOutFromManager(): void {
    throw new Error('Method not implemented.');
  }
  getAdminSchoolId(): number {
    throw new Error('Method not implemented.');
  }
  isLoggedInAsManager(): boolean {
    throw new Error('Method not implemented.');
  }
  logInAsManager(schoolId: number, redirectPath: string): void {
    throw new Error('Method not implemented.');
  }
  getAuthorizationId(): number {
    throw new Error('Method not implemented.');
  }
  getManagerId(): number {
    throw new Error('Method not implemented.');
  }

  /*
  initialized authorization system, can be invoked frequently,
  is not resource consuming
  */
  init(): Observable<void> {
    return new Observable<void>(observer => {
      if (this.initialized) {
        observer.next();
        observer.complete();
        return;
      }
      this.accessTokenValue = localStorage.getItem(this.accessTokenLocalStorageName);
      this.refreshTokenValue = localStorage.getItem(this.refreshTokenLocalStorageName);

      if (!this.isTokenValid()) {
        this.clear();
        this.initialized = true;
        observer.next();
        observer.complete();
        return;
      }
      this.loadAuthData().subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  /*
  load all possible authorization details into the class
  */
  private loadAuthData(): Observable<void> {
    return new Observable<void>(observer => {

      this.loadServerProfile().pipe(
        tap(() => this.initialized = true)
      ).subscribe(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  private saveToken(resp: AuthResponse): void {
    this.accessTokenValue = resp.access_token;
    localStorage.setItem(this.accessTokenLocalStorageName, this.accessTokenValue);
    this.refreshTokenValue = resp.refresh_token;
    localStorage.setItem(this.refreshTokenLocalStorageName, this.refreshTokenValue);
  }

  /*
  clear authorization resources
  */
  private readonly accessTokenLocalStorageName = 'schools_accessToken';
  private readonly refreshTokenLocalStorageName = 'schools_refreshToken';

  clear(): void {
    localStorage.removeItem(this.accessTokenLocalStorageName);
    localStorage.removeItem(this.refreshTokenLocalStorageName);
    this._serverProfile = null;
    this.initialized = false;
  }

  private prepareServerBase() {
    let baseHref  = this.platformLocation.getBaseHrefFromDOM();
    baseHref = baseHref.substr(0, baseHref.length - 1);
    return environment.serverBase + baseHref;
  }

  public logout(): void {
    const currentUrl = this.prepareServerBase() + '/logout';
    const logoutUrl = environment.authEndpoint + '/oauth/logout?redirect_uri=' + encodeURIComponent(currentUrl);

    this.clear();
    window.location.href = logoutUrl;
  }

  private decomposeToken(token: string): any {
    const res: any = {};
    if (!token) {
      return null;
    }
    const splitted = token.split('.');
    res.alg = JSON.parse(atob(splitted[0]));
    res.data = JSON.parse(atob(splitted[1]));
    return res;
  }

  public getAccessToken(): string {
    return this.accessTokenValue;
  }

  public getUserId(): string {
    return JSON.parse(atob(this.accessTokenValue.split("\.")[1])).userId;
  }

  public isTokenValid(): boolean {
    const accessToken = this.decomposeToken(this.accessTokenValue);
    if (!accessToken) {
      return false;
    }
    const expTime = accessToken.data.exp;
    const currentTime = new Date().getTime() / 1000;
    return currentTime <= expTime;

  }

  /*
  do the authorization with the given code and
  load authorization data
  */
  public authorizeCode(code): Observable<AuthResponse> {
    this.clear();
    return this.askForAccessToken(code)
    .pipe(
      switchMap( authResponse =>
        this.loadAuthData().pipe(
          map( _ => authResponse)
        )
      )
    );
  }
  public startRegistration(stateUrl: string) {
    const oauth = environment.authEndpoint
    + '/oauth/school/' + environment.callanonlineId + '/register?' + this.constructOauth(stateUrl);
    window.location.href = oauth;
  }

  private constructOauth(stateUrl: string) {
    const stateStr = encodeURIComponent(btoa(stateUrl));
    const clientId = environment.casaClientId;
    const redirectUrl = encodeURIComponent(environment.serverBase + '/oauth');

    return 'response_type=code&chooseProfile=1&client_id='
      + clientId
      + '&state=' + stateStr
      + '&redirect_uri=' + redirectUrl;
  }

  public startLogin(stateUrl: string) {
    window.location.href = environment.authEndpoint
        + '/oauth/school/' + environment.callanonlineId + '/login?' + this.constructOauth(stateUrl);
  }

  private askForAccessToken(code): Observable<AuthResponse> {
    const codeRequest = {
      code: code,
      client_id: this.clientId,
      redirect_uri: this.redirectUrl
      };

      return this.http.post<AuthResponse>(this.oauthEndpoint + '/oauth/token', codeRequest)
        .pipe(tap(tokenResponse => this.saveToken(tokenResponse)));
  }

  private loadServerProfile(): Observable<TokenBasedSServerProfile> {
    const parsed = JSON.parse(atob(this.accessTokenValue.split("\.")[1])).profileInfo;
    const prototyped = Object.setPrototypeOf(parsed, TokenBasedSServerProfile.prototype);
    if (prototyped.teacherId != null
      && prototyped.roles.indexOf("ROLE_SCHOOL_TEACHER") < 0
    )  {
      prototyped.roles.push("ROLE_SCHOOL_TEACHER")
    }

    this._serverProfile = prototyped;
    this.serverProfileSubject.next(prototyped);
    return of(prototyped);
  }

  public resolveState(state: string): string {
    const paramsExpr = /\[:([^\]]+)\]/g;
    const path = state;
    let myArray: any = null;
    let result = '';
    let startIdx = 0;
    while ((myArray = paramsExpr.exec(path)) !== null) {
      result += path.substring(startIdx, myArray.index);
      result += this._serverProfile[myArray[1]];
      startIdx = paramsExpr.lastIndex;
    }
    if (startIdx < state.length) {
      result += path.substring(startIdx);
    }
    return result;
  }

  refreshToken(): Observable<AuthResponse> {
    throw new Error("not supported");
  }

  getUserRole(): Observable<UserRole> {
    return this.serverProfileSubject.pipe(
      take<TokenBasedSServerProfile>(1),
      map<TokenBasedSServerProfile, UserRole>( profile => {
        if (profile.isSchoolAdmin()) return UserRole.Manager;
        else if (profile.isStudent()) return UserRole.Student;
        else if (profile.isTeacher()) return UserRole.Teacher;
        else return UserRole.Unknown;
      })
    );
  }

  getSchoolId(): number {
    return this.serverProfile.schoolId;
  }

  getStudentId(): number {
    return this.serverProfile.studentId;
  }

  getTeacherId(): number {
    return this.serverProfile.teacherId;
  }

  hasSuperAdminRole(): boolean {
    throw new Error("unsupported");
  }

}
