import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {switchMap, tap} from "rxjs/operators";
import {School, TeacherStats} from "../../../model/rest/casa-apiv2.model";
import {Page, Pageable} from "../../../model/rest/base";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";

@Component({
    selector: 'app-teachers-summary-block',
    templateUrl: './teachers-summary-block.component.html',
    styleUrls: ['./teachers-summary-block.component.css'],
    standalone: false
})
export class TeachersSummaryBlockComponent implements OnInit {
  mSchool: School;
  private teachersTotal: number;
  private ready = false;
  private stats: TeacherStats;

  @Input()
  set school(value: School) {
    this.mSchool = value;
    this.reload();
  }
  @Output() redirectEmitter: EventEmitter<string> = new EventEmitter();

  constructor(private casaApi: CasaRestApiV2) { }

  ngOnInit() {
  }

  private reload() {
    if (!this.mSchool) return;
    this.ready = false;
    this.loadTeachers(this.mSchool.id)
      .pipe(
        tap(page => this.store(page)),
        switchMap( _ => this.loadTeachersStats(this.mSchool.id)),
        tap( stats => this.storeStats(stats))
      )
      .subscribe();
  }

  private loadTeachersStats(schoolId: number) {
    return this.casaApi.countSchoolTeachersStats(schoolId);
  }

  private loadTeachers(schoolId: number) {
    return this.casaApi.listTeachers(schoolId, null, Pageable.of(0,1, null));
  }

  private store(page: Page<any>) {
    this.ready = true;
    this.teachersTotal = page.totalElements;
  }

  isReady() {
    return this.ready;
  }

  getTeachersNumber() {
    return this.teachersTotal;
  }

  private storeStats(stats: TeacherStats) {
    this.stats = stats;

  }

  hasAnyStats() {
    return this.stats &&
      (this.getNew() || this.getActivated() || this.getBlocked() || this.getUpdated() || this.getDeleted())
  }

  getNew() {
    return this.stats.new;
  }

  getActivated() {
    return this.stats.activations;
  }

  getBlocked() {
    return this.stats.deactivations;
  }

  getUpdated() {
    return this.stats.updates;
  }

  getDeleted() {
    return this.stats.deletions;
  }

  redirectTo(path: string) {
    this.redirectEmitter.emit(path);
  }
}
