import {Component, Input, OnInit} from '@angular/core';
import {TeacherBookRestService} from 'src/app/services/rest/teacher-book-rest.service';
import {combineLatest, from, Observable, of} from 'rxjs';
import {CalendarEntry, ClickEvent} from '../../calendar/week-calendar/week-calendar.component';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {
  ApiLearningUnitStudentBase,
  ApiLearningUnitTeacher,
  ApiLessonInstance,
  ApiPersonalProfile
} from 'src/app/model/rest/rest-model';
import {SimpleProductAvailability, SimpleScheduleEvents} from "../../../model/booking/booking-rest.model";
import {LangProductMapper} from "../../../services/utils/lang-mappers";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {Student} from "../../../model/rest/casa-apiv2.model";

@Component({
    selector: 'app-teacher-main-calendar',
    templateUrl: './teacher-main-calendar.component.html',
    styleUrls: ['./teacher-main-calendar.component.css'],
    standalone: false
})
export class TeacherMainCalendarComponent implements OnInit {

  _teacherId: number;
  _focusDate: Date;
  calendarEntries: CalendarEntry[] = [];

  studentById: {[ studentId: number]: Student} = {};

  loading = true;

  allProductsCode = '__all__';

  constructor(
    private bookingRest: TeacherBookRestService,
    private casaRest: CasaRestApiV2) { }

  @Input()
  schoolId: number;

  @Input()
  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadWeekEvents();
  }

  setTimeWithStr(date: Date, time: string) {
    const timeSplit = time.split(':');
    const hour = Number(timeSplit[0]);
    const minutes = Number(timeSplit[1]);

    const res = new Date(date);
    res.setHours(hour);
    res.setMinutes(minutes);

    return res;
  }

  getProductName(code: string): string {
    return LangProductMapper.mapLangCodeToLangName(code);
  }

  set focusDate(date: Date) {
    this._focusDate = date;
    this.loadWeekEvents();
  }

  calendarClicked(event: ClickEvent) {
  }

  loadWeekEvents() {
    if (!this._teacherId || !this._focusDate) { return; }
    this.loading = true;
    combineLatest([
      this.bookingRest.listWorktimeWeekSchedules(this._teacherId, this._focusDate)
      .pipe(
        map( worktimesArray => this.mapSimpleProductAvailabilityToCalendarEntry(worktimesArray))
        )
      ,
      this.bookingRest.listWeekSchedules(this._teacherId, this._focusDate)
      .pipe(
        map((schedulesArray) =>
          this.mapSimpleScheduleEventsToCalendarEntry(schedulesArray)
        ),
        tap((schedulesArray) => this.loadStudents(schedulesArray))
      )]
    ).pipe(
      map(([worktimeEntries , scheduleEntries]) => worktimeEntries.concat(scheduleEntries)),
      tap<CalendarEntry[]>(calendarEntries => this.calendarEntries = calendarEntries)
      )
    .subscribe( () => this.loading = false, () => this.loading = false);
  }

  loadStudents(schedulesArray: CalendarEntry[]): void {
    const studentIds = schedulesArray
      .map( schedule => (schedule.relatedObject as ApiLessonInstance<ApiPersonalProfile, ApiLearningUnitTeacher>))
      .map( lesson => lesson.students )
      .filter( studentsArray => studentsArray && studentsArray.length > 0 )
      .reduce( (src: Array<ApiLearningUnitStudentBase>, arg: Array<ApiLearningUnitStudentBase>) => src.concat(arg), [])
      .map( student => student.id );

    const studentsToDownolad = Array.from(new Set(studentIds))
      .filter( studentId => !this.studentById[studentId]);

    if (this.schoolId) {
      from(studentsToDownolad).pipe(
        switchMap<number, Observable<Student>>( studentId => this.casaRest.getStudent(this.schoolId, studentId).pipe(
          catchError( _ => of(null))
        )),
        filter( v => v != null),
        tap<Student>( student => this.studentById[student.id] = student)
      ).subscribe();
    }

    // skip loading - will be replaced
    // this.teacherRest.queryForStudentsById(this._teacherId, studentsToDownolad).pipe(
    //   tap( studentsPage => studentsPage.content.forEach( student => this.studentById[student.id] = student))
    // ).subscribe();
  }

  getAvailabilityName(av: SimpleProductAvailability) {
    return `${av.details.product.code}[${av.details.competences.map(c => c.code).join(", ")}]`
  }

  mapSimpleProductAvailabilityToCalendarEntry(schedules: SimpleProductAvailability[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => this.getAvailabilityName(schedule),
        null,
        schedule,
        'worktime'
      )
      if(schedules.length > 1)
        entry = entry.narrowIntersected()

      entries.push(entry);
    }))
    return entries
  }

  mapSimpleScheduleEventsToCalendarEntry(schedules: SimpleScheduleEvents[]): CalendarEntry[] {
    let entries = new Array<CalendarEntry>()
    schedules.forEach(schedule => schedule.events.forEach(ev => {
      const dateTo = new Date();
      dateTo.setTime(ev.eventDate.getTime() + ev.duration);
      let entry = new CalendarEntry(
        schedule.schedule.id,
        ev.eventId,
        ev.eventDate,
        dateTo,
        () => schedule.schedule.name,
        null,
        schedule,
        'schedule'
      )
      entries.push(entry);
    }))
    return entries
  }
  ngOnInit() {
  }

}
