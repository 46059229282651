import { Component, OnInit } from '@angular/core';
import { Page, Pageable } from 'src/app/model/rest/base';
import { Address, ContactEntry, PersonSearchResult, School, SchoolBase } from 'src/app/model/rest/casa-apiv2.model';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
    standalone: false
})
export class AdminDashboardComponent implements OnInit {
  schoolId: number;
  school: School;
  searchTerm: string;
  pageablePeople = new Pageable(0, 5, [])
  pageableSchools = new Pageable(0, 5, [])
  searchedPeople: Page<PersonSearchResult>
  searchedSchools: Page<SchoolBase>

  constructor(
    private adminRest: AdminRestService
  ) {}

  ngOnInit(): void {}
  searchUpdated() {
    this.adminRest.searchSchool(this.searchTerm, this.pageableSchools).subscribe(
      schools => this.searchedSchools = schools
    )
    this.adminRest.searchPeople(this.searchTerm, this.pageablePeople).subscribe(
      people => this.searchedPeople = people
    )
  }
  resetSearchResults() {
    this.searchedPeople = null
    this.searchedSchools = null
  }

  getSearchedPeople(): PersonSearchResult[] {
    return this.searchedPeople?.content
  }

  getSearchedSchools(): SchoolBase[] {
    return this.searchedSchools?.content
  }

  peoplePageLoaded($event: Page<PersonSearchResult>) {
    this.searchedPeople = $event;
  }
  schoolsPageLoaded($event: Page<SchoolBase>) {
    this.searchedSchools = $event;
  }

  getAddress(address: Address) {
    if(!address) return
    let _address = []
    address.line1 && (_address = _address.concat(address.line1))
    address.line2 && (_address = _address.concat(address.line2))
    address.line3 && (_address = _address.concat(address.line3))
    address.line4 && (_address = _address.concat(address.line4))
    address.postal && (_address = _address.concat(address.postal))
    address.county && (_address = _address.concat(address.county))
    address.country && (_address = _address.concat(address.country))
    return _address.join(", ")
  }

  getContact(contacts: ContactEntry[]) {
    if(!contacts.length) return
    let _contacts = contacts.map(el => {
      let contact = []
      el.name && (contact.push(el.name))
      el.value && (contact.push(el.value))
      el.contactPerson && (contact.push(el.contactPerson))
      el.entryType && (contact.push(el.entryType))
      return contact.join(", ")
    })
    return _contacts
  }
}
