import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ApiOrderItem, ApiProduct, SupportedSubs, WarehouseEntry} from "../../model/rest/old-casa-api.model";
import { finalize, switchMap, take, tap} from "rxjs/operators";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {School} from "../../model/rest/casa-apiv2.model";
import {TimeUnits} from "../../services/utils/calendar-utils";
import {Observable, of, Subscription} from "rxjs";
import {environment} from "../../../environments/environment";
import {PusherService} from "../../services/pusher.service";

/**
 * catalogue definition item. Represents whole products group like "English Core"
 * or "English for Kids"
 */
export class ProductGroup{
  constructor(
    public groupName: string,
    public subGroups: ProductSubGroup[]) {
  }
}

/**
 * Products subgroup. Grouping products of the same type in the group, like
 * Exercises in English for Kids
 */
export class ProductSubGroup{
  constructor(
    public code: string,
    public name: string,
    public currency: string,
    public products: ApiProduct[]){}
}

export interface ProductsListDataAdapter {
  syncProducts(products: ApiProduct[]): Observable<any>
  listProducts(): Observable<ApiOrderItem[]>
}

@Component({
    selector: 'app-account-products',
    templateUrl: './account-products.component.html',
    styleUrls: ['./account-products.component.scss'],
    standalone: false
})
export class AccountProductsComponent implements OnInit, OnDestroy {
  // just array of number, used to iterate in the html file
  stageIndexArray: number[];
  productsCatalogue: ProductGroup[];
  // index product code to currency for faster searching
  private productCodesCurrency: {[productCode: string]: string} = {};
  private _schoolId: number;
  private schoolCredits: WarehouseEntry[];
  schoolDetails: School;
  private orderLinesToCancel: ApiOrderItem[] = [];
  private productsToOrder: ApiProduct[] = [];
  private loading = 0;
  ownedProducts: ApiOrderItem[];
  private pusherSubscription: Subscription;
  private _productsAdapter: ProductsListDataAdapter;
  success = false;
  error = false;
  private _listName: "teacher" | "student";
  private _isTeacherSubscribed: boolean = false;
  @Output() openModalEvent = new EventEmitter<void>()

  @Input()
  hideControls = false;

  @Input()
  set schoolId(id: number) {
    this._schoolId = id;

    if (id) {
      this.loading++;
      this.loadSchoolData().pipe(
        finalize(() => this.loading-- )
      ).subscribe(
        _ => this.subscribePusher()
      );
    }
  }

  @Input()
  set productList(listName: "teacher" | "student") {
    this.buildAvailableProducts(listName)
    this._listName = listName;
  }

  @Input()
  set productsAdapter(adapter: ProductsListDataAdapter) {
    this._productsAdapter = adapter
    if(adapter) this.reloadOwnedProducts().subscribe()
  }

  @Input()
  set isTeacherSubscribed(isSubscribed: boolean) {
    this._isTeacherSubscribed = isSubscribed
    if(this._productsAdapter)
      this.reloadOwnedProducts().subscribe()
  }

  clearSelection() {
    this.orderLinesToCancel = [];
    this.productsToOrder = [];
  }

  ngOnDestroy() {
    if (this.pusherSubscription) {
      this.pusherSubscription.unsubscribe();
      this.pusherSubscription = null;
    }
  }

  private subscribePusher() {
    if (!this._schoolId) return;
    if (this.pusherSubscription) return;
    this.pusherSubscription = this.pusher.joinCredits<any>(
      `casa.${environment.creditsPusher.instanceName}.warehouse.${this._schoolId}`,
      "update").pipe(
      switchMap( _ => this.loadAndStoreSchoolCredits())
    )
      .subscribe( );
  }


  constructor(
    private casaRest: CasaRestApiV2,
    private pusher: PusherService
  ) {
  }

  ngOnInit(): void {
  }

  onProductChange(group: ProductGroup, subGroup: ProductSubGroup, stageIndex: number, event: Event) {
    const product = subGroup.products[stageIndex];
    const checked = (event.target as any).checked;

    if(subGroup.currency == 'subscription.BASE' && !this._isTeacherSubscribed) return;

    if (checked) {
      this.orderProduct(group, subGroup, product);
      return;
    } else {
      this.cancelProduct(group, subGroup, product);
    }
  }

  postOrder(): Observable<any> {
    this.success = false;
    this.error = false;
    if (!this.hasAnySelection()) return of(null)
    this.loading++;
    const productsOrderingList = this.constructProductsOrderingList();
    return this._productsAdapter.syncProducts(productsOrderingList).pipe(
      switchMap( _ => this.reloadOwnedProducts()),
      switchMap( _ => this.loadAndStoreSchoolCredits()),
      take(1),
      tap( _ => {
        this.clearSelection();
        this.showSuccess();
      }),
      finalize( () => this.loading--)
    )
  }

  submitOrder() {
    if(!this._productsAdapter) return
    this.postOrder()
    .subscribe(
      {
        error: () => {
          this.showError();
        }
      }
    )
  }

  private showSuccess() {
    this.success = true;
    setTimeout(() => this.success = false, 5000);
  }

  private showError() {
    this.error = true;
    setTimeout(() => this.error = false, 10000);
  }

  private constructProductsOrderingList() {
    const productCodesToCancel = new Set(
      this.orderLinesToCancel
        .filter(item => !SupportedSubs.isSubscription(item.product.productCode))
        .map( line => line.product.productCode)
    )

    return Array.from( new Set(
      this.ownedProducts
        .map( line => line.product)
        .filter( product => !productCodesToCancel.has(product.productCode))
        .concat(...this.productsToOrder)
    ));
  }

  hasAnySelection() {
    return this.orderLinesToCancel.length > 0 || this.productsToOrder.length > 0;
  }

  private simpleProduct(code: string, name: string): ApiProduct {
    const res = new ApiProduct();
    res.productCode = code
    res.name = name
    return res
  }

  private generateProducts(codePrefix: string, namePrefix: string, count: number, codeSuffix: string = "") {
    return Array
      .from({length: count}, (_, i) => i + 1)
      .map( it => {
        const prod = new ApiProduct();
        prod.productCode = `${codePrefix}${it}${codeSuffix}`;
        prod.name = `${namePrefix}${it}`;
        return prod;
      })
  }

  generateTeachersProducts(codePrefix: string, namePrefix: string, count: number, codeSuffix: string = "") {
    return Array
      .from({length: count}, (_, i) => i + 1)
      .map( it => {
        if (!(it % 2)) return null;
        const prod = new ApiProduct();
        prod.productCode = `${codePrefix}${it}&${it+1}${codeSuffix}`;
        prod.name = `${namePrefix}${it}&${it+1}`;
        return prod;
      })
  }

  private buildAvailableProducts(listName: "teacher" | "student") {
    this.stageIndexArray = Array.from(Array(12).keys())

    if (listName === "teacher"){
      this.productsCatalogue = [
        new ProductGroup("Callan English 3rd Edition", [
          new ProductSubGroup('en-books-r3', "eBooks",'subscription.BASE',  this.generateTeachersProducts("ETEBookS", "", 12, "r3")),
          new ProductSubGroup("en-ex-r3", "Exercises",'casa', this.generateProducts("EExS", "", 12, "r3")),
          new ProductSubGroup("en-exam-r3", "Exams",'casa', this.generateProducts("EExamS", "", 12, "r3")),
          new ProductSubGroup('en-transition-r3', "Transitions 1-12", "free", [this.simpleProduct("ETEBookTransr3", "Transition")])
        ]),
        new ProductGroup( "Callan English 2nd Edition", [
          new ProductSubGroup("en-books","eBooks",'casa', this.generateTeachersProducts("ETEBookS", "", 12)),
          new ProductSubGroup("en-ex", "Exercises",'casa', this.generateProducts("EExS", "", 12))
        ]),
        new ProductGroup( "Callan Español", [
          new ProductSubGroup('sp-books', "eBooks",'casa', this.generateTeachersProducts("STEBookS", "", 6)),
          new ProductSubGroup('sp-ex', "Exercises",'casa', this.generateProducts("SExS", "", 6)),
        ]),
        new ProductGroup( "Callan for Business", [
          new ProductSubGroup('bs-books', "eBooks",'casa', this.generateProducts("EBTEBookS", "", 1)),
          new ProductSubGroup('bs-ex', "Exercises", 'casa',this.generateProducts("EBExS", "", 1)),
        ]),
        new ProductGroup("Callan for Kids", [
          new ProductSubGroup('kids-books', "eBooks",'casa',  this.generateTeachersProducts("EKidTEBookS", "", 6)),
          new ProductSubGroup('kids-ex', "Exercises", 'casa', this.generateProducts("CfkeExS", "", 6)),
        ]),
      ];
    } else {
      this.productsCatalogue = [
        new ProductGroup("Callan English 3rd Edition", [
          new ProductSubGroup('en-books-r3', "eBooks",'credit-en-r3',  this.generateProducts("EEBookS", "", 12, "r3")),
          new ProductSubGroup("en-audio-r3", "Audio",'casa', this.generateProducts("EABookS", "", 12, "r3")),
          new ProductSubGroup("en-ex-r3", "Exercises",'casa', this.generateProducts("EExS", "", 12, "r3")),
          new ProductSubGroup("en-exam-r3", "Exams",'casa', this.generateProducts("EExamS", "", 12, "r3")),
          new ProductSubGroup('en-transition-r3', "Transitions 1-12", "free", [this.simpleProduct("EEBookTransr3", "Transition")])
        ]),
        new ProductGroup( "Callan English 2nd Edition", [
          new ProductSubGroup("en-books","eBooks",'credit-en', this.generateProducts("EEBookS", "", 12)),
          new ProductSubGroup("en-audio", "Audio",'casa', this.generateProducts("EABookS", "", 12)),
          new ProductSubGroup("en-ex", "Exercises",'casa', this.generateProducts("EExS", "", 12))
        ]),
        new ProductGroup( "Callan Español", [
          new ProductSubGroup('sp-books', "eBooks",'credit-sp', this.generateProducts("SEBookS", "", 6)),
          new ProductSubGroup('sp-audio', "Audio",'casa', this.generateProducts("SABookS", "", 6)),
          new ProductSubGroup('sp-ex', "Exercises",'casa', this.generateProducts("SExS", "", 6)),
        ]),
        new ProductGroup( "Callan for Business", [
          new ProductSubGroup('bs-books', "eBooks",'credit-bs', this.generateProducts("EBEBookS", "", 1)),
          new ProductSubGroup('bs-audio', "Audio", 'casa',this.generateProducts("EBABookS", "", 1)),
          new ProductSubGroup('bs-ex', "Exercises", 'casa',this.generateProducts("EBExS", "", 1)),
        ]),
        new ProductGroup("Callan for Kids", [
          new ProductSubGroup('kids-books', "eBooks",'credit-cfk',  this.generateProducts("EKidEBookS", "", 6)),
          new ProductSubGroup('kids-audio', "Audio", 'casa', this.generateProducts("EKidABookS", "", 6)),
          new ProductSubGroup('kids-ex', "Exercises", 'casa', this.generateProducts("CfkeExS", "", 6)),
        ]),
      ];
    }

    this.productsCatalogue
      .forEach( group =>
        group.subGroups.forEach( subGroup =>
          subGroup.products.forEach( product => {
              if (!product) return;
              this.productCodesCurrency[product.productCode] = subGroup.currency;
            }
          )
        )
      );
  }

  private loadSchoolData() {
    return this.loadAndStoreSchoolCredits().pipe(
      switchMap( _ => this.loadAndStoreSchoolDetails())
    )
  }

  private loadAndStoreSchoolCredits() {
    this.storeSchoolCredits(null);
    return this.loadSchoolCredits().pipe(
      tap(credits => this.storeSchoolCredits(credits))
    )
  }

  private loadSchoolCredits() {
    return this.casaRest.queryCredits(this._schoolId)
  }

  private storeSchoolCredits(credits: WarehouseEntry[]) {
    this.schoolCredits = credits;
  }

  private loadAndStoreSchoolDetails() {
    return this.loadSchoolDetails().pipe(
      tap( schoolDetails => this.storeSchoolDetails(schoolDetails))
    )
  }

  private loadSchoolDetails() {
    return this.casaRest.getSchoolDetails(this._schoolId)
  }

  private storeSchoolDetails(schoolDetails: School) {
    this.schoolDetails = schoolDetails;
  }

  isSchoolPostPayAllowed() {
    if (this._listName === "teacher") return true;
    return this.hasLabel("eBooks");
  }

  private hasLabel(labelToFind: string) {
    if (!this.schoolDetails || !this.schoolDetails.labels) return false;
    return this.schoolDetails.labels.split(",")
      .map( it => it.trim())
      .find( it => it === labelToFind) != null
  }

  creditsLoaded() {
    return this.schoolCredits != null;
  }

  hasAnyCreditRow() {
    return this.schoolCredits.length > 0;
  }

  getCreditsEntriesSorted(): WarehouseEntry[] {
    return [
      this.getCreditEntry('en-r3'),
      this.getCreditEntry('en'),
      this.getCreditEntry('sp'),
      this.getCreditEntry('bs'),
      this.getCreditEntry('cfk'),
    ].filter(it => it)
  }

  getCreditEntry(productCode: string): WarehouseEntry {
    return this.schoolCredits.find(credit => credit.product.productCode.endsWith(productCode))
  }

  getCreditEntryNameWithAmountText(entry: WarehouseEntry) {
    if(!entry) return
    for ( let i = 0 ; i < this.productsCatalogue.length ; i++) {
      const group = this.productsCatalogue[i];
      if (group.subGroups.find( subGroup =>
        subGroup.currency === entry.product.productCode)) {
        return [group.groupName, this.calculateAvailableCredits(entry.product.productCode)]
      }
    }
    return [`Unknown credit type: ${entry.amount}`];
  }

  private calculateAvailableCredits(currency: string) {
    if (!this.creditsLoaded()) return 0;
    const creditEntry = this.schoolCredits.find( entry =>
      entry.product.productCode === currency);
    let balance = 0;
    if (creditEntry) balance = creditEntry.amount
    balance += this.orderLinesToCancel
      .filter( line => line.currency === currency)
      .reduce((sum, next) => sum + next.price, 0)
    balance -= this.productsToOrder
      .filter( prod => this.getProductCurrency(prod) === currency)
      .length
    return balance;
  }

  private getProductCurrency(product: ApiProduct) {
    return this.productCodesCurrency[product.productCode];
  }

  getStageNumber(group: ProductGroup, stageIndex: number) {
    if (this.groupHasStageOnPosition(group, stageIndex)) {
      return (stageIndex + 1).toString()
    }
    return '';
  }

  groupHasStageOnPosition(group: ProductGroup, stageIndex: number) {
    return group.subGroups.find( subGroup => subGroup.products.length > stageIndex);
  }
  subGroupHasStageOnPosition(subGroup: ProductSubGroup, stageIndex: number) {
    return subGroup.products.length > stageIndex;
  }

  onGroupSelectAllChange(group: ProductGroup, subGroup: ProductSubGroup, $event: Event) {
    if (($event.target as any).checked) {
      this.selectAll(group, subGroup);
    } else {
      this.unselectAll(group, subGroup);
    }
  }

  isSelectAllDisabled(group: ProductGroup, subGroup: ProductSubGroup) {
    for (let i = 0 ; i < subGroup.products.length ; i++) {
      if (!this.isProductDisabledByIndex(group, subGroup, i)) return false;
    }
    return true;
  }

  isProductDisabledByIndex(group: ProductGroup, subGroup: ProductSubGroup, stageIndex: number) {
    if (this.loading) return true;
    const product = subGroup.products[stageIndex];
    if (!product) return true;

    const isChecked = this.isProductCheckedByIndex(group, subGroup, stageIndex);

    return (isChecked && !this.canCancel(product))
      || (!isChecked && !this.canOrder(product));
  }

  private canCancel(product: ApiProduct) {
    if (!product) return false;
    return this.hasProductOnOrderList(product) ||
      (this.hasProduct(product)
        && !this.hasProductOnCancelList(product)
        && this.isCancelable(product));
  }

  private canOrder(product: ApiProduct) {
    if (!product) return false;
    return this.hasProductOnCancelList(product) ||
      (!this.hasProduct(product)
        && !this.hasProductOnOrderList(product)
        && this.isOrderable(product));
  }

  private hasProduct(product: ApiProduct) {
    return (this.ownedProducts
      && this.ownedProducts.find(sp =>
        sp.product.productCode === product.productCode) != null);
  }

  private isCancelable(product: ApiProduct) {
    if (!product) return false;
    const lineToCancel = this.ownedProducts.find(sp =>
      sp.product.productCode === product.productCode);

    if (!lineToCancel) return false;
    if (lineToCancel.currency === "casa") return true;
    return lineToCancel.cancelationExpiryDate >
      new Date().getTime() + TimeUnits.Minues(10).toMilis();
  }

  private isOrderable(product: ApiProduct) {
    if (this.isSchoolPostPayAllowed()) return true;
    const currency = this.getProductCurrency(product);
    if ( currency === "casa" || currency === "free") return true;
    const credits = this.calculateAvailableCredits(currency);
    return credits > 0;
  }

  private hasProductOnCancelList(product: ApiProduct) {
    return this.orderLinesToCancel.find( line =>
      line.product.productCode === product.productCode) != null
  }

  private hasProductOnOrderList(product: ApiProduct) {
    if (!product) return false;
    return this.productsToOrder.find( toOrder =>
      toOrder.productCode === product.productCode) != null;
  }

  isProductCheckedByIndex(group: ProductGroup, subGroup: ProductSubGroup, stageIndex: number) {
    const product = subGroup.products[stageIndex];
    if (!product) return false;
    return (this.hasProduct(product) || this.hasProductOnOrderList(product)) && !this.hasProductOnCancelList(product);
  }

  private isAudioSubGroup(subGroup: ProductSubGroup) {
    return subGroup.code.endsWith("-audio");
  }

  isSelectAllChecked(group: ProductGroup, subGroup: ProductSubGroup) {
    if (this.isSelectAllDisabled(group, subGroup)) return false;

    for (let i = 0 ; i < subGroup.products.length ; i++) {
      if (!this.isProductDisabledByIndex(group, subGroup, i)
        && !this.isProductCheckedByIndex(group, subGroup, i)
      ) {
        return false;
      }
    }
    return true;
  }

  private selectAll(group: ProductGroup, subGroup: ProductSubGroup) {
    for (let i = 0 ; i < subGroup.products.length ; i++) {
      if (this.isProductDisabledByIndex(group, subGroup, i)
        || this.isProductCheckedByIndex(group, subGroup, i)) continue;
      this.orderProduct(group, subGroup, subGroup.products[i]);
    }
  }

  private unselectAll(group: ProductGroup, subGroup: ProductSubGroup) {
    for (let i = 0 ; i < subGroup.products.length ; i++ ) {
      if (this.isProductDisabledByIndex(group, subGroup, i)
        || !this.isProductCheckedByIndex(group, subGroup, i)) continue;
      this.cancelProduct(group, subGroup, subGroup.products[i]);
    }
  }

  orderProduct(group: ProductGroup, subGroup: ProductSubGroup, product: ApiProduct) {
    if (!this.canOrder(product)) return;

    // bring back canceled product
    const cancellingPos = this.orderLinesToCancel.findIndex( line =>
      line.product.productCode === product.productCode
    );

    if (cancellingPos>=0) {
      this.orderLinesToCancel.splice(cancellingPos, 1);
    } else {
      this.productsToOrder.push(product);
    }
  }

  cancelProduct(group: ProductGroup, subGroup: ProductSubGroup, product: ApiProduct) {
    if (!this.canCancel(product)) return;

    const orderingPos = this.productsToOrder.findIndex( orderingLine =>
      orderingLine.productCode === product.productCode
    );

    if (orderingPos >= 0) {
      this.productsToOrder.splice(orderingPos, 1);
      return;
    }
    // or add to canceling
    const lineToCancel = this.ownedProducts.find(sp =>
      sp.product.productCode === product.productCode)
    if (!lineToCancel) return;
    this.orderLinesToCancel.push(lineToCancel);
  }

  private reloadOwnedProducts() {
    this.loading++;
    return this._productsAdapter.listProducts()
    .pipe(
      tap(products => this.storeProducts(products)),
      finalize( () => this.loading-- )
    )
  }

  private storeProducts(products: ApiOrderItem[]) {
    this.ownedProducts = products;
  }

  isLoading() {
    return this.loading > 0;
  }

  hasProductCredits(subGroup: ProductSubGroup) {
    if (this.isSchoolPostPayAllowed()) return true;
    return this.schoolCredits?.some(credits => credits.product.productCode === subGroup.currency || subGroup.currency == 'casa' );
  }

  isFreeProduct(subgroup: ProductSubGroup) {
    return subgroup.currency == 'free' || subgroup.name != 'eBooks'
  }

  openSubscriptionModal(subGroup: ProductSubGroup) {
    if(this._listName == "teacher"
      && !this._isTeacherSubscribed
      && subGroup.code == 'en-books-r3')
      this.openModalEvent.emit()
  }

  hasSubscriptionForProducts(subGroup: ProductSubGroup) {
    if(this._listName == "student" || subGroup.code != 'en-books-r3') return true
    return this._isTeacherSubscribed;
  }
}
