import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { ReportResponse, ReportsMap, ReportSpecification, ParamTypes, ReportRequestParam } from 'src/app/model/report';
import { School } from 'src/app/model/rest/casa-apiv2.model';
import { AuthServiceProvider, UserRole } from 'src/app/services/col2/auth.service';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';
import { ExportExcelService } from 'src/app/services/export-excel.service';
import { ReportsRestService } from 'src/app/services/rest/reports-rest.service';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ErrorBase } from 'src/app/model/server';

@Component({
    selector: 'app-reports-page',
    templateUrl: './reports-page.component.html',
    styleUrls: ['./reports-page.component.scss'],
    standalone: false
})
export class ReportsPageComponent implements OnInit {

  schoolId: number
  params: ReportRequestParam[]
  report: ReportSpecification
  
  @ViewChild('getReportForm') form: NgForm

  @Output() reportResponse: ReportResponse
  
  reports
  paramTypes = ParamTypes
  role: UserRole
  isBusy: boolean

  @ViewChild("timeoutErrorInfoModal", {static: true})
  timeoutErrorInfoModal: ModalComponent;
  error: ErrorBase

  constructor (
    activatedRoute: ActivatedRoute, 
    private reportService: ReportsRestService, 
    private authService: AuthServiceProvider,
    private casaRestService: CasaRestApiV2,
    private exportExcelService: ExportExcelService
  ){
    activatedRoute.parent.parent.paramMap.subscribe(params => this.schoolId = parseInt(params.get('schoolId')));
    this.authService.get().pipe(
      switchMap(api => api.getUserRole()),
      tap(role => this.role = role),
      switchMap(_ => this.casaRestService.getManagedSchools()),
      tap(managedSchools => this.reports = this.prepareReports(managedSchools))
    ).subscribe()
  }

  ngOnInit(): void {}

  getParamLabel(paramName: string){
    return paramName.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase()
  }

  prepareReports(managedSchools: School[]) {
    return new Map(Array.from(ReportsMap).filter(([reportType, reportSpecification]) => {
      if(reportSpecification.roles.includes(this.role)){
        if(this.role === UserRole.Admin) return true
        if(managedSchools.length === 1 && managedSchools[0].id === 1)
          return reportSpecification.isForCallanOnline 
        else if(managedSchools.some(school => school.id === 1) && managedSchools.length > 1){
          return true
        }
        else if (managedSchools.every(school => school.id !== 1)){
          return !reportSpecification.isForCallanOnline
        }
      }
      else return false
    }
  ))
}

  onReportChange(report: ReportSpecification){
    this.params = report.parameters.flatMap(param => 
      param.paramName.map(name => {
          return {
            paramName : name, 
            type: param.type, 
            required: param.required, 
            value: ""
        }})
    )
    this.form.resetForm()
  }

  getReport() {
    this.isBusy = true
    if(this.validateParams()){
        this.reportService.getReport(this.schoolId, this.report, this.params, this.role)
          .subscribe({
            next: reportResponse => {
            this.reportResponse = reportResponse
            this.isBusy = false
            },
            error: e => {
              this.error = e
              this.isBusy = false
              this.timeoutErrorInfoModal.show()
            }
          }
        )
    }
  }

  validateParams() { 
    const invalidFields = this.params.filter(param => param.required && !param.value)

    if(invalidFields.length > 0) return false
    else return true 
  }

  download(){
    this.exportExcelService.exportToExcel(this.prepareDataToDownload(), this.reportResponse.meta.reportName, this.getReportKey())
  }

  prepareDataToDownload(){
    const columnLabels = this.reportResponse.colsMeta.map(col => col.columnLabel);
    return this.reportResponse.data.map(row => {
      const transformedRow = {};
      row.forEach((value, index) => {
          const label = columnLabels[index];
          transformedRow[label] = value;
      });
      return transformedRow;
  });
  }

  getReportKey(){
    for(const [key, value] of this.reports.entries()){
      if(value === this.report) return key
    }
  }
}
