import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, UrlSegment, ParamMap, RouterEvent, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {filter, mergeMap, switchMap, tap} from 'rxjs/operators';
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";
import {CallanOnlineRecognizeService} from "../../services/callan-online-recognize.service";
import {combineLatest, Observable} from "rxjs";
import {
  School,
  Student,
  VerifiedEmailChangeRequest,
  VerifiedPasswordChangeRequest
} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {PersonPasswordChangeComponent} from "../../components/person-password-change/person-password-change.component";
import {PersonEmailChangeComponent} from "../../components/person-email-change/person-email-change.component";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";
import {ConfirmDialogService} from "../../services/confirm-dialog.service";
import { ManagerUtils } from 'src/app/services/utils/manager-utils';
import {FunctionalAccessService} from "../../services/functional-access.service";

@Component({
    selector: 'app-manager-student-details-layout',
    templateUrl: './manager-student-details-layout.component.html',
    styleUrls: ['./manager-student-details-layout.component.css'],
    standalone: false
})
export class ManagerStudentDetailsLayoutComponent implements OnInit {
  schoolId: number;
  studentId: number;
  student: Student;
  currentFragment: string;
  private currentFragments: string[];
  searchTerm = "";
  private school: School;
  @ViewChild('passwordModal', {static: true})
  passwordModal: PersonPasswordChangeComponent;
  @ViewChild('emailModal', {static: true})
  emailModal: PersonEmailChangeComponent;


  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private managerRest: ManagerRestService,
    private casa: CasaRestApiV2,
    public callanOnlineRecognize: CallanOnlineRecognizeService,
    private confirmationService: ConfirmDialogService,
    private toastNotificationService: ToastNotificationsService,
    public functionalAccessService: FunctionalAccessService) {
      router.events.pipe(
        filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
                                    e instanceof NavigationEnd || 
                                    e instanceof NavigationCancel || 
                                    e instanceof NavigationError))
      .subscribe((e: RouterEvent) => {
          e['urlAfterRedirects']? this.currentFragments = e['urlAfterRedirects'].split('/') : ''
          this.currentFragment = this.currentFragments[this.currentFragments.length - 1]
      });
    }


  changePasswordCallback = (request : VerifiedPasswordChangeRequest) => this.casa.changeStudentPassword(this.schoolId, this.studentId, request)
  changeEmailCallback = (request: VerifiedEmailChangeRequest) => this.casa.changeStudentEmail(this.schoolId, this.studentId, request)

  getPersonName() {
    return this.getName(this.student);
  }

  getName(student: Student) {
    return PersonNameExtractor.getCasaPersonName(student.person);
  }

  getInitials(student: Student) {
    return ManagerUtils.getInitials(student.person)
  }


  loadStudent(): Observable<Student> {

    return this.casa.getStudent(this.schoolId, this.studentId).pipe(
      tap( student => {
        this.student = student;
        const childActiveStateUrl = (this.activeRoute.children && this.activeRoute.children.length>0) ? this.activeRoute.children[0].snapshot.url : null;
        const selectedStudentPage = this.getStudentPageSelected(childActiveStateUrl);
        if (!selectedStudentPage) {
          this.redirectToStudentDefaultPage();
        }
      })
    )
  }

  ngOnInit() {
    combineLatest([
      this.activeRoute.parent.paramMap,
      this.activeRoute.paramMap
    ]).pipe(
      tap<[ParamMap, ParamMap]>(([parentParams, params]) => {
        this.schoolId = Number(parentParams.get('schoolId'));
        this.studentId = Number(params.get('studentId'));
      }),
      mergeMap( _ => this.loadSchool()),
      mergeMap( _ => this.loadStudent())
    ).subscribe();
  }

  private supportedPaths = ['credits', 'lessons', 'profile', 'products', 'cspa-monitor', 'casa-profile', 'exam-results'];

  getStudentPageSelected(urls: UrlSegment[]) {
    if (!urls) return null;
    if (urls.length > 0) {
      const lastPath = urls.length > 0 ? urls[urls.length - 1].path : '';
      const prevPath = urls.length > 1 ? urls[urls.length - 2].path : '';

      if (this.supportedPaths.indexOf(lastPath) >=0 ) return lastPath;
      // special case for lessons details
      else if(this.supportedPaths.indexOf(prevPath) >=0) return prevPath;
      else {
        return null;
      }
    }
    return null;
  }

  isCallanOnline() {
    if (!this.schoolId) return false;
    return this.callanOnlineRecognize.isCallanOnlineFranchiseById(this.schoolId);
  }

  private redirectToStudentDefaultPage() {
    if (!this.schoolId || !this.studentId) return;
    if (this.isCallanOnline()) {
      this.router.navigate(["school", this.schoolId.toString(), "student", this.studentId.toString(), "lessons"]);
    } else {
      this.router.navigate(["school", this.schoolId.toString(), "student", this.studentId.toString(), "products"]);
    }
  }

  searchStudent() {
    this.router.navigate(['school', this.schoolId.toString(), "students"], {queryParams: {term: this.searchTerm}});
  }


  private loadSchool() {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.school = school)
    )
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  changePassword() {
    this.passwordModal.show();
  }

  changeEmail() {
    this.emailModal.show();
  }

  prolongStudent() {
    this.confirmationService.doOnConfirm("Are you sure you want renew the student?",
      () => {
        this.casa.prolongStudent(this.schoolId, this.studentId).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student renewal",`Student access has been renewed.`))),
          switchMap( _ => this.loadStudent())
        ).subscribe()
      } ,
      "Student renewal");
  }

  blockStudent() {
    this.confirmationService.doOnConfirm("Are you sure you want to block the student?",
      () => {
        this.casa.blockStudent(this.schoolId, this.studentId).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student blocking",`Student has been blocked.`))),
          switchMap( _ =>  this.loadStudent())
        ).subscribe();
      },"Student blocking", "bg-warning");
  }

  deleteStudent() {
    this.confirmationService.doOnConfirm("Are you sure you want delete the student?",
      () => {
        this.casa.deleteStudent(this.schoolId, this.studentId).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student deletion",`Student has been deleted.`))),
        ).subscribe(_ => this.router.navigate(["school", this.schoolId.toString(), "students"]));
      },
      "Student deletion",
      "bg-danger text-white"
    );
  }

  getStatus() {
    if (!this.student) return '';
    return this.student.status;
  }

  getAccountEmail() {
    if (!this.student) return '';
    return this.student.person.registrationEmail;
  }

  resetBookShares(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you to reset student eBooks download limit?\n" +
      "This will remove the eBooks from all student's devices, so that they can download the eBooks to new devices.",
      () => {
        this.casa.clearStudentDeviceShares(this.schoolId, student.id).subscribe(
          _ => this.toastNotificationService.display(
            new ToastNotification("Reset of student's eBooks download limit", `Students' eBooks download limit reset successfully.`)
          )
        )
      }, "Reset of student's eBooks download limit","bg-warning");
  }

  reloadStudent(student: Student) {
    this.casa.syncStudent(this.schoolId, student.id).subscribe(
      _ => this.toastNotificationService.display(
        new ToastNotification("Refreshing of student's eBooks & Exercises", `Students' eBooks & Exercises has been refreshed successfully. Student should be able to access all content.`, "bg-success text-white"))
    )
  }

  anonymize(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you want to permanent delete the student?",
      () => {
        this.casa.anonymizePerson(student.person.id).subscribe(
           _ => {
              this.toastNotificationService.display(new ToastNotification("Student has been deleted", "Student has been deleted correctly"));
              this.router.navigate(["school", this.schoolId.toString(), "students"]);
          }
        )

      }, "Student permanent delete", "bg-danger text-white"
      )
  }
}
