import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";
import * as Sentry from "@sentry/angular";

@Component({
    selector: 'app-toast-notifications',
    templateUrl: './toast-notifications.component.html',
    styleUrls: ['./toast-notifications.component.css'],
    standalone: false
})
export class ToastNotificationsComponent implements OnInit, OnDestroy {
  private notificationsSubs: Subscription;

  messages: ToastNotification[] = [];
  isOnTop: boolean = true

  constructor(
    private toastNotificationsService: ToastNotificationsService
  ) { }

  ngOnInit() {
    this.notificationsSubs = this.toastNotificationsService.toDisplay.subscribe(
      message => this.displayMessage(message)
    );
  }

  ngOnDestroy(): void {
    this.notificationsSubs.unsubscribe();
  }

  private displayMessage(message: ToastNotification) {
    this.messages.splice(0,0, message);
    setTimeout(() => this.removeMessage(message), 5000);
  }

  private removeMessage(message: ToastNotification) {
    const messageIndex = this.messages.indexOf(message);
    if (messageIndex < 0) return;
    this.messages.splice(messageIndex, 1);
  }

  reportAnError(notification: ToastNotification){
      Sentry.showReportDialog({
        eventId: notification.eventId,
        onLoad: () => {
          this.isOnTop = false
        },
        onClose: () => {
          this.isOnTop = true
        }
      })
    }
}
