import {Component, Input, OnInit} from '@angular/core';
import {
  Correction,
  CorrectionAnswer,
  ExerciseSessionQuestion
} from "../../../model/rest/cspa.model";

@Component({
    selector: 'app-cor-result',
    templateUrl: './cor-result.component.html',
    styleUrls: ['./cor-result.component.scss'],
    standalone: false
})
export class CorResultComponent implements OnInit {
  @Input() questions: ExerciseSessionQuestion<CorrectionAnswer, Correction> []

  constructor() { }

  ngOnInit(): void {
  }

  getCorrectAnswer(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return q.question.definition.answer.correct
  }

  getStudentAnswer(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return q.answer?.correct
  }

  isIncorrect(q: ExerciseSessionQuestion<CorrectionAnswer, Correction>) {
    return !q.correct
  }
}
