import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Page, Pageable } from 'src/app/model/rest/base';
import { NewsListEntry } from 'src/app/model/rest/casa-apiv2.model';
import { LocalStateService } from 'src/app/services/local-state.service';
import { NewsService } from 'src/app/services/rest/news-rest.service';
import { LocalState, SortingRule } from 'src/app/services/utils/manager-utils';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';

class NewsSorting extends SortingRule {
  constructor(public name: string, sorting: string[]) {
    super(sorting);
  }
  static ByTitle = new NewsSorting('By Title', ['content.title,ASC']);
  static ByTitleDesc = new NewsSorting('By Title (descending)', [
    'content.title,DESC',
  ]);
  static ByDate = new NewsSorting('By Date', ['publicationDate,ASC']);
  static ByDateDesc = new NewsSorting('By Date (descending)', [
    'publicationDate,DESC',
  ]);

  static values = [
    NewsSorting.ByTitle,
    NewsSorting.ByTitleDesc,
    NewsSorting.ByDate,
    NewsSorting.ByDateDesc,
  ];
}

@Component({
    selector: 'app-news-page',
    templateUrl: './news-page.component.html',
    styleUrls: ['./news-page.component.scss'],
    standalone: false
})
export class NewsPageComponent implements OnInit {
  schoolId: number;
  managerId: number;
  newsList: Page<NewsListEntry>;
  sortingRules = NewsSorting.values;
  currentState: LocalState<NewsSorting>;
  pageable = new Pageable(0, 10, []);
  loaded = false;

  constructor(
    private route: ActivatedRoute,
    private news: NewsService,
    private localStateService: LocalStateService,
    private auth: AuthServiceProvider
  ) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        tap((params) => (this.schoolId = +params.get('schoolId'))),
        switchMap((_) => this.restoreNewsLocalState()),
        tap((state) => {
          this.currentState = state;
          this.pageable.sort = this.currentState.sortingRule.sorting;
        }),
        switchMap((_) => this.auth.get()),
        tap((auth) => (this.managerId = auth.getManagerId())),
        switchMap((_) => this.loadNews())
      )
      .subscribe();
  }

  loadNews() {
    return this.news
      .listNews(this.schoolId, this.managerId, this.pageable)
      .pipe(
        tap((news) => {
          this.newsList = news;
        }),
        finalize(() => (this.loaded = true))
      );
  }

  private restoreNewsLocalState() {
    const contextPath = `/schools/${this.schoolId}/news`;
    return this.localStateService.get<LocalState<NewsSorting>>(
      contextPath,
      () =>
        new LocalState<NewsSorting>(
          '',
          NewsSorting.ByDateDesc,
          Pageable.of(0, 10, null)
        )
    );
  }

  sort() {
    this.pageable.sort = this.currentState.sortingRule.sorting;
    this.loadNews().subscribe();
  }
}
