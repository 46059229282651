import { Component, OnInit } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { SentryService } from './services/sentry-service.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  title = 'schools-manager';

  constructor(
    private themeService: ThemeService,
    private sentryService: SentryService) {}

  ngOnInit() {
    this.themeService.initialize()
  }
}
