import {Component, OnInit} from '@angular/core';
import {School} from 'src/app/model/rest/casa-apiv2.model';
import {CasaRestApiV2} from 'src/app/services/rest/casa-rest.service';
import {ActivatedRoute} from "@angular/router";
import {switchMap, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {AuthServiceProvider} from "../../../services/col2/auth.service";
import {FunctionalAccessService} from "../../../services/functional-access.service";

@Component({
    selector: 'app-admin-school-dashboard',
    templateUrl: './admin-school-dashboard.component.html',
    styleUrls: ['./admin-school-dashboard.component.scss'],
    standalone: false
})
export class AdminSchoolDashboardComponent implements OnInit {

  school: School
  schoolId: number

  constructor(
    private casaRest: CasaRestApiV2,
    private route: ActivatedRoute,
    private authProvider: AuthServiceProvider,
    private functionalAccessService: FunctionalAccessService
  ) {}

  ngOnInit(): void {
    this.route.parent.paramMap.pipe(
      tap(params => this.schoolId = +params.get("schoolId")),
      switchMap(_ => this.casaRest.getSchoolDetails(this.schoolId))
    ).subscribe(school => (this.school = school))
  }

  redirectTo(path: string) {
      this.logInAsManager(path).subscribe()
  }

  logInAsManager(path: string): Observable<any> {
    return this.authProvider
      .get()
      .pipe(
        tap((auth) => auth.logInAsManager(this.schoolId, path)),
        //reinitialize functionalAccess when admin has manager school id
        tap(_ => this.functionalAccessService.initialize())
      )
  }
}
