import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-menu',
    templateUrl: './no-menu.component.html',
    styleUrls: ['./no-menu.component.css'],
    standalone: false
})
/*
  Outer component for pulic pages without menu
*/
export class NoMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
