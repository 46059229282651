<div class="bg-workspace min-vh-100">
  <top-bar [title]="'Schools'"></top-bar>
  <div class="container py-4 px-md-5">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item active" aria-current="page">Schools</li>
      </ol>
    </nav>
    <!-- <div class="row mb-4">
      <div class="col-auto mb-3 mb-sm-0"></div>
      <div class="col-auto ms-auto">
        <a class="btn btn-primary px-4 py-2 rounded-pill">Add new school</a>
        <a class="btn btn-primary px-4 py-2 rounded-pill"
          >Add existing school</a
        >
      </div>
    </div> -->
    <div class="card">
      <form class="card-header row gy-2 gy-md-4 gx-3">
        <div
          class="col-12 col-sm-5"
          [class]="isSearchByExpDate() ? 'col-lg-4' : 'col-lg-3'"
        >
          <div class="form-floating">
            <select
              id="searchType"
              name="searchType"
              [(ngModel)]="searchForm.searchType"
              (ngModelChange)="updateSearchType()"
              #searchType="ngModel"
              class="form-select select-not-empty"
            >
              <option *ngFor="let type of getAllSearchTypes()" [value]="type">
                {{ getSearchTypeName(type) }}
              </option>
            </select>
            <label for="searchType">Search by</label>
          </div>
        </div>
        <div
          *ngIf="!isSearchByExpDate() && !isSearchByStatus() && !isSearchBySchoolId()"
          class="col-12 col-sm-7 col-lg-4 d-flex"
        >
          <div class="position-relative w-100">
            <input
              name="search-value"
              type="text"
              class="form-control p-3 h-100"
              placeholder="Search"
              [(ngModel)]="searchForm.searchValue"
              #searchValue="ngModel"
              (ngModelChange)="updateSearch()"
            />
            <button
              type="submit"
              class="btn position-absolute end-0 top-0 bottom-0"
            >
              <app-icon name="search" class="icon-primary"></app-icon>
            </button>
          </div>
        </div>
        <ng-container *ngIf="isSearchBySchoolId()">
          <div class="col-12 col-sm-7 col-lg-4 d-flex">
            <div class="position-relative w-100">
              <input
                name="search-value-number"
                type="text"
                class="form-control p-3 h-100"
                placeholder="Search"
                [(ngModel)]="searchForm.searchValue"
                #searchValueNumber="ngModel"
                (ngModelChange)="!isNumberInvalid(searchForm.searchValue) && updateSearch()"
                [ngClass]="{
                'is-invalid': isNumberInvalid(searchForm.searchValue)
              }"
              />
              <button
                type="submit"
                class="btn position-absolute end-0 top-0 bottom-0"
              >
                <app-icon name="search" class="icon-primary"></app-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isSearchByStatus()">
          <div class="col-12 col-sm-7 col-lg-4">
            <div class="form-floating">
              <select
                id="searchStatus"
                name="searchStatus"
                [(ngModel)]="searchForm.schoolStatus"
                #searchStatus="ngModel"
                class="form-select"
                [ngClass]="{ 'select-not-empty': searchForm.schoolStatus }"
                (ngModelChange)="updateSearch()"
              >
                <option
                  *ngFor="let type of getSchoolStatusList()"
                  [value]="type"
                >
                  {{ getSchoolStatusName(type) }}
                </option>
              </select>
              <label for="searchStatus">School status</label>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="isSearchByExpDate()">
          <div class="col-sm-7 d-lg-none"></div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-floating">
              <input
                name="dateAfter"
                placeholder="dd/MM/yyyy HH:mm"
                class="form-control"
                type="date"
                [(ngModel)]="searchForm.dateAfter"
                (ngModelChange)="updateSearch()"
              />
              <label>Date from</label>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-lg-4">
            <div class="form-floating">
              <input
                name="dateBefore"
                placeholder="dd/MM/yyyy HH:mm"
                class="form-control"
                type="date"
                [(ngModel)]="searchForm.dateBefore"
                (ngModelChange)="updateSearch()"
              />
              <label>Date to</label>
            </div>
          </div>
        </ng-container>
        <div class="col-auto d-flex">
          <button
            class="btn btn-primary rounded-circle px-2 my-auto"
            (click)="resetSearch()"
          >
            <app-icon name="restart" class="icon-white"></app-icon>
          </button>
        </div>
      </form>

      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr class="table-row table-row-head text-nowrap">
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.schoolName)">School
                  <span [ngClass]="sortColumn === sortColumns.schoolName ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.schoolId)">School ID
                  <span [ngClass]="sortColumn === sortColumns.schoolId ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.country)">Country
                  <span [ngClass]="sortColumn === sortColumns.country ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.status)">Status
                  <span [ngClass]="sortColumn === sortColumns.status ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.activeStudents)">Active students
                  <span [ngClass]="sortColumn === sortColumns.activeStudents ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.activeTeachers)">Active teachers
                  <span [ngClass]="sortColumn === sortColumns.activeTeachers ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th [ngClass]="loading ? 'pe-none' : 'cursor-pointer'" (click)="sortTable(sortColumns.expiry)">Expiry
                  <span [ngClass]="sortColumn === sortColumns.expiry ? 'visible' : 'invisible'" class="text-muted xsmall"> {{ sortDirection === 'ASC' ? '▲' : '▼' }}</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="table-body" *ngIf="schoolsPage">
              <tr
                *ngFor="let school of schoolsPage.content"
                class="table-row cursor-pointer "
                (click)="navigateBasingOnRole(school)"
              >
                <td class="col-3">{{ school.details.name }}</td>
                <td class="col-2 text-nowrap">
                  {{ school.id }}<span *ngIf="school.oldId">/{{ school.oldId }}</span>
                </td>
                <td class="col-2">{{ school.details.address.country }}</td>
                <td class="col-2">{{ school.status }}</td>
                <td class="col-1">{{ school.estimate.active }}</td>
                <td class="col-1">{{ school.activeTeachers }}</td>
                <td class="col-1">{{ school.expDate | date : "d/MM/yyyy" }}</td>
                <td class="text-end col-1">
                  <a
                    class="btn btn-outline-secondary-2 rounded-circle"
                    (click)="navigateBasingOnRole(school)"
                  >
                    <app-icon
                      name="angle-right"
                      class="icon-primary"
                    ></app-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row">
          <app-paginator
            class="col-auto ms-auto"
            [data]="schoolsPage"
            [pageable]="pageable"
            (newPage)="switchPage($event)"
          ></app-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
