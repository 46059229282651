import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Page, Pageable } from 'src/app/model/rest/base';
import { NewsListEntry } from 'src/app/model/rest/casa-apiv2.model';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { NewsService } from 'src/app/services/rest/news-rest.service';

@Component({
    selector: 'app-news-details-page',
    templateUrl: './news-details-page.component.html',
    styleUrls: ['./news-details-page.component.scss'],
    standalone: false
})
export class NewsDetailsPageComponent implements OnInit {
  schoolId: number;
  managerId: number;
  articleId: number;
  article: NewsListEntry;
  footerArticles: NewsListEntry[];
  articleRead: boolean = false

  constructor(private route: ActivatedRoute, private news: NewsService, private auth: AuthServiceProvider) {}

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        tap((params) => {
          this.schoolId = +params.get('schoolId');
          this.articleId = +params.get('newsId');
        }),
        switchMap(_ => this.auth.get()),
        tap(auth => this.managerId = auth.getManagerId()),
        switchMap((_) => this.news.getNewsById(this.schoolId, this.managerId, this.articleId)),
        tap((article) => (this.article = article)),
        tap(_ => (this.articleRead = this.article.readingDate != null)),
        switchMap((_) => this.news.listNews(this.schoolId, this.managerId, new Pageable(0, 3, ["publicationDate,DESC"]))),
        tap((articles) => {
          this.footerArticles = articles.content?.filter(art => art.id != this.articleId)
        })
      )
      .subscribe();
  }

  markAsRead() {
    this.news.updateUserNewsStatus(this.schoolId, this.managerId, this.articleId).subscribe()
  }

  getLabels() {
    return this.article?.newsItem?.content?.labels.split(',').map(l => l.trim())
  }

  getAuthor() {
    return `${this.article?.newsItem?.author.details.name} ${this.article?.newsItem?.author.details.surname? this.article?.newsItem?.author.details.surname : ''}`
  }
}
