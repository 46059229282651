import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ComponentEvent, StateAwareComponent} from "../../../model/rest/base";
import {ApiCourseContext, ApiLessonInstanceBase, ApiProductContext} from "../../../model/rest/rest-model";
import {Dates} from "../../../services/utils/calendar-utils";
import {ManagerRestService} from "../../../services/col2/manager-rest.service";
import {combineLatest, Subject} from "rxjs";
import {filter, switchMap, tap} from "rxjs/operators";

export class StudentProgressComponentEvent extends ComponentEvent {}

@Component({
    selector: 'app-student-context-based-progress',
    templateUrl: './student-context-based-progress.component.html',
    styleUrls: ['./student-context-based-progress.component.css'],
    standalone: false
})
export class StudentContextBasedProgressComponent extends StateAwareComponent<StudentProgressComponentEvent> implements OnInit, OnDestroy {

  @Input()
  set studentId(value:number) {
    this.studentIdSubject.next(value);
  }
  @Input()
  set schoolId(value: number) {
    this.schoolIdSubject.next(value);
  }
  @Input()
  public set langCode(lang: string) {
    this._lang = lang;
    this.langCodeSubject.next(lang);
  }


  private _lang: string;
  private courseContexts: ApiCourseContext[];
  private currentIndex: number = null;
  private loaded = false;
  private studentIdSubject = new Subject<number>();
  private schoolIdSubject = new Subject<number>();
  private langCodeSubject = new Subject<string>();

  constructor(
    private managerRest: ManagerRestService) {
    super();

    combineLatest(
      this.studentIdSubject,
      this.schoolIdSubject,
      this.langCodeSubject
    ).pipe(
      filter<[number, number, string]>(( [ studentId, schoolId, langCode]) => studentId != null && schoolId != null && langCode != null),
      switchMap(( [ studentId, schoolId, langCode]) => managerRest.getStudentProductContext(schoolId, studentId, langCode)),
      tap( context => this.storeData(context))
    ).subscribe();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  private storeData(context: ApiProductContext) {
    this.loaded = true;
    this.currentIndex = null;
    if (!context) return;
    this.courseContexts = context.courseContexts;
    if (this.courseContexts.length > 0) {
      this.currentIndex = this.courseContexts.length - 1;
    }
  }

  hasAnyProgress() {
    return this.currentIndex != null;
  }

  getCurrentCourseName() {
    return this.getContext().course.name;
  }

  hasPrev() {
    return this.currentIndex != null && this.currentIndex > 0;
  }

  hasNext() {
    return this.currentIndex != null && this.currentIndex < this.courseContexts.length - 1;
  }

  moveCourse(number: number) {
    const prevValue = this.currentIndex;
    this.currentIndex += number;
    if (this.currentIndex < 0 || this.currentIndex >= this.courseContexts.length) {
      this.currentIndex = prevValue;
    }
  }

  hasNoProgress() {
    return this.loaded && this.currentIndex == null;
  }

  getProgress() {
    const result = this.getContext().lessonsProgress;
    return result != null ? result * 100 : 0;
  }

  getRevisionProgress() {
    return this.getContext().revisionProgress ? this.getContext().revisionProgress * 100 : 0;
  }

  getQuickStageRevisionProgress() {
    if (!this.hasQs()) return 0;
    if (this.hasAnyLesson()) return 100;
    return this.getContext().quickStageRevisionProgress ? this.getContext().quickStageRevisionProgress * 100 : 0;
  }

  hasAnyLesson() {
    return this.getContext().lessons > 0;
  }

  private static getLessonStartDate(lesson: ApiLessonInstanceBase) {
    if (!lesson || !lesson.lessonMetric || !lesson.lessonMetric.started) return null;
    return Dates.parse(String(lesson.lessonMetric.started));
  }

  getFirstLessonDate() {
    return StudentContextBasedProgressComponent.getLessonStartDate(this.getContext().firstLesson);
  }

  private getContext() {
    return this.courseContexts[this.currentIndex];
  }

  getNumberOverallLessons() {
    return this.getContext().overall;
  }

  getLastLessonDate() {
    const context = this.getContext();
    const allDates = [
      this.getLastExamDate(),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastQuickStageRevision),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastRegularLesson),
      StudentContextBasedProgressComponent.getLessonStartDate(context.lastRevision)
    ].filter( el => el != null)
      .sort((l,r) => l.getTime() - r.getTime());
    if (allDates.length === 0) return null;
    return allDates[allDates.length - 1];
  }

  getQuickStageRevisions() {
    return this.getContext().quickStageRevisions;
  }

  getRegular() {
    return this.getContext().lessons;
  }

  getLastExamDate() {
    return StudentContextBasedProgressComponent.getLessonStartDate(this.getContext().lastExam);
  }

  hasQs() {
    const context = this.getContext();
    if (!context) return false;
    if (context.quickStageRevisions > 0) return true;
    return false;
  }

  getQsProgressWidth() {
    const weight = this.hasQs() ? 15 : 0;
    const progress = this.getQuickStageRevisionProgress();
    return this.widthStyle(progress, weight);
  }

  getExamProgressWidth() {
    const weight = 5;
    const progress = this.getContext().exams ? 100 : 0;
    return this.widthStyle(progress, weight);
  }

  getRevisionProgressWidth() {
    const weight = this.hasQs() ? 10 : 15;
    const progress = this.getRevisionProgress();
    return this.widthStyle(progress, weight);
  }

  getLessonProgressWidth() {
    const weight = this.hasQs() ? 70 : 80;
    const progress = this.getProgress();
    return this.widthStyle(progress, weight);
  }

  private widthStyle(width: number, weight: number) {
    return {width: `${width * weight / 100}%`};
  }

  getExamProgressDescription() {
    return this.getContext().exams ? '✓' : '---';
  }
}
