import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { School } from 'src/app/model/rest/casa-apiv2.model';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { CasaRestApiV2 } from 'src/app/services/rest/casa-rest.service';

@Component({
    selector: 'top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss'],
    standalone: false
})
export class TopBarComponent implements OnInit {
  school: School;
  @Input() title: String;
  constructor(
    private authService: AuthServiceProvider,
    private route: ActivatedRoute,
    private casaRest: CasaRestApiV2
  ) {}

  ngOnInit(): void {
    this.route.parent.paramMap
      .pipe(
        map((params) => Number(params.get('schoolId'))),
        switchMap((schoolId) =>
          schoolId ? this.loadSchool(schoolId) : of(null)
        )
      )
      .subscribe();
  }

  loadSchool(schoolId) {
    return this.casaRest
      .getSchoolDetails(schoolId)
      .pipe(tap<School>((school) => (this.school = school)));
  }

  getTitle() {
    return this.title;
  }

  isAdminLoggedInAsManager() {
    return this.authService
      .get()
      .pipe(map((auth) => auth.isLoggedInAsManager()));
  }
}
