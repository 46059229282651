import { Component, OnInit, Input } from '@angular/core';
import { ApiPersonTechnicalProfile } from 'src/app/model/rest/rest-model';
import { Languages, ComponentEvent, StateAwareComponent } from 'src/app/model/rest/base';
import { map } from 'rxjs/operators';
import {Utils} from "../../services/utils/utils";
import {ManagerRestService} from "../../services/col2/manager-rest.service";

export class StudentTechnicalProfileFormComponentEvent extends ComponentEvent {
  public static ET_FORM_READONLY = 'readonly';
  public static ET_FORM_EDITABLE = 'editable';

  constructor() {
    super();
  }
  public static formEditable(editable: boolean) {
    const res = new StudentTechnicalProfileFormComponentEvent();
    if (editable) {
      res.eventType = this.ET_FORM_EDITABLE;
    } else {
      res.eventType = this.ET_FORM_READONLY;
    }
    return res;
  }
}

@Component({
    selector: 'app-student-technical-profile-form',
    templateUrl: './student-technical-profile-form.component.html',
    styleUrls: ['./student-technical-profile-form.component.css'],
    standalone: false
})
export class StudentTechnicalProfileFormComponent extends StateAwareComponent<StudentTechnicalProfileFormComponentEvent> implements OnInit {

  _studentId: number;
  _editable = false;
  profile: ApiPersonTechnicalProfile;
  languages = Languages.values;
  _schoolId: number;


  @Input()
  set studentId(studentId: number) {
    this._studentId = studentId;
    this.loadTechnicalProfile();
  }

  @Input()
  set schoolId( schoolId: number) {
    this._schoolId = schoolId;
    this.loadTechnicalProfile();
  }

  callForProfile() {
    return this.managerRest.getStudentTechnicalProfile(this._schoolId, this._studentId);
  }

  loadTechnicalProfile(): any {
    if (!this._studentId || !this._schoolId) {return; }
    this.callForProfile().pipe(
      map(profile => Utils.jsonClone(profile))
    )
    .subscribe( profile => this.setupProfileAfterLoad(profile) );
  }

  set editable(editable: boolean) {
    this._editable = editable;
    this.stateEvent.emit(StudentTechnicalProfileFormComponentEvent.formEditable(editable));
  }

  get editable(): boolean {
    return this._editable;
  }

  saveProfile() {
    return this.managerRest.saveStudentTechnicalProfile(this._schoolId, this._studentId, this.profile);
  }

  public onSave() {
    this.editable = false;
    this.saveProfile().pipe(
      map(profile => Utils.jsonClone(profile))
    )
    .subscribe( p => {
      this.profile = p;
    });
  }

  setupProfileAfterLoad(profile: ApiPersonTechnicalProfile) {
    if (profile == null ) {
      this.profile = new ApiPersonTechnicalProfile();
      this.profile.lang = Languages.English.code;
    } else {
      this.profile = profile;
    }
    this.stateEvent.emit(ComponentEvent.initialized());
  }

  constructor(private managerRest: ManagerRestService) {
    super();
   }

  ngOnInit() {
    this.editable = false;
  }
}
