import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {finalize, map, switchMap, tap} from "rxjs/operators";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {PersonDetails, Student, StudentPersonDetails} from "../../model/rest/casa-apiv2.model";
import {Observable} from "rxjs";

@Component({
    selector: 'app-manager-student-casa-profile-page',
    templateUrl: './manager-student-casa-profile-page.component.html',
    styleUrls: ['./manager-student-casa-profile-page.component.scss'],
    standalone: false
})
export class ManagerStudentCasaProfilePageComponent implements OnInit {
  private schoolId: number;
  private studentId: number;
  private student: Student;
  profileUnderEdit: StudentPersonDetails;
  busy = false;

  constructor(
    activatedRoute: ActivatedRoute,
    private casa: CasaRestApiV2
  ) {
    activatedRoute.parent.paramMap.pipe(
      map<ParamMap, [number, number]>(params => [Number(params.get("schoolId")), Number(params.get("studentId"))]),
      tap<[number, number]>( ([schoolId, studentId]) => this.storeIds(schoolId, studentId)),
      switchMap<[number, number], Observable<Student>>( ([schoolId, studentId]) => this.casa.getStudent(schoolId, studentId)),
      tap<Student>(student => this.keepStudent(student))
    ).subscribe(
    )
  }

  ngOnInit(): void {
  }

  private storeIds(schoolId: number, studentId: number) {
    this.schoolId = schoolId;
    this.studentId = studentId;
  }

  private keepStudent(student: Student) {
    this.student = student;
    this.profileUnderEdit = student.person.details
  }

  saveProfile(details: StudentPersonDetails) {
    this.busy = true;
    console.log("saving the profile:")

    this.casa.requestStudentPersonDetailsUpdate(this.schoolId, this.studentId, details, true).pipe(
      switchMap( _ => this.casa.getStudent(this.schoolId, this.studentId)),
      tap<Student>(student => this.keepStudent(student)),
      finalize( () => this.busy = false)
    ).subscribe(

    );
  }
}
