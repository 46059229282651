import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { NotesSortingRule, NoteTypes } from 'src/app/model/admin';
import { Page, Pageable } from 'src/app/model/rest/base';
import { Note, NoteContent } from 'src/app/model/rest/casa-apiv2.model';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { AdminRestService } from 'src/app/services/rest/admin-rest.service';
import {
  ToastNotification,
  ToastNotificationsService,
} from 'src/app/services/toast-notifications.service';

@Component({
    selector: 'app-admin-school-notes',
    templateUrl: './admin-school-notes.component.html',
    styleUrls: ['./admin-school-notes.component.scss'],
    standalone: false
})
export class AdminSchoolNotesComponent implements OnInit {
  schoolId: number;
  noteContent = new NoteContent();
  notePage: Page<Note>;
  filterType: NoteTypes = null;
  pageable = new Pageable(0, 5, []);
  editingNote: Note;

  @ViewChild('f', { static: false }) form: NgForm;

  constructor(
    private adminRest: AdminRestService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmDialogService,
    private toastNotificationService: ToastNotificationsService
  ) {}

  ngOnInit(): void {
    this.route.parent.paramMap.subscribe((params) => {
      this.schoolId = +params.get('schoolId');
      this.loadNotes();
    });
    this.resetNote();
  }

  getNoteTypes() {
    return NotesSortingRule.getNoteTypes();
  }

  getNoteTypeName(type: NoteTypes) {
    return NoteTypes[type?.toLowerCase()];
  }

  loadNotes() {
    if (!this.schoolId) return;
    this.adminRest
      .listNotes(
        this.schoolId,
        this.pageable,
        this.filterType?.toLowerCase()
      )
      .subscribe((notePage) => (this.notePage = notePage));
  }

  onSave() {
    let observable = this.editingNote ? this.editNote() : this.saveNote();
    observable.subscribe((_) => {
      this.loadNotes();
      this.form.resetForm();
      this.resetNote();
    });
  }

  saveNote() {
    this.noteContent.type = this.noteContent.type.toLowerCase();
    return this.adminRest.createNote(this.schoolId, this.noteContent);
  }

  startEditingNote(note: Note) {
    this.editingNote = note;
    this.noteContent.type = NoteTypes[note.content.type];
    this.noteContent.value = note.content.value;
    document.getElementById('noteForm').scrollIntoView();
  }

  editNote() {
    this.noteContent.type = this.noteContent.type.toLowerCase();
    return this.adminRest
      .updateNote(this.schoolId, this.editingNote.id, this.noteContent)
      .pipe(
        tap((this.editingNote = null)),
        finalize(() => {
          this.toastNotificationService.display(
            new ToastNotification('Note edition', `Note has been edited.`)
          );
        })
      );
  }

  deleteNote(note: Note) {
    this.confirmationService.doOnConfirm(
      'Are you sure you want delete the note?',
      () => {
        this.adminRest
          .deleteNote(this.schoolId, note.id)
          .subscribe((_) =>
            this.toastNotificationService.display(
              new ToastNotification('Note deletion', `Note has been deleted.`)
            )
          );
      },
      'Note deletion'
    );
    this.loadNotes();
  }

  sortBy(rule: string) {
    this.pageable.page = 0;
    this.pageable.sort = NotesSortingRule.getSortingRule(rule).sorting;
    this.loadNotes();
  }

  resetNote() {
    this.noteContent.type = null;
    this.noteContent.value = null;
  }

  filterByType(event: any) {
    this.pageable.page = 0;
    this.filterType = Object.values(NoteTypes).includes(event) ? event : null;
    this.loadNotes();
  }

  switchPage(event: any) {
    this.pageable = event;
    this.loadNotes();
  }
}
