
<app-modal #modalComponent>
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Lesson bundle expiry date update</h5>
      <button type="button" class="btn-close" (click)="modalComponent.hide()"  aria-label="Close">
      </button>
    </div>
    <div class="modal-body">
      <div  *ngIf="complete && !error">
        <div class="alert alert-success">
          Lesson Bundle has been updated
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-primary" (click)="modal.hide()">Close</button>
        </div>
      </div>
      <div class="alert alert-danger" *ngIf="complete && error">
        Got error: {{error}}. <br>
        Please try again or contact the administrator.
      </div>
      <form *ngIf="model" #f="ngForm" (ngSubmit)="f.form.valid && submitUpdate()">
        <div class="form-floating mb-4">
          <input
          class="form-control form-control-sm"
          name="expiryDate"
          type="date"
          [ngModel]="model.expiryDate | date:'yyyy-MM-dd'"
          (ngModelChange)="onExpiryDateChange($event)"
          #expiryDate="ngModel"
          required="required"
          [ngClass]="{'is-invalid': f.submitted && expiryDate.invalid}"
          >
          <label class="field-required">Expiry date</label>
        </div>
        <div class="form-floating mb-4">
          <textarea
          placeholder="notes"
          class="form-control form-control-sm"
          name="notes"
          [(ngModel)]="model.note"
          >
        </textarea>
        <label>Notes</label>
        </div>
        <div class="text-end">
          <button type="button" class="btn btn-secondary rounded-pill me-2" (click)="modal.hide()">Cancel</button>
          <button class="btn btn-primary rounded-pill" [disabled]="inProgress">Submit</button>
        </div>
      </form>
    </div>
  </div>
</app-modal>

