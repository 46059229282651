import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page, Pageable} from "../../model/rest/base";

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    standalone: false
})
export class PaginatorComponent implements OnInit {

  constructor() { }
  @Input()
  data: Page<any>;
  @Input()
  pageable: Pageable;

  @Output()
  newPage = new EventEmitter<Pageable>();

  ngOnInit(): void {
  }

  prev() {
    this.newPage.emit(this.pageable.prev());
  }

  hasNext() {
    return this.pageable && this.data && !this.data.last;
  }

  hasPrev() {
    return this.pageable && this.data && !this.data.first;
  }

  first() {
    this.newPage.emit(this.pageable.first());
  }

  getPage() {
    if (!this.data) return null;
    return this.data.number + 1;
  }

  next() {
    this.newPage.emit(this.pageable.next());
  }
}
