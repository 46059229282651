import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router, UrlSegment} from "@angular/router";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {combineLatest, Observable, of} from "rxjs";
import {switchMap, take, tap} from "rxjs/operators";
import {FileRepoEntry, FolderRepoEntry, RepoEntry, RepoEntryType} from "../../model/rest/documents";
import {School} from "../../model/rest/casa-apiv2.model";


@Component({
    selector: 'app-documents-browser',
    templateUrl: './documents-browser.component.html',
    styleUrls: ['./documents-browser.component.scss'],
    standalone: false
})
export class DocumentsBrowserComponent implements OnInit {
  private school: School;
  schoolId: number;



  constructor(
    private activatedRoute: ActivatedRoute,
    private casaApi: CasaRestApiV2,
    private router: Router
    ) {
    combineLatest(
      [activatedRoute.url,
            activatedRoute.paramMap,
            activatedRoute.queryParamMap
            ]
    ).pipe(
      tap<[UrlSegment[], ParamMap, ParamMap]>(([_, params, _2]) => this.loadSchool(Number(params.get("schoolId")))),
      switchMap(([url, params, queryParams]) =>
        combineLatest([
          of(this.buildDocumentPath(url)),
          this.obtainData(params, !this.isDownloadAction(queryParams)),
          of(this.isDownloadAction(queryParams))
        ])),
      tap<[string[], RepoEntry, boolean]>( ([documentPath, repoData, downloadIt]) =>
        this.prepareView(repoData, documentPath, downloadIt))
    )
      .subscribe()
  }


  private loadSchool(schoolId: number) {
    this.schoolId = schoolId;
    return this.casaApi.getSchoolDetails(schoolId).subscribe(
      school => this.school = school
    );
  }

  ngOnInit(): void {
  }

  documentRoot: RepoEntry;
  documentsPath: RepoEntry[] = [];

  private buildDocumentPath(segments: UrlSegment[]): string[] {
    if (segments.length === 0) return [];
    return segments.map(segment => segment.path);
  }

  private isDownloadAction(queryParams: ParamMap): boolean {
    return queryParams.has("download");
  }

  private obtainData(params: ParamMap, reloadIfEmpty: boolean): Observable<RepoEntry> {
    if (!reloadIfEmpty) return of(this.documentRoot);
    if (this.documentRoot) return of(this.documentRoot);
    return this.casaApi.listDocuments(Number(params.get("schoolId"))).pipe(
      tap(documentRoot => this.documentRoot = documentRoot)
    );
  }

  private prepareView(repoData: RepoEntry, documentPath: string[], downloadIt: boolean) {
    if (downloadIt) {
      this.casaApi.getDocumentLink(this.schoolId, documentPath.join("/")).subscribe(
        {
          next: link => location.href = link,
          error: _ => this.routeToNotFound()
        });
      return;
    }
    // if path is empty redirect to the correct folder
    if (documentPath.length === 0) {
      this.router.navigate([repoData.relativeKey], {relativeTo: this.activatedRoute});
      return;
    }

    this.documentsPath = [];
    let currentItemKids: RepoEntry[] = [repoData];
    for (const pathSegment of documentPath) {
      const nextItem = currentItemKids.find(item => item.relativeKey === pathSegment)
      if (!nextItem) {
        this.routeToNotFound();
        return;
      }
      this.documentsPath.push(nextItem)
      currentItemKids = (nextItem as FolderRepoEntry).innerEntries;
    }
  }

  private routeToNotFound() {
    this.router.navigate(["404"]);
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    return this.school && this.school.details.name || "";
  }

  last() {
    if (this.documentsPath.length == 0) return null;
    return this.documentsPath[this.documentsPath.length - 1];
  }

  lastAsDir() {
    if (this.isDir(this.last())) return this.last() as FolderRepoEntry;
    return null;
  }

  isDir(item: RepoEntry) {
    if (!item) return false;
    return item["@type"] === RepoEntryType.Dir;
  }

  download(item: RepoEntry) {
    this.casaApi.getDocumentLink(this.schoolId, item.key).subscribe(
      {
        next: link => window.open(link, "_blank"),
        error: _ => this.routeToNotFound()
      });
  }

  up() {
    this.router.navigate([".."], {relativeTo: this.activatedRoute})
  }

  getSize(item: RepoEntry) {
    if (item["@type"] === RepoEntryType.File) {
      let size = (item as FileRepoEntry).size;
      if(!size) return ""
      if (size < 1024) return size.toString();
      size = Math.round(size / 1024);
      if (size < 1024) return `${size} KB`;
      size = Math.round(size / 1024);
      return `${size} MB`;
    }
    return "";
  }

  getExtension(item: RepoEntry) {
    if (this.isDir(item)) return "";
    return (item as FileRepoEntry).extension;
  }

  refresh() {
    combineLatest([
      this.casaApi.refreshDocumentsRepository(this.schoolId).pipe(
        switchMap( _ => this.casaApi.listDocuments(this.schoolId)),
        tap<RepoEntry>(documentRoot => this.documentRoot = documentRoot)
      ),
      this.activatedRoute.url
    ]).pipe(
      take(1),
      tap<[RepoEntry, UrlSegment[]]>( ([repoData,url]) => this.prepareView(repoData, this.buildDocumentPath(url), false)) ).subscribe()
  }
}
