import {Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router, UrlSegment, RouterEvent, Event, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import {filter, switchMap, tap} from 'rxjs/operators';
import {PersonNameExtractor} from "../../services/utils/profile-photo-url-extractor";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {
  LearningUnitTeacherStatus,
  School, Student,
  Teacher, VerifiedEmailChangeRequest,
  VerifiedPasswordChangeRequest
} from "../../model/rest/casa-apiv2.model";
import {CallanOnlineRecognizeService} from "../../services/callan-online-recognize.service";
import {PersonPasswordChangeComponent} from "../../components/person-password-change/person-password-change.component";
import {PersonEmailChangeComponent} from "../../components/person-email-change/person-email-change.component";
import {ConfirmDialogService} from "../../services/confirm-dialog.service";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";
import { ManagerUtils } from 'src/app/services/utils/manager-utils';
import {FunctionalAccessService} from "../../services/functional-access.service";

@Component({
    selector: 'app-manager-teacher-details-layout',
    templateUrl: './manager-teacher-details-layout.component.html',
    styleUrls: ['./manager-teacher-details-layout.component.css'],
    standalone: false
})
export class ManagerTeacherDetailsLayoutComponent implements OnInit {

  _schoolId: number;
  _teacherId: number;
  currentFragment: string;
  searchTerm = '';
  teacher: Teacher;
  private currentFragments: string[];
  private school: School;
  @ViewChild('passwordModal', {static: true})
  passwordModal: PersonPasswordChangeComponent;
  @ViewChild('emailModal', {static: true})
  emailModal: PersonEmailChangeComponent;


  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private casa: CasaRestApiV2,
    public callanOnlineRecognize: CallanOnlineRecognizeService,
    private confirmationService: ConfirmDialogService,
    private toastNotificationService: ToastNotificationsService,
    public functionalAccessService: FunctionalAccessService
  ) {
    router.events.pipe(
      filter((e: Event): e is NavigationStart | NavigationEnd | NavigationCancel | NavigationError => e instanceof NavigationStart || 
                                  e instanceof NavigationEnd || 
                                  e instanceof NavigationCancel || 
                                  e instanceof NavigationError))
    .subscribe((e: RouterEvent) => {
        e['urlAfterRedirects']? this.currentFragments = e['urlAfterRedirects'].split('/') : ''
        this.currentFragment = this.currentFragments[this.currentFragments.length - 1]
    });
  }

  changePasswordCallback = (request : VerifiedPasswordChangeRequest) => this.casa.changeTeacherPassword(this._schoolId, this._teacherId, request)
  changeEmailCallback = (request: VerifiedEmailChangeRequest) => this.casa.changeTeacherEmail(this._schoolId, this._teacherId, request)


  set schoolId(schoolId: number) {
    this._schoolId = schoolId;
    this.loadData();
  }

  set teacherId(teacherId: number) {
    this._teacherId = teacherId;
    this.loadData();
  }

  getPersonName() {
    if (!this.teacher) return '';
    return PersonNameExtractor.getCasaPersonName(this.teacher.person);
  }

  loadData() {
    if (!this._schoolId || !this._teacherId) {
      return;
    }

    this.casa.getSchoolDetails(this._schoolId).pipe(
      tap( school => this.school = school),
      switchMap( _ => this.loadTeacher())
    ).subscribe(
      _ => {
        const childActiveStateUrl = (this.activeRoute.children && this.activeRoute.children.length>0) ? this.activeRoute.children[0].snapshot.url : null;
        const selectedTeacherPage = this.getTeacherPageSelected(childActiveStateUrl);
        if (!selectedTeacherPage) {
          this.redirectToDefaultPage()
        }
      }
    )
  }

  loadTeacher(){
    return this.casa.getTeacher(this._schoolId, this._teacherId).pipe(
      tap(teacher => this.teacher = teacher)
    )
  }

  ngOnInit() {
    this.activeRoute.parent.paramMap.subscribe( params => this.schoolId = Number(params.get('schoolId')));
    this.activeRoute.paramMap.subscribe( params => this.teacherId = Number(params.get('teacherId')));
  }

  private supportedPaths = ['lessons', 'profile',  'casa-profile', 'products', 'lessons-list', 'lessons-calendar'];


  determineActiveButton(urls: UrlSegment[]): void {
    this.currentFragment = this.getTeacherPageSelected(urls);
  }

  isCallanOnline() {
    if (!this._schoolId) return false;
    return this.callanOnlineRecognize.isCallanOnlineFranchiseById(this._schoolId);
  }

  searchTeacher() {
    this.router.navigate(['school', this._schoolId, 'teachers'], {queryParams: {term: this.searchTerm}});
  }

  isSchoolReady() {
    return !!this.school;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  private getTeacherPageSelected(urls: UrlSegment[]) {
    if (!urls) return null;
    if (urls.length > 0) {
      const lastPath = urls[urls.length - 1].path;
      if (this.supportedPaths.indexOf(lastPath) >=0 ) return lastPath;
    }
    return null;
  }

  private redirectToDefaultPage() {
    if (!this._schoolId || !this._teacherId) return;
    if (this.isCallanOnline()) {
      this.router.navigate(["school", this._schoolId.toString(), "teacher", this._teacherId.toString(), "lessons"]);
    } else {
      this.router.navigate(["school", this._schoolId.toString(), "teacher", this._teacherId.toString(), "casa-profile"]);
    }
  }

  getStatus() {
    return this.teacher && this.teacher.status || '';
  }

  getAccountEmail() {
    return this.teacher && this.teacher.person.registrationEmail || '';
  }

  canActivate() {
    return this.teacher && this.teacher.status === LearningUnitTeacherStatus.BLOCKED;
  }

  isActive() {
    return this.teacher && this.teacher.status === LearningUnitTeacherStatus.ACTIVE;
  }

  activate() {
    this.confirmationService.doOnConfirm("Are you sure you want to activate the teacher?",
      () => {
        this.casa.updateTeacherStatus(this._schoolId, this._teacherId, LearningUnitTeacherStatus.ACTIVE).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher activation",`Teacher has been activated.`))),
          switchMap( _ => this.loadTeacher())
        ).subscribe();
      },"Teacher activation", "bg-warning");
  }

  block() {
    this.confirmationService.doOnConfirm("Are you sure you want to block the teacher?",
      () => {
        this.casa.updateTeacherStatus(this._schoolId, this._teacherId, LearningUnitTeacherStatus.BLOCKED).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher blocking",`Teacher has been blocked.`))),
          switchMap( _ => this.loadTeacher())
        ).subscribe();
      },"Teacher blocking", "bg-warning");
  }

  delete() {
    this.confirmationService.doOnConfirm("Are you sure you want delete the teacher?",
      () => {
        this.casa.deleteTeacher(this._schoolId, this._teacherId).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Teacher deletion",`Teacher has been deleted.`)))
        ).subscribe(_ => this.router.navigate(["school", this._schoolId, "teachers"]));
      },
      "Teacher deletion",
      "bg-danger text-white"
    );
  }

  anonymize(teacher: Teacher) {
    this.confirmationService.doOnConfirm("Are you sure you want to permanent delete the teacher?",
      () => {
        this.casa.anonymizePerson(teacher.person.id).subscribe(
          _ => {
            this.toastNotificationService.display(new ToastNotification("Teacher has been deleted", "Teacher has been deleted correctly"));
            this.router.navigate(["school", this._schoolId, "teachers"]);
          }
        )

      }, "Teacher permanent delete", "bg-danger text-white"
    )
  }

  changePassword() {
    this.passwordModal.show();
  }

  changeEmail() {
    this.emailModal.show();
  }

  getInitials(student: Teacher) {
    return ManagerUtils.getInitials(student.person)
  }

  reloadTeacher(teacher: Teacher){
    this.casa.syncTeacher(this._schoolId, teacher.id).subscribe(
      _ => this.toastNotificationService.display(
        new ToastNotification("Refreshing of teacher's eBooks & Exercises", `Teachers' ${this.teacher?.person?.details?.name} eBooks & Exercises has been refreshed successfully. Teacher should be able to access all content.`, "bg-success text-white"))
    )
  }

  resetBookShares(teacher: Teacher){
    this.confirmationService.doOnConfirm("Are you sure you to reset teacher eBooks download limit? This will remove the eBooks from all teacher's devices, so that they can download the eBooks to new devices.",
    () => {
      this.casa.clearTeacherDeviceShares(this._schoolId, teacher.id).subscribe(
        _ => this.toastNotificationService.display(
          new ToastNotification("Reset of teacher's eBooks download limit", `Teachers' ${this.teacher?.person?.details?.name} eBooks download limit reset successfully.`)
        )
      )
    }, "Reset of teacher's eBooks download limit","bg-warning");
  }
}
