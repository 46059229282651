import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {debounceTime, map, switchMap, take, tap} from "rxjs/operators";
import {Page, Pageable} from "../../model/rest/base";
import {School, SchoolStudentStatus, Student, VerifiedEmailChangeRequest, VerifiedPasswordChangeRequest} from "../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../services/rest/casa-rest.service";
import {LocalStateService} from "../../services/local-state.service";
import {TimeUnits} from "../../services/utils/calendar-utils";
import {LocalState, ManagerUtils, SortingRule} from "../../services/utils/manager-utils";
import {forkJoin, Subject, Subscription} from "rxjs";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";
import {ConfirmDialogService} from "../../services/confirm-dialog.service";
import { PersonEmailChangeComponent } from 'src/app/components/person-email-change/person-email-change.component';
import { PersonPasswordChangeComponent } from 'src/app/components/person-password-change/person-password-change.component';
import { FunctionalAccessService } from 'src/app/services/functional-access.service';

class StudentsSortingRule extends SortingRule {
  constructor(public name: string, sorting: string[], public status: string[], public totalLabel: string) {
    super(sorting);
  }
  static Natural = new StudentsSortingRule("All",null, null, "All students");
  static Newest =  new StudentsSortingRule("Active (newest first)", ["createDate,DESC"], [SchoolStudentStatus.ACTIVE], "Active students")
  static NameAsc = new StudentsSortingRule("All (ascending)", ["student.person.surname,ASC"], null, "All students");
  static NameDesc = new StudentsSortingRule("All (descending)", ["student.person.surname,DESC"], null, "All students");
  static Inactive = new StudentsSortingRule("Inactive", ["expiryDate,ASC"], [SchoolStudentStatus.INACTIVE], "Inactive students");
  static Expiring = new StudentsSortingRule("Expiring first", ["expiryDate,ASC"], [SchoolStudentStatus.ACTIVE], "Active students");
  static values = [StudentsSortingRule.Natural, StudentsSortingRule.Newest, StudentsSortingRule.NameAsc, StudentsSortingRule.NameDesc, StudentsSortingRule.Inactive, StudentsSortingRule.Expiring];
}

@Component({
    selector: 'app-manager-students-list-page',
    templateUrl: './manager-students-list-page.component.html',
    styleUrls: ['./manager-students-list-page.component.css'],
    standalone: false
})
export class ManagerStudentsListPageComponent implements OnInit, OnDestroy {
  schoolId: number;
  private expiringStudents: Page<Student>;
  currentState: LocalState<StudentsSortingRule>;
  private studentsPage: Page<Student>;
  private school: School;
  private expiryDate = new Date().getTime() + TimeUnits.Days(14).toMilis();
  studentSortingRules = StudentsSortingRule.values;
  private studentsStateUrl = "dashboard/students";
  private searchTermUpdates = new Subject<string>();
  private readonly searchTermUpdatesSubscription: Subscription;

  @ViewChild('emailModal', {static: true})
    emailModal: PersonEmailChangeComponent;
  @ViewChild('passwordModal', {static: true})
    passwordModal: PersonPasswordChangeComponent;

  constructor(
    route: ActivatedRoute,
    private casa: CasaRestApiV2,
    private localState: LocalStateService,
    private router: Router,
    private toastNotificationService: ToastNotificationsService,
    private confirmationService: ConfirmDialogService,
    public functionalAccessService: FunctionalAccessService
  ) {

    forkJoin([
      this.loadSchoolId(route).pipe(
        switchMap( _ => this.loadSchool())
      ),
      this.findLocalState(),
      this.getTermQueryParam(route)
    ]).pipe(
      switchMap(([_, state, term]) => {
        if (term) {
          state.term = term;
        }
        return this.loadStudentsPage(state);
      }),
      switchMap( _ => this.loadExpiringStudents())
    ).subscribe()

    this.searchTermUpdatesSubscription = this.searchTermUpdates.pipe(
      debounceTime(400)
    ).subscribe( _ => this.doSearch());
  }

  private getTermQueryParam(route: ActivatedRoute) {
    return route.queryParamMap.pipe(
      take(1),
      map( paramMap => paramMap.get('term'))
    );
  }

  private findLocalState() {
    return this.localState.get<LocalState<StudentsSortingRule>>(
      this.studentsStateUrl,
      () => new LocalState("", StudentsSortingRule.Natural ,Pageable.of(0, 50, null)))
  }

  private loadSchoolId(route: ActivatedRoute) {
    return route.parent.paramMap.pipe(
      take(1),
      map( params => Number(params.get('schoolId'))),
      tap<number>( schoolId => this.schoolId = schoolId));
  }

  ngOnDestroy() {
    if (this.searchTermUpdatesSubscription) {
      this.searchTermUpdatesSubscription.unsubscribe();
    }
  }

  ngOnInit() {
  }

  set searchTerm(value: string) {
    if (this.currentState) this.currentState.term = value;
    this.searchTermUpdates.next(value);
  }

  get searchTerm(): string {
    if (this.currentState) return this.currentState.term;
    return '';
  }

  private loadSchool() {
    return this.casa.getSchoolDetails(this.schoolId).pipe(
      tap(school => this.storeSchool(school))
    );
  }

  private storeSchool(school: School) {
    this.school = school;
  }

  private loadExpiringStudents() {
    return this.casa.getStudents(this.schoolId, null,null, this.expiryDate, Pageable.of(0, 5, ["expiryDate,ASC"])).pipe(
      tap( expiringStudents => this.storeExpiringStudents(expiringStudents))
    );
  }

  private storeExpiringStudents(expiringStudents: Page<Student>) {
    this.expiringStudents = expiringStudents;
  }

  private loadStudentsPage(state: LocalState<StudentsSortingRule>) {
    this.currentState = state;
    return this.localState.set(this.studentsStateUrl, state).pipe(
      switchMap( state => this.casa.getStudents(this.schoolId, state.term, state.sortingRule.status,null, state.page)),
      tap( studentsPage => this.storeStudentsPage(studentsPage))
    )
  }

  private storeStudentsPage(studentsPage: Page<Student>) {
    this.studentsPage = studentsPage;
  }

  isReady() {
    return this.studentsPage;
  }

  getSchoolName() {
    return this.school.details.name;
  }

  hasExpiredStudents() {
    return this.expiringStudents
      && this.expiringStudents.totalElements > 0;
  }

  getExpiringStudents() {
    return this.expiringStudents.content;
  }

  getTotalExpiring() {
    return this.expiringStudents.totalElements;
  }

  getName(student: Student) {
    return ManagerUtils.getName(student.person);
  }

  getExpiry(student: Student) {
    return student.expiryDate;
  }

  getEmail(student: Student) {
    return student.person.registrationEmail;
  }

  getInitials(student: Student) {
    return ManagerUtils.getInitials(student.person)
  }

  countTotalStudents() {
    if (!this.studentsPage) return 0;
    return this.studentsPage.totalElements;
  }

  doSearch() {
    this.loadStudentsPage(this.currentState.first()).subscribe();
  }

  searchUpdated() {
    this.currentState.applySorting();
  }

  getStudentsPage() {
    return this.studentsPage.content;
  }

  hasPages() {
    return this.hasPrev() || this.hasNext();
  }

  prev() {
    this.loadStudentsPage(this.currentState.prev()).subscribe();
  }

  hasPrev() {
    return this.studentsPage && !this.studentsPage.first;
  }

  getPageNumber() {
    return this.studentsPage.number + 1;
  }

  hasNext() {
    return this.studentsPage && !this.studentsPage.last;
  }

  next() {
    this.loadStudentsPage(this.currentState.next()).subscribe();
  }

  getStatus(student: Student) {
    return student.status;
  }

  hasStudents() {
    return this.studentsPage;
  }

  isSchoolReady() {
    return this.school != null;
  }

  openStudent(student: Student) {
    this.router.navigate(['school', this.schoolId, 'student', student.id]);
  }

  delete(student: Student) {
    return this.casa.deleteStudent(this.schoolId, student.id);
  }

  prolong(student: Student) {
    return this.casa.prolongStudent(this.schoolId, student.id);
  }

  block(student: Student) {
    return this.casa.blockStudent(this.schoolId, student.id);
  }

  prolongStudent(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you want renew the student?",
      () => {
        this.prolong(student).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student renewal",`Student ${this.getName(student)} access has been renewed.`))),
          switchMap( _ => this.loadStudentsPage(this.currentState))
        ).subscribe()
      } ,
      "Student renewal");
  }

  deleteStudent(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you want delete the student?",
      () => {
        this.delete(student).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student deletion",`Student ${this.getName(student)} has been deleted.`))),
          switchMap( _ => this.loadStudentsPage(this.currentState))
        ).subscribe();
      },
      "Student deletion",
      "bg-danger text-white"
      );
  }

  blockStudent(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you want to block the student?",
      () => {
        this.block(student).pipe(
          tap(_ => this.toastNotificationService.display(new ToastNotification("Student blocking",`Student ${this.getName(student)} has been blocked.`))),
          switchMap( _ =>  this.loadStudentsPage(this.currentState))
        ).subscribe();
      },"Student blocking", "bg-warning");
  }

  getCreated(student: Student) {
    return student.created;
  }

  reloadStudent(student: Student) {
    this.casa.syncStudent(this.schoolId, student.id).subscribe(
      _ => this.toastNotificationService.display(
        new ToastNotification("Refreshing of student's eBooks & Exercises", `Students ${this.getName(student)} eBooks & Exercises has been refreshed successfully. Student should be able to access all content.`, "bg-success text-white"))
    )
  }

  resetBookShares(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you to reset student eBooks download limit? This will remove the eBooks from all student's devices, so that they can download the eBooks to new devices.",
      () => {
        this.casa.clearStudentDeviceShares(this.schoolId, student.id).subscribe(
          _ => this.toastNotificationService.display(
            new ToastNotification("Reset of student's eBooks download limit", `Students' ${this.getName(student)} eBooks download limit reset successfully.`)
          )
        )
      }, "Reset of student's eBooks download limit","bg-warning");
  }

  changePasswordCallback = (request : VerifiedPasswordChangeRequest) =>
    this.casa.changeStudentPassword(this.schoolId, null, request)
  changeEmailCallback = (request: VerifiedEmailChangeRequest) =>
    this.casa.changeStudentEmail(this.schoolId, null, request)

  changePassword(student: Student) {
    this.changePasswordCallback = (request : VerifiedPasswordChangeRequest) =>
    this.casa.changeStudentPassword(this.schoolId, student.id, request)
    this.passwordModal.show();
  }
  changeEmail(student: Student) {
    this.changeEmailCallback = (request: VerifiedEmailChangeRequest) =>
      this.casa.changeStudentEmail(this.schoolId, student.id, request)
    this.emailModal.show();
  }

  anonymize(student: Student) {
    this.confirmationService.doOnConfirm("Are you sure you want to permanent delete the student?",
      () => {
        this.casa.anonymizePerson(student.person.id).subscribe(
           _ => {
              this.toastNotificationService.display(new ToastNotification("Student has been deleted", "Student has been deleted correctly"));
              this.router.navigate(["school", this.schoolId.toString(), "students"]);
          }
        )

      }, "Student permanent delete", "bg-danger text-white"
      )
  }

}
