import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {switchMap, tap} from "rxjs/operators";
import {Manager, School} from "../../../model/rest/casa-apiv2.model";
import {CasaRestApiV2} from "../../../services/rest/casa-rest.service";
import {LocalStateService} from "../../../services/local-state.service";

@Component({
    selector: 'app-managers-block',
    templateUrl: './managers-block.component.html',
    styleUrls: ['./managers-block.component.css'],
    standalone: false
})
export class ManagersBlockComponent implements OnInit {
  mSchool: School;
  private managers: Manager[];
  private currentPage = 0;
  private pageSize = 5;
  private currentSliceData: Manager[];

  @Input()
  set school(value: School) {
    this.mSchool = value;
    this.reload();
  }
  @Output() redirectEmitter: EventEmitter<string> = new EventEmitter();

  constructor(
    private casaApi: CasaRestApiV2,
    private localState: LocalStateService
  ) { }

  ngOnInit() {
  }

  private reload() {
    if (!this.mSchool) return;
    this.loadManagers(this.mSchool.id).pipe(
      tap(managers => this.store(managers)),
      switchMap( _ => this.localState.get<number>("dashboard/managers", () => 0)),
      tap( page => this.prepareManagersPage(page))
    )
      .subscribe();
  }

  private loadManagers(schoolId: number) {
    return this.casaApi.getManagers(schoolId);
  }

  private store(managers: Manager[]) {
    this.managers = managers;
  }

  private prepareManagersPage(page: number) {
    this.currentPage = page;
    const startingElement = page * this.pageSize;
    const endingElement = startingElement + this.pageSize;
    const maxElement = Math.min(endingElement, this.managers.length);
    this.currentSliceData = this.managers.slice(startingElement, maxElement);
  }

  hasPrev() {
    console.log(`hasPrev: ${this.currentPage > 0}`);
    return this.currentPage > 0;
  }

  isReady() {
    return this.currentSliceData;
  }

  hasPages() {
    return this.managers && this.managers.length > this.pageSize;
  }

  hasNext() {
    console.log(`hasNext: ${(this.currentPage + 1) * this.pageSize < this.managers.length}`)
    return (this.currentPage + 1) * this.pageSize < this.managers.length;
  }

  prev() {
    this.prepareManagersPage(this.currentPage - 1);
  }

  next() {
    this.prepareManagersPage(this.currentPage + 1);
  }

  getCurrentPageDate(): Manager[] {
    return this.currentSliceData;
  }

  getName(manager: Manager) {
    const person = manager.person.details;
    const components: string[] = [];
    if (person.name) components.push(person.name);
    if (person.surname) components.push(person.surname);
    return components.join(" ");
  }

  getMail(manager: Manager) {
    return manager.person.registrationEmail;
  }

  getMenagersLength() {
    return this.managers.length
  }

  isEmpty() {
    return this.managers.length === 0;
  }

  delete(manager: Manager) {
    this.casaApi.deleteManager(this.mSchool.id, manager.id).pipe(
      switchMap( _ => this.loadManagers(this.mSchool.id)),
      tap(managers => this.store(managers)),
      tap( page => this.prepareManagersPage(this.currentPage))
    ).subscribe();
  }

  redirectTo(path: string) {
    this.redirectEmitter.emit(path);
  }
}
