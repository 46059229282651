import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ExamSessionPart} from "../../model/rest/cspa.model";
import {AudioResultComponent} from "../exam-part-results/audio-result/audio-result.component";
import {LoadExamPartResultDirective} from "../../directives/load-exam-part-result.directive";
import {CorResultComponent} from "../exam-part-results/cor-result/cor-result.component";
import {DictResultComponent} from "../exam-part-results/dict-result/dict-result.component";
import {FillMultiResultComponent} from "../exam-part-results/fill-multi-result/fill-multi-result.component";
import {PicturesResultComponent} from "../exam-part-results/pictures-result/pictures-result.component";
import {JumbleResultComponent} from "../exam-part-results/jumble-result/jumble-result.component";

@Component({
    selector: 'app-exam-part-result',
    templateUrl: './exam-part-result.component.html',
    styleUrls: ['./exam-part-result.component.scss'],
    standalone: false
})
export class ExamPartResultComponent implements OnInit {
  @ViewChild(LoadExamPartResultDirective, {static: true}) loadExamPartResultDirective!: LoadExamPartResultDirective

  private _examPart: ExamSessionPart
  private _examPartType: string
  @Input() set examPart(part: ExamSessionPart) {
    this._examPart = part
    if(part.questions == null)
      return
    this._examPartType = part.questions[0]?.question.definition['@type']
    this.onLoadQuestion()
  }
  get examPart(): ExamSessionPart {
    return this._examPart
  }

  constructor() { }

  ngOnInit(): void {
  }


  onLoadQuestion() {
    this.loadExamPartResultDirective.viewContainerRef.clear();
    let componentRef: any;
    if (this._examPartType === 'cor') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(CorResultComponent)
    } else if (this._examPartType === 'dict') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(DictResultComponent)
    } else if (this._examPartType === 'ef') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(FillMultiResultComponent)
    } else if (this._examPartType === 'pct') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(PicturesResultComponent)
    } else if (this._examPartType === 'jmb') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(JumbleResultComponent)
    } else if (this._examPartType === 'audio') {
      componentRef = this.loadExamPartResultDirective.viewContainerRef.createComponent(AudioResultComponent)
    }
    componentRef.instance.questions = this.examPart?.questions
  }

}
