import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Page, Pageable } from 'src/app/model/rest/base';
import { NewsListEntry } from 'src/app/model/rest/casa-apiv2.model';
import { AuthServiceProvider } from 'src/app/services/col2/auth.service';
import { NewsService } from 'src/app/services/rest/news-rest.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    standalone: false
})
export class NotificationsComponent implements OnInit {
  @Input()
  set schoolId(id: string) {
    this._schoolId = +id
  }
  _schoolId: number;
  managerId: number;
  private pageable = new Pageable(0, 4, ["publicationDate,DESC"]);
  notifications: Page<NewsListEntry>;
  constructor(
    private news: NewsService,
    private router: Router,
    private auth: AuthServiceProvider
  ) {}

  ngOnInit(): void {
    this.auth
      .get()
      .pipe(
        tap((auth) => (this.managerId = auth.getManagerId())),
        switchMap((_) =>
          this.news.listNews(this._schoolId, this.managerId, this.pageable, true)
        ),
        tap((notifications) => (this.notifications = notifications))
      )
      .subscribe();
  }

  goToDetails(news: NewsListEntry) {
    this.router.navigate(['school', this._schoolId, 'news', news.id]);
  }
}
