import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {map, switchMap, take, tap, timeoutWith} from "rxjs/operators";
import {StudentVideoRestService} from "../../../services/rest/student-video-rest.service";
import {SearchingContextService} from "../../../services/searching-context.service";
import {
  ScheduleListQuery,
  StudentSchedulesListProvider
} from "../../../components/schedules-list/schedules-list.provider";
import {of} from "rxjs";
import {ListEvent, SchedulesListComponent} from "../../../components/schedules-list/schedules-list.component";
import {DateUtils} from "../../../services/helpers/date-utils";
import {ScheduleRow} from "../../../model/schedule";
import {LocalStateService} from "../../../services/local-state.service";
import {Page, Pageable} from "../../../model/rest/base";
import {IdentifiedRoomTemplate, RoomDef} from "../../../model/server";

@Component({
    selector: 'app-student-root-page',
    templateUrl: './student-root-page.component.html',
    styleUrls: ['./student-root-page.component.scss'],
    standalone: false
})
export class StudentRootPageComponent implements OnInit {
  schoolId: number;
  private studentId: number;
  schedulesDataProvider: StudentSchedulesListProvider;
  isScheduleLoading = false;
  @ViewChild('schedulesListComponent', {static: false}) schedulesListComponent: SchedulesListComponent;
  schedulesCount: number;
  groupsState: Pageable;
  groupsData: Page<IdentifiedRoomTemplate>;
  roomsState: Pageable;
  roomsData: Page<RoomDef>;

  constructor(
    activatedRoute: ActivatedRoute,
    private studentVideoRest: StudentVideoRestService,
    private searchingContext: SearchingContextService,
    private localStateService: LocalStateService,
    private router: Router
  ) {

    activatedRoute.paramMap.pipe(
      map(params => [Number(params.get("schoolId")), Number(params.get("studentId"))]),
      tap( ([schoolId, studentId]) => this.storeParams(schoolId, studentId)),
      switchMap( _ => this.restoreSchedules()),
      switchMap( _ => this.restoreGroupsLocalState()),
      switchMap( groupsState => this.loadGroups(groupsState)),
      switchMap( _ => this.restoreRoomsLocalState()),
      switchMap( roomsState => this.loadRooms(roomsState))
    ).subscribe( )
  }

  private storeParams(schoolId: number, studentId: number) {
    this.schoolId = schoolId;
    this.studentId = studentId;
  }

  ngOnInit(): void {
  }

  private restoreSchedules() {
    const contextPath = `/schools/${this.schoolId}/students/${this.studentId}/schedules`;
    return this.searchingContext.getOrCreateContext<ScheduleListQuery>(contextPath).pipe(
      take(1),
      timeoutWith(100, of(new ScheduleListQuery(0 , null))),
      map( query => new StudentSchedulesListProvider(contextPath, this.searchingContext, this.studentVideoRest, this.schoolId, this.studentId, query)),
      tap<StudentSchedulesListProvider>( provider => this.schedulesDataProvider = provider)
    );
  }

  hasScheduleQueryArgs() {
    return this.schedulesDataProvider && this.schedulesDataProvider.queryArgs && this.schedulesDataProvider.queryArgs.weekOffset != null;
  }

  moveScheduleWeekOffset(number: number) {
    this.schedulesDataProvider.queryArgs.weekOffset += number;
    this.schedulesListComponent.refreshData();
  }

  moveScheduleToNow() {
    this.schedulesDataProvider.queryArgs.weekOffset = 0;
    this.schedulesListComponent.refreshData();
  }

  getWeekStartDate() {
    return DateUtils.weekStart(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  getWeekEndDate() {
    return DateUtils.weekEnd(DateUtils.queryDate(this.schedulesDataProvider.queryArgs.weekOffset));
  }

  onSchedulesDataSetUpdate(dataSet: ScheduleRow[]) {
    this.schedulesCount = dataSet.length;
  }

  onSchedulesLoading($event: boolean) {
    this.isScheduleLoading = $event;
  }

  getSchedulesDataProvider() {
    return this.schedulesDataProvider;
  }

  private groupsLocalStatePath() {
    return `/schools/${this.schoolId}/students/${this.studentId}/templates`;
  }

  private roomsLocalStatePath() {
    return `/schools/${this.schoolId}/students/${this.studentId}/rooms`;
  }

  private restoreGroupsLocalState() {
    return this.localStateService.get<Pageable>(this.groupsLocalStatePath(),() =>
      Pageable.of(0,10, ['created,desc'])
    );
  }

  private loadGroups(groupsState: Pageable) {
    this.groupsState = groupsState;
    return this.localStateService.set<Pageable>(this.groupsLocalStatePath(), groupsState).pipe(
      switchMap( state => this.studentVideoRest.listTemplates(this.schoolId, this.studentId, state)),
      tap( groupsData => this.storeGroupsData(groupsData))
    )
  }

  private storeGroupsData(groupsData: Page<IdentifiedRoomTemplate>) {
    this.groupsData = groupsData;
  }

  private restoreRoomsLocalState() {
    return this.localStateService.get<Pageable>(this.roomsLocalStatePath(),() =>
      Pageable.of(0,10, ['createDate,desc'])
    );
  }

  private loadRooms(roomsState: Pageable) {
    this.roomsState = roomsState;
    return this.localStateService.set<Pageable>(this.roomsLocalStatePath(), roomsState).pipe(
      switchMap( state => this.studentVideoRest.listRooms(this.schoolId, this.studentId, state)),
      tap( roomsData => this.storeRoomsData(roomsData))
    )
  }

  private storeRoomsData(roomsData: Page<RoomDef>) {
    this.roomsData = roomsData;
  }

  hasGroups() {
    return this.groupsData && this.groupsData.totalElements > 0;
  }

  hasRooms() {
    return this.roomsData && this.roomsData.totalElements > 0;
  }

  switchGroupPage(toPage: Pageable) {
    this.loadGroups(toPage).subscribe();
  }

  switchRoomsPage(toPage: Pageable) {
    this.loadRooms(toPage).subscribe();
  }

  openRoomDetails(room: RoomDef) {
    this.router.navigate(["sa", "school",this.schoolId, "student", this.studentId ,"room",room.uuid ]);
  }

  onScheduleEvent($event: ListEvent) {
    if ($event.event == ListEvent.EVENT_OPEN){
      this.router.navigate(["sa", "school",this.schoolId, "student", this.studentId ,"schedule", $event.item.schedule.id ]);
    }
  }
}
