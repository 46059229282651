import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { ApiLessonBundle } from 'src/app/model/rest/rest-model';
import {LangProductMapper} from "../../services/utils/lang-mappers";
import {ManagerRestService} from "../../services/col2/manager-rest.service";
import {ToastNotification, ToastNotificationsService} from "../../services/toast-notifications.service";

@Component({
    selector: 'app-create-students-credits-bundle',
    templateUrl: './create-students-credits-bundle.component.html',
    styleUrls: ['./create-students-credits-bundle.component.css'],
    standalone: false
})
export class CreateStudentsCreditsBundleComponent implements OnInit {
  @Input()
  schoolId: number;
  @Input()
  studentId: number;

  @Output()
  creditsAdded = new EventEmitter<ApiLessonBundle>();

  newBundle = new ApiLessonBundle();
  availableProducts  = LangProductMapper.getCourseProducts();

  constructor(
    private managerRest: ManagerRestService,
    private notifications: ToastNotificationsService) { }

  registerBundle() {
    this.newBundle.registeredLessons = this.newBundle.available;
    this.newBundle.date = new Date();

    this.managerRest.saveLessonBundle(this.schoolId, this.studentId, this.newBundle)
    .subscribe( resBundle => {
      this.creditsAdded.emit(resBundle);
      this.newBundle = new ApiLessonBundle();
      this.notifications.display(new ToastNotification("Credits added", "Students credits added correctly."))
    });
  }

  ngOnInit() {}
}
