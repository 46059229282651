import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-payment-confirm',
    templateUrl: './payment-confirm.component.html',
    styleUrls: ['./payment-confirm.component.scss'],
    standalone: false
})
export class PaymentConfirmComponent implements OnInit {
  public schoolId: string;

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    activatedRoute.paramMap.subscribe(params => this.schoolId = params.get('schoolId'))
  }

  ngOnInit(): void {
  }
}
